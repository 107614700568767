import { useEffect, useState } from 'react';
import Axios from 'axios';
import { ProcessBlogPost } from './ProcessBlogPost';

let cache = {};

export default function useBlogPost(slug) {
	const [post, setPost] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (cache[slug]) {
			setPost(cache[slug]);
			setLoading(false);
		} else {
			Axios.get(`${process.env.PUBLIC_URL}/posts/${slug}.json?${Date.now()}`)
				.then((response) => {
					const processed = ProcessBlogPost(response.data);
					cache[slug] = processed;
					setPost(processed);
					setLoading(false);
				})
				.catch((error) => {
					setPost({});
					setLoading(false);
				});	
			}
	}, [slug]);

	return [post, loading];
}
