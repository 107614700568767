import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowSize from '../../common/utilities/useWindowSize';
import { useSetRecoilState } from 'recoil';
import LandscapeActiveState from '../../states/LandscapeActive';
import { useEffect } from 'react';
import styles from './BlogContent.module.scss';
// import LogoLandscape from '../Logo/LogoLandscape';
import useStore from '../../states/useStore';
import { useGesture } from '@use-gesture/react';
import Button from '../Button/Button';
import BlogList from './BlogList';
import Footer from '../Footer/Footer';
import DateIcon from './DateIcon';
import ProfileIcon from './ProfileIcon';
import useBlogList from './useBlogList';
import { useSpring, a } from '@react-spring/web';
import { useTabletLargeQuery, useMobileQuery } from '../../common/utilities/MediaQuery';

export default function BlogContent() {
	const location = useLocation();
	const { width, height } = useWindowSize();
	const ref = useRef();
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const dispatchScrollY = useStore((state) => state.dispatchScrollY);
	const isMobile = useMobileQuery();
	const isTabletLarge = useTabletLargeQuery();
	const isTabletDown = isMobile || isTabletLarge;
	const history = useHistory();

	const bind = useGesture({
		onScroll: ({ offset }) => {
			dispatchScrollY(offset[1]);
		},
	});

	useEffect(() => {
		setLandscapeActive(false);
	}, [setLandscapeActive]);

	useEffect(() => {
		ref.current.scrollTo(0, 0);
	}, [location]);

	const [posts, loading] = useBlogList();
	const recentPost = posts[0] ?? {};

	const { opacity } = useSpring({
		opacity: loading ? 0 : 1,
	});

	return (
		<>
			<Helmet>
				<title>{`Herbalife24 Tri - Blog`}</title>
				<meta
					name="description"
					content="A selection of news and articles from the world of the Herbalife 24 Triathlon Los Angeles."
				/>
				<meta
					name="keywords"
					content="Herbalife 24, blog, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles"
				/>
				<meta property="og:title" content="Blog" />
				<meta
					property="og:description"
					content="A selection of news and articles from the world of the Herbalife 24 Triathlon Los Angeles."
				/>
			</Helmet>
			<a.div {...bind()} style={{ height, width, opacity }} className={styles.BlogContent} ref={ref}>
				<div className={styles['header']} style={{ height: isTabletDown ? '70%' : height }}>
					<div className={`${styles['detail-head-wrap']} ${styles['blog']}`}>
						<h2 className={styles['detail-head-title']}>{recentPost.title}</h2>

						<div className={`${styles['summary-copy']} ${styles['info']}`}>
							<div className={styles['info-item']}>
								<DateIcon />
								<span>{recentPost.date}</span>
							</div>
							<div className={styles['info-item']}>
								<ProfileIcon />
								<span>{recentPost.author}</span>
							</div>
						</div>

						<Button
							label="Read Now"
							onPress={() => {
								history.push(`/blog/${recentPost.slug}`);
							}}
						/>
					</div>
				</div>
				<div className={styles['detail-body-inner']}>
					<div className={styles['content']}>
						<div className={styles['detail-body-wrap']}>
							<div className={styles['inner-wrap']}>
								<BlogList posts={posts} />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</a.div>
		</>
	);
}
