import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import useWindowSize from '../../common/utilities/useWindowSize';
import { useTabletLargeQuery, useMobileQuery } from '../../common/utilities/MediaQuery';
import styles from './Overlay.module.scss';
import { useSpring, a } from '@react-spring/web';
import MainMenuStatusState from '../../states/MainMenuStatus';
import LogoLandscapeDate from '../Logo/LogoLandscapeDate';

function Overlay() {
	const { width: winWidth } = useWindowSize();
	const MainMenuStatus = useRecoilValue(MainMenuStatusState);
	const isMobile = useMobileQuery();
	const isTabletLarge = useTabletLargeQuery();
	const isTabletDown = isMobile || isTabletLarge;

	const [{ opacity }, spring] = useSpring(() => ({
		opacity: 0,
	}));

	useEffect(() => {
		if (MainMenuStatus) {
			spring.start({ opacity: 0.94 });
		} else {
			spring.start({ opacity: 0 });
		}
	}, [MainMenuStatus, winWidth, spring]);

	return (
		<a.div className={styles.Overlay} style={{ opacity }}>
			<div style={{ position: 'absolute', top: 60, right: 60, width: 400, display: isTabletDown ? 'none' : 'block' }}>
				<LogoLandscapeDate />
			</div>
		</a.div>
	);
}

export default Overlay;
