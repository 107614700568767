import React, { useEffect, useCallback, useRef } from 'react';
import styles from './SponsorTickerMobile.module.scss';
import { useSprings, a } from '@react-spring/web';
import { sponsorData } from '../../data/sponsorData';
import useWindowSize from '../../common/utilities/useWindowSize';

export default function SponsorTickerMobile() {
	const { width: winWidth } = useWindowSize();
	const visible = winWidth < 500 ? 2 : 3; //value +1 for offset both sides
	const WIDTH = winWidth / visible;
	const idx = useCallback((x, l = sponsorData.length) => (x < 0 ? x + l : x) % l, []);
	const getPos = useCallback((i, firstVis, firstVisIdx) => idx(i - firstVis + firstVisIdx), [idx]);
	const [springs, api] = useSprings(sponsorData.length, (i) => ({ x: (i < sponsorData.length - 1 ? i : -1) * WIDTH }));
	const prev = useRef([0, 1]);

	const runSprings = useCallback(
		(y) => {
			const firstVis = idx(Math.floor(y / WIDTH) % sponsorData.length);
			const firstVisIdx = 1 < 0 ? sponsorData.length - 1 : 1;
			api.start((i) => {
				const position = getPos(i, firstVis, firstVisIdx);
				const prevPosition = getPos(i, prev.current[0], prev.current[1]);
				const rank = firstVis - (y < 0 ? sponsorData.length : 0) + position - firstVisIdx;
				return {
					x: (-y % (WIDTH * sponsorData.length)) + WIDTH * rank,
					immediate: 1 < 0 ? prevPosition > position : prevPosition < position,
				};
			});
			prev.current = [firstVis, firstVisIdx];
		},
		[idx, getPos, api, WIDTH]
	);

	const wheelOffset = useRef(0);
	const dragOffset = useRef(0);

	const index = useRef(0);

	const toggleSlide = useCallback(() => {
		wheelOffset.current += WIDTH;
		dragOffset.current += WIDTH;
		index.current += WIDTH;

		runSprings(index.current);
	}, [runSprings, WIDTH]);

	useEffect(() => {
		setInterval(() => {
			toggleSlide();
		}, 3000);
	}, [toggleSlide]);

	return (
		<div className={styles.SponsorTickerMobile}>
			{/* <div className={styles.header}>Sponsors</div> */}
			<div className={styles.sponsors} style={{ right: WIDTH / 2 }}>
				{springs.map(({ x }, index) => {
					return (
						<a.div
							key={index}
							className={`${styles.sponsor} ${styles[sponsorData[index].name]}`}
							style={{ width: WIDTH, x, willChange: 'transform' }}
						>
							<a href={sponsorData[index].link} rel="noopener noreferrer" target="_blank">
								<img className={styles.img} src={sponsorData[index].src} alt={sponsorData[index].alt} />
							</a>
						</a.div>
					);
				})}
			</div>
		</div>
	);
}
