export const styleList = [
	'margin',
	'marginLeft',
	'marginRight',
	'marginTop',
	'marginBottom',
	'width',
	'height',
	'minWidth',
	'minHeight',
	'maxWidth',
	'maxHeight',
	'display',
	'alignSelf',
	'justifySelf',
	'position',
	'zIndex',
	'top',
	'bottom',
	'left',
	'right',
	'order',
	'flex',
	'flexGrow',
	'flexShrink',
	'flexBasis',
];

export default function useStyleProps(props) {
	let styleProps = {};

	for (const style of styleList) {
		if (props[style] === undefined || props[style] === null) {
			continue;
		}

		styleProps[style] = props[style];
	}

	return styleProps;
}
