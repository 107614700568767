import { Billboard, Circle, Ring, Text } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import useStore from '../../states/useStore';
import { MAP_DATA } from '../../data/mapData';

export default function Poi({ size = 1, location = 'veniceBeach', ...props }) {
	const circleRef = useRef();
	const [circle] = useState(() => ({ scale: new THREE.Vector3(), color: new THREE.Color('red') }));

	const labelRef = useRef();
	const [label] = useState(() => ({ scale: new THREE.Vector3() }));

	const ringRef = useRef();
	const [ring] = useState(() => ({ position: new THREE.Vector3() }));

	const [isHovered, setIsHovered] = useState(false);

	const dispatchPoi = useStore((state) => state.dispatchPoi);
	const active = useStore((state) => state.poi.active);
	const isOpen = useStore((state) => state.poi.isOpen);

	const handlePoiClick = useCallback(
		(e) => {
			// e.stopPropagation();
			dispatchPoi({ active: location, isOpen: true });
		},
		[dispatchPoi, location]
	);

	const handlePoiEnter = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handlePoiLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	useEffect(() => void (document.body.style.cursor = isHovered ? 'pointer' : 'auto'), [isHovered]);

	useFrame((state) => {
		if (!circleRef.current) return;

		const t = state.clock.getElapsedTime();

		if (isHovered && (!isOpen || active !== location)) {
			circleRef.current.scale.lerp(circle.scale.set(1.3, 1.3, 1), 0.1);
		} else {
			circleRef.current.scale.lerp(circle.scale.set(1, 1, 1), 0.1);
		}

		if (!isOpen || active === location) {
			labelRef.current.scale.lerp(label.scale.set(1, 1, 1), 0.1);
			circleRef.current.material.color.lerp(circle.color.set('red'), 0.1);
			ringRef.current.material.color.lerp(circle.color.set('red'), 0.1);
		} else {
			labelRef.current.scale.lerp(label.scale.set(0, 0, 0), 0.1);
			circleRef.current.material.color.lerp(circle.color.set('#f92626'), 0.01);
			ringRef.current.material.color.lerp(circle.color.set('#f92626'), 0.01);
			ringRef.current.position.lerp(ring.position.set(0, 0.01, 0), 0.1);
		}

		if (isOpen && active === location) {
			ringRef.current.position.y = THREE.MathUtils.lerp(ringRef.current.position.y, Math.sin(t) / 9, 0.1);

			circleRef.current.scale.x = THREE.MathUtils.lerp(circleRef.current.scale.x, Math.sin(t) / 4 + 1.2, 0.1);
			circleRef.current.scale.y = THREE.MathUtils.lerp(circleRef.current.scale.y, Math.sin(t) / 4 + 1.2, 0.1);
			circleRef.current.scale.z = THREE.MathUtils.lerp(circleRef.current.scale.z, Math.sin(t) / 4 + 1.2, 0.1);
		}
	});

	return (
		<group {...props} name="poi" onClick={handlePoiClick}>
			<Billboard ref={labelRef} position={[0.5, 0.5, -1]}>
				<Text
					font="https://fonts.gstatic.com/s/raleway/v14/1Ptrg8zYS_SKggPNwK4vaqI.woff"
					fontSize={0.5}
					outlineOffsetX={'1%'}
					outlineOffsetY={'1%'}
					outlineBlur={'50%'}
					outlineColor="#EC2D2D"
				>
					{/* <meshBasicMaterial color="white" visible={active === location ? true : false} /> */}
					{MAP_DATA[location].title}
				</Text>
			</Billboard>
			<Ring ref={ringRef} args={[size - 0.25, size, 30]} position={[0, 0.01, 0]} rotation={[-Math.PI / 2, 0, 0]}>
				<meshBasicMaterial color={circle.color} transparent opacity={0.5} />
			</Ring>
			<Circle
				ref={circleRef}
				onPointerEnter={handlePoiEnter}
				onPointerLeave={handlePoiLeave}
				args={[size, 30]}
				rotation={[-Math.PI / 2, 0, 0]}
			>
				<meshBasicMaterial color={circle.color} transparent opacity={0.5} />
			</Circle>
		</group>
	);
}
