import React from 'react';
import { ELITES_PATH } from '../../data/pathConstants';
import { Item, List } from '../List/List';

import elitesBg from '../../media/page-bgs/rules_bg.jpg';
import Title from '../Text/Title';
import Paragraph from '../Text/Paragraph';

export const ElitesData = {
	id: 'elites',
	url: ELITES_PATH,
	pageBg: elitesBg,
	pageTitle: 'Elites',
	pageSummary: 'Some of the most elite athletes from around the globe are gathering together.',
	pageIcon: '/assets/icons/rules.svg',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, Herbalife tri, Los Angeles tri, LA tri, H24 tri, H24 triathlon, Herbalife24, LA Live, down town Los Angeles, triathlon, triathlon wetsuit, triathlon training, triathlon training plan, triathlon clothing, triathletes, triathlon bike, triathlon bikes, triathlon shorts, triathlon calendar, triathlon events, triathlon magazine, triathlons, mini triathlon, triathlon distance, types of triathlons, triathlon near me, triathlon types, triathlete, triathlon suit, duathlon, triathlon meaning, triathlon logo, tri athlete, triathlon websites, triathlon times, running, Race triathlon, 2021 triathlons, athletes, elite',
	metaDescription: 'Elite athletes coming to race.',
};

export default function Elites() {
	return (
		<>
			<Paragraph>
				Below is the 2022 Start List of the 2022 Herbalife24 Triathlon. Some of the most elite athletes from around the
				globe are gathering together in Los Angeles to compete for ultimate glory and to stake their claim in the
				amazing $100,000 prize purse. Split between male and female athletes, this prize purse will have the athletes
				giving it their all!
			</Paragraph>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
					fontSize: 18,
					fontWeight: 700,
					gap: 7,
					marginTop: 40,
				}}
			>
				<div>1st - $9,000</div>
				<div>2nd - $7,500</div>
				<div>3rd - $6,000</div>
				<div>4th - $5,000</div>
				<div>5th - $4,300</div>
				<div>6th - $3,500</div>
				<div>7th - $3,100</div>
				<div>8th - $2,700</div>
				<div>9th - $2,300</div>
				<div>10th - 2,000</div>
				<div>11th - $1,400</div>
				<div>12th - $1,100</div>
				<div>13th - $900</div>
				<div>14th - $700</div>
				<div>15th - $500</div>
			</div>
			<div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
				<div>
					<Title>Females</Title>
					<List>
						<Item>Lauren Babineau</Item>
						<Item>Romina Biagioli</Item>
						<Item>Sarah Bishop</Item>
						<Item>Haley Chura</Item>
						<Item>Amy Sloan</Item>
						<Item>Megan Foley</Item>
						<Item>Lara Gruden</Item>
						<Item>Mollie Hebda</Item>
						<Item>Brittany Higgins</Item>
						<Item>Heather Jackson</Item>
						<Item>Carly Johann</Item>
						<Item>Danielle Lewis</Item>
						<Item>Vittoria Lopes</Item>
						<Item>Laura Mathews</Item>
						<Item>Katrina Matthews</Item>
						<Item>Elizabeth Nyitray</Item>
						<Item>Rachel Olson</Item>
						<Item>Alexandra Poole</Item>
						<Item>Liizeth Santos</Item>
						<Item>Grace Thek</Item>
						<Item>Brianna Troksa</Item>
						<Item>Sarah True</Item>
						<Item>Brittany Warly</Item>
						<Item>Amelia Watkinson</Item>
						<Item>Hannah Wells</Item>
					</List>
				</div>
				<div>
					<Title>Males</Title>
					<List>
						<Item>Marty Andrie</Item>
						<Item>Sam Appleton</Item>
						<Item>Kyle Buckingham</Item>
						<Item>Nathan F. Cohen</Item>
						<Item>Kevin Collington</Item>
						<Item>Guy Crawford</Item>
						<Item>Trevor Foley</Item>
						<Item>Cody Forman</Item>
						<Item>Pedro Gomes</Item>
						<Item>Rodrigo Gonzalez</Item>
						<Item>Gregory Harper</Item>
						<Item>Javier Hidalgo</Item>
						<Item>Ian Hoover-Grinde</Item>
						<Item>Kevin Jervis</Item>
						<Item>Stephen Kilshaw</Item>
						<Item>Ian King</Item>
						<Item>Andy Krueger</Item>
						<Item>Eric Lagerstrom</Item>
						<Item>Andre Lopes</Item>
						<Item>Jim Lubinski</Item>
						<Item>Steven McKenna</Item>
						<Item>Jonathan McKinley</Item>
						<Item>Evan Pardi</Item>
						<Item>Steven Parker</Item>
						<Item>Danilo Pimentel</Item>
						<Item>Austin Podhajsky</Item>
						<Item>Jamie Riddle</Item>
						<Item>Andrew Roos</Item>
						<Item>Steve Rosinski</Item>
						<Item>Aaron Royle</Item>
						<Item>Cody Sahlstrom</Item>
						<Item>Simon Shi</Item>
						<Item>Andrew Starykowicz</Item>
						<Item>Luciano Taccone</Item>
						<Item>Jesse Vondracek</Item>
						<Item>Michael Vulanich</Item>
						<Item>Robby Webster</Item>
						<Item>Kurt Wesley</Item>
						<Item>Jason West</Item>
						<Item>Travis Wood</Item>
					</List>
				</div>
			</div>
		</>
	);
}
