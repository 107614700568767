import { EVENTS_5K_VIRTUAL_PATH } from '../../data/pathConstants';
import { Item, List } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import events5kVirtualBg from '../../media/page-bgs/events_5k_virtual_bg.jpg';

export const Events5kVirtualData = {
	id: 'events5kVirtual',
	url: EVENTS_5K_VIRTUAL_PATH,
	pageBg: events5kVirtualBg,
	pageTitle: 'Herbalife24 5k Virtual',
	pageSummary: 'Train together while staying apart.',
	pageIcon: '/assets/icons/map.svg',
	pageIntro:
		'During these challenging times, staying connected (and active) is more important than ever.  Participate in the virtual challenge to have fun, stay motiviated and connect with each other.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA course description, Herbalife24 triathlon Los Angeles course description, Herbalife24 triathlon Los Angeles, Herbalife24 triathlon LA, venice beach, venice, marina del rey, helms bakery, midcity, koreatown, figueroa corridor, university park, L.A. Live, la live, macarthur park, downtown la, westlake, virtual challenge, covid, covid-19, coronavirus',
	metaDescription: 'During these challenging times, staying connected (and active) is more important than ever.',
}

export default function Events5kVirtual() {
	return (
		<>
			<Title>About</Title>
			<Paragraph>
				During these challenging times, staying connected (and active) is more important than ever. While the{' '}
				<b>2021 Herbalife24 Triathlon LA</b> was supposed to take place on October 17, (now moved to October 24), we can
				still train together while apart! The purpose of the <b>Herbalife24 Triathlon - Virtual Challenge</b> is to have
				fun, stay motivated, and connect with each other. Your entry fee is just a donation to JDRF, an amazing charity
				making huge strides to support those affected by Type 1 Diabetes. Plus, by entering the challenge, you'll be
				entered into the raffle to win some awesome prizes!
			</Paragraph>
			<Paragraph>
				The two week Virtual Challenge runs from October 17 - October 31. Participants will complete a 5k Run / 20k Bike
				/ Athlete’s Choice. When you complete the challenge, upload your results online and share your challenge photos
				with us on social media!
			</Paragraph>
			<Subtitle>Dates</Subtitle>
			<Paragraph>October 17 - October 31, 2021</Paragraph>
			<Subtitle>Distances</Subtitle>
			<Paragraph>
				This challenge consists of a 5k Run / 20k Bike / Athlete’s Choice (choose any physical activity of your choice
				that lasts at least 5 minutes - anyone up for 5 minutes of burpees?). The 3 events don't all have to be done at
				once or in any particular order. You can take as many days as you wish to complete within the window of October
				17 to October 31.
			</Paragraph>
			<Subtitle>How do I Participate</Subtitle>
			<Paragraph>
				Register{' '}
				<a
					href="https://endurancecui.active.com/new/events/69246153/select-race?_p=3166794260667931"
					target="_blank"
					rel="noreferrer"
				>
					here
				</a>
				. Entry into this event is just a $20.00 donation that goes directly to JDRF! Complete the challenge at home and
				upload your results{' '}
				<a href="https://resultscui.active.com/events/Herbalife24VirtualTriathlon" target="_blank" rel="noreferrer">
					here
				</a>
				.
			</Paragraph>

			<Title>Raffle</Title>
			<Subtitle>Prizes</Subtitle>

			<List>
				<Item>(1) Set of HED Carbon Jet 4 Plus Wheels ($1500 value)</Item>
				<Item>(1) Herbalife24 “Stay-Fit” Kit (Work out equipment and nutrition??) ($200 Value)</Item>
				<Item>(1) Rudy Project Herbalife24 Triathlon LA Volantis Helmet ($185 value)</Item>
			</List>

			<Title>Raffle</Title>
			<Subtitle>How are the raffle winners chosen?</Subtitle>
			<Paragraph>
				All registered participants will be entered into the raffle. Names will be drawn at random and winners will be
				announced on June 2nd.
			</Paragraph>

			<Title>About JDRF</Title>
			<Paragraph>
				Herbalife Nutrition and JDRF are partnering to change the future of millions of people living with type 1
				diabetes by working together toward a world without T1D.
			</Paragraph>
			<Paragraph>
				JDRF is leading the fight against type 1 diabetes (T1D), an autoimmune disease that affects over 1.25 million
				people of all ages. The cause is unknown and there currently is no cure. It requires constant management, a
				lifelong dependence on injected insulin and can create life-threatening complications.
			</Paragraph>

			<Title>Rules</Title>

			<List>
				<Item>This is intended to be participatory and fun. So please - have fun!</Item>
				<Item>Athletes must obey the rules of the road just like any other event</Item>
				<Item>Use common sense when deciding when and where to participate</Item>
				<Item>
					<List>
						<Item>Wear reflective gear if competing at dusk or dawn</Item>
						<Item>Don’t bike in high traffic areas</Item>
					</List>
				</Item>
				<Item>Please follow your local Social Distancing Guidelines</Item>
				<Item>
					<List>
						<Item>Stay at least 6 feet (about 2 arms’ length) from other people</Item>
						<Item>Do not gather in groups</Item>
						<Item>Stay out of crowded places and avoid mass gatherings</Item>
						<Item>Refer to www.CDC.gov for more in-depth guidelines</Item>
					</List>
				</Item>
				<Item>There are no refunds or transfers. All sales are final.</Item>
			</List>
		</>
	);
}