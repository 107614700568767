import React, { useEffect } from 'react';
import RegisterButton from '../Button/RegisterButton';
import { useTabletQuery } from '../../common/utilities/MediaQuery';
import MainMenuStatusState from '../../states/MainMenuStatus';
import { useRecoilValue } from 'recoil';
import { useSpring, a } from '@react-spring/web';
import styles from './MobileRegister.module.scss';
// import { REGISTER_URL } from '../../data/pageData';

export default function MobileRegister() {
	const isTablet = useTabletQuery();
	const mainMenuOpen = useRecoilValue(MainMenuStatusState);

	const [{ opacity, y, x }, spring] = useSpring(() => ({
		opacity: 1,
		y: 0,
		x: '-50%',
	}));

	useEffect(() => {
		if (mainMenuOpen) {
			spring.start({
				opacity: 0,
				y: 100,
			});
		} else {
			spring.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [mainMenuOpen, spring]);

	return (
		<a.div className={styles.MobileRegister} style={{ opacity, y, x, bottom: isTablet ? 8 : 14 }}>
			{/* <RegisterButton label="Register" fullWidth href={REGISTER_URL} /> */}
			<RegisterButton
				label="Watch PTO Pro-Am"
				fullWidth
				href="https://protriathletes.org/events/pto-pro-am/herbalife-los-angeles-2022/watch-live/"
			/>
		</a.div>
	);
}
//https://protriathletes.org/events/pto-pro-am/herbalife-los-angeles-2022/watch-live/
