export const HOME_PATH = '/';
export const ABOUT_PATH = '/about';
export const RULES_PATH = '/rules';

export const FAQ_PATH = '/faq';
export const CORPORATE_CHALLENGE_PATH = '/corporate-challenge';
export const COURSE_DESCRIPTION_PATH = '/course-description';
export const LA_TRI_CLUB_PATH = '/la-tri-club';
export const TRAINING_PLAN_PATH = '/training-plan';
export const POINT_TO_POINT_PATH = '/point-to-point';
export const WEEKEND_SCHEDULE_PATH = '/weekend-schedule';
export const EXPO_PATH = '/expo';
export const ROAD_CLOSURE_PATH = '/road-closures';
export const ELITES_PATH = '/elites';
export const JDRF_PATH = '/jdrf';
export const AMBASSADOR_PROGRAM_PATH = '/ambassador-program';
export const EVENTS_5K_RUN_PATH = '/events-5k-run';
export const EVENTS_BIKE_TOUR_PATH = '/events-bike-tour';
export const EVENTS_TRIATHLON_AQUABIKE_PATH = '/events-triathlon-aquabike';
export const EVENTS_5K_VIRTUAL_PATH = '/events-5k-virtual';
export const MAP_DETAILS_PATH = '/map-details';
export const SPORTS_NUTRITION_PATH = '/sports-nutrition';
export const CONTACT_PATH = '/contact';
export const PHOTO_GALLERY_PATH = '/photo-gallery';
export const BLOG_PATH = '/blog';
export const MAP_PATH = '/map';

export const HOME_TRIATHLON_PATH = '/home-triathlon';
export const HOME_AQUABIKE_PATH = '/home-aquabike';
export const HOME_5K_PATH = '/home-5k';
export const HOME_BIKETOUR_PATH = '/home-biketour';
export const USAT_PATH = '/usat';
export const PTO_PRO_AM_PATH = '/pto-pro-am';
export const VIRTUAL_CHALLENGE_PATH = '/virtual-challenge';
