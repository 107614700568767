import { useTransition, a } from '@react-spring/web';
import useWindowSize from '../../../common/utilities/useWindowSize';
import styles from './SubMenu.module.scss';
import SubMenuContent from './SubMenuContent';

export default function SubMenu({ activeSubMenuItem = 'none' }) {
	const { height: winHeight } = useWindowSize();
	const activeSubMenuLabel = activeSubMenuItem?.label ?? 'none';

	const transitions = useTransition(activeSubMenuItem, {
		keys: (item) => {
			if (item) {
				return item.label;
			} else {
				return 'none';
			}
		},
		from: { opacity: 0, y: '-100%' },
		enter: { opacity: 1, y: '0%', pointerEvents: 'auto' },
		leave: { opacity: 0, y: '-100%', pointerEvents: 'none' },
	});

	const submenuFragment = transitions(({ opacity, y, pointerEvents }, item) => {
		return item ? (
			<a.div className={styles.SubMenu} key={item.label} style={{ height: winHeight, pointerEvents }}>
				<div style={{ transform: 'skewX(-7deg) translateX(70px)' }}>
					<a.div className={styles.SubMenuBG} style={{ y, opacity, height: winHeight }} />
				</div>
				<a.div style={{ opacity, height: '100%', width: '100%' }}>
					<SubMenuContent
						key={item.label}
						label={item.label}
						items={item.subMenu}
						subMenuOpen={activeSubMenuLabel === item.label}
					/>
				</a.div>
			</a.div>
		) : null;
	});

	return submenuFragment;
}
