import { Canvas } from '@react-three/fiber';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import MapModel from './MapModel';
import { Cloud, Sphere, useProgress } from '@react-three/drei';
import Poi from './Poi';
import MapSideSheet from './MapSideSheet';
import SelectToZoom from './SelectToZoom';
import styles from './ThreeMap.module.scss';
import { Bounds } from './Bounds';
import Rig from './Rig';
import SquareSpinner from '../../common/icons/spinners/SquareSpinner';

const InnerWorld = React.memo(() => {
	const targetRef = useRef();
	const target2Ref = useRef();
	const [targets, setTargets] = useState([]);

	useEffect(() => {
		if (targetRef.current) {
			setTargets([targetRef.current, target2Ref.current]);
		}
	}, []);

	return (
		<>
			<Suspense fallback={null}>
				<Rig>
					<Bounds margin={1.5} damping={2}>
						<SelectToZoom>
							<Poi size={1.3} position={[-10.6, 1.7, 6.5]} location="veniceBeach" />
							<Poi size={1.3} position={[-7, 2, 3.3]} location="marVista" />
							<Poi size={1.3} position={[-3.6, 2, 3]} location="culverCity" />
							<Poi size={0.7} position={[-2, 2, 1.5]} location="helmsBakery" />
							<Poi size={1.3} position={[2, 1.8, -1.1]} location="midCity" />
							<Poi size={1.3} position={[5.5, 1.7, -2.5]} location="koreatown" />
							<Poi size={1.3} position={[12, 1.5, 0.2]} location="dtla" />
							<Poi size={0.7} position={[10, 1.5, -1]} location="laLive" />
							<Poi size={0.7} position={[9.5, 1.5, -2.5]} location="mcarthurPark" />
						</SelectToZoom>
					</Bounds>
					<Cloud position={[-10, 4, -3]} args={[2, 2]} scale={0.4} opacity={0.65} width={5} />
					<Cloud position={[-14, 4, -6]} args={[2, 4]} scale={0.5} opacity={0.65} width={5} />

					<MapModel scale={[0.65, 0.65, 0.65]} rotation={[0, Math.PI, 0]} />
				</Rig>
				<Sphere ref={targetRef} args={[0.2]} position={[3.5, 2, 0]} visible={false}>
					<meshBasicMaterial color="red" />
				</Sphere>
				<Sphere ref={target2Ref} args={[0.2]} position={[10, 2, 0]} visible={false}>
					<meshBasicMaterial color="red" />
				</Sphere>
			</Suspense>
			<directionalLight
				color="#f3d5f7"
				position={[0, 30, 0]}
				intensity={0.26}
				castShadow
				shadow-mapSize={[1024, 1024]}
				shadow-camera-near={1}
				shadow-camera-far={240}
				shadow-camera-right={30}
				shadow-camera-top={30}
				shadow-camera-left={-30}
				shadow-camera-bottom={-30}
				shadow-bias={-0.0001}
			/>
			<directionalLight
				color="#f3d5f7"
				target={targets[0]}
				position={[10, 20, 0]}
				intensity={0.35}
				castShadow
				shadow-mapSize={[1024, 1024]}
				shadow-camera-near={1}
				shadow-camera-far={240}
				shadow-camera-right={30}
				shadow-camera-top={30}
				shadow-camera-left={-30}
				shadow-camera-bottom={-30}
				shadow-bias={-0.005}
			/>
			{/* <spotLight
				color="#fff9de"
				target={targets[0]}
				position={[20, 18, -4]}
				angle={0.38}
				penumbra={1}
				intensity={0.3}
				castShadow
				shadow-mapSize={[2048, 2048]}
				shadow-bias={-0.00002}
			/> */}
			<spotLight
				color="#fff9de"
				target={targets[1]}
				position={[30, 14, -18]}
				angle={0.3}
				penumbra={1}
				intensity={0.6}
				castShadow
				shadow-mapSize={[2048, 2048]}
				shadow-bias={-0.00002}
			/>
		</>
	);
});

function Loader() {
	const [active] = useProgress((state) => [state.active]);

	return (
		active && (
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100vw',
					height: '100vh',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<SquareSpinner />
			</div>
		)
	);
}

export default function ThreeMap() {
	return (
		<div className={styles.ThreeMap}>
			<MapSideSheet />
			<Canvas
				shadows
				camera={{
					position: [-0.7414, 14.3, 6.6743],
					rotation: [-1.2066, -0.0014, -0.0037],
				}}
			>
				<InnerWorld />
			</Canvas>
			<Loader />
		</div>
	);
}
