import { useEffect, useRef } from 'react';

// Convenience function to have a useEffect-based key listener to a component.
// Events to be handled by this function are 'keypress', 'onclick', 'onmousedown', among others. - Frank

export default function useEventListener(eventName, handler, element = window) {
	const savedHandler = useRef();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;

		if (!isSupported) return;

		const eventListener = (event) => savedHandler.current(event);
		element.addEventListener(eventName, eventListener);
		return () => {
			element.removeEventListener(eventName, eventListener);
		};
	}, [eventName, element]);
}
