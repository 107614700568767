import styles from './BlogPostContent.module.scss';
import { Helmet } from 'react-helmet';
import useWindowSize from '../../common/utilities/useWindowSize';
import Footer from '../Footer/Footer';
import { useGesture } from '@use-gesture/react';
import useStore from '../../states/useStore';
import { useParams } from 'react-router';
// import LogoLandscapeDate from '../Logo/LogoLandscapeDate';
import DateIcon from './DateIcon';
import ProfileIcon from './ProfileIcon';
// import BackButton from './BackButton';
import { Link } from 'react-router-dom';
import Close from '../../common/icons/Close';
import useBlogPost from './useBlogPost';
import { useSpring, a } from '@react-spring/web';

const Months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

function Blog() {
	const { width, height } = useWindowSize();
	const dispatchScrollY = useStore((state) => state.dispatchScrollY);
	const { slug } = useParams();

	const bind = useGesture({
		onScroll: ({ offset }) => {
			dispatchScrollY(offset[1]);
		},
	});

	const [CurrentBlogPost, loading] = useBlogPost(slug);
	const date = CurrentBlogPost.date ? CurrentBlogPost.date : new Date();

	const { opacity } = useSpring({
		opacity: loading ? 0 : 1,
	});

	return (
		<>
			<Helmet>
				<title>{`Herbalife24 Tri - Blog`}</title>
				<meta
					name="description"
					content="A selection of news and articles from the world of the Herbalife 24 Triathlon Los Angeles."
				/>
				<meta
					name="keywords"
					content="Herbalife 24, blog, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles"
				/>
				<meta property="og:title" content="Blog" />
				<meta
					property="og:description"
					content="A selection of news and articles from the world of the Herbalife 24 Triathlon Los Angeles."
				/>
			</Helmet>
			<a.div {...bind()} className={styles.BlogPostContent} style={{ height, width, opacity }}>
				<Link to="/blog">
					<div className={styles.back}>
						<Close size="L" />
					</div>
				</Link>

				<div className={styles.content}>
					<div className={styles['title-inner-wrap']}>
						<h3 className={styles['body-title']}>{CurrentBlogPost.title}</h3>
						<div className={styles.info}>
							<div className={styles['info-item']}>
								<DateIcon />
								<span>
									{Months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}
								</span>
							</div>
							<div className={styles['info-item']}>
								<ProfileIcon />
								<span>{CurrentBlogPost.author}</span>
							</div>
						</div>
					</div>
					<div className={styles['header-image']} style={{ backgroundImage: `url(${CurrentBlogPost.titleImage})` }} />
					<div className={styles['inner-wrap']}>{CurrentBlogPost.content}</div>
				</div>
				<Footer />
			</a.div>
		</>
	);
}

export default Blog;
