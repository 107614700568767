import logoSmall from '../../media/logo-landscape-date-small.svg';
import logoLarge from '../../media/logo-date.svg';

export default function LogoLandscapeDate({ size = 'large' }) {
	return size === 'large' ? (
		<img src={logoLarge} alt="Herbalife Triathlon Los Angeles 2021 - October 24th" />
	) : (
		<img src={logoSmall} style={{ aspectRatio: '250/111' }} alt="Herbalife Triathlon Los Angeles 2021 - October 24th" />
	);
}
