import { EVENTS_TRIATHLON_AQUABIKE_PATH } from "../../data/pathConstants";
import Infobox from "../Infobox/Infobox";
import Paragraph from "../Text/Paragraph";
import Subtitle from "../Text/Subtitle";
import Title from "../Text/Title";

import eventsTriathlonAquabikeBg from '../../media/page-bgs/events_triathlon_aquabike_bg.jpg';

export const EventsTriathlonAquabikeData = {
	id: 'eventsTriathlonAquabike',
	url: EVENTS_TRIATHLON_AQUABIKE_PATH,
	pageBg: eventsTriathlonAquabikeBg,
	pageTitle: 'Herbalife24 Triathlon/Aquabike',
	pageSummary: 'Make Los Angeles your playground.',
	pageIcon: '/assets/icons/map.svg',
	pageIntro:
		'The Herbalife24 Triathlon Los Angeles provides a one of a kind tour through the heart of Los Angeles. The swim starts at iconic Venice beach, followed by a relatively flat and fast ride through various LA neighborhoods en route to Downtown Los Angeles. The run takes athletes through more of Downtown LA and Koreatown.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA course description, Herbalife24 triathlon Los Angeles course description, Herbalife24 triathlon Los Angeles, Herbalife24 triathlon LA, venice beach, venice, marina del rey, helms bakery, midcity, koreatown, figueroa corridor, university park, L.A. Live, la live, macarthur park, downtown la, westlake',
	metaDescription:
		'The Herbalife24 Triathlon Los Angeles provides a one of a kind tour through the heart of Los Angeles. The swim starts at iconic Venice beach, followed by a relatively flat and fast ride through various LA neighborhoods en route to Downtown Los Angeles. The run takes athletes through more of Downtown LA and Koreatown.',
};

export default function EventsTriathlonAquabike() {
	return (
		<>
			<Title>International Triathlon</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 1500 meters (.9 mile)
			</Infobox>
			<Paragraph>
				The 1500 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 40 kilometers (25 miles)
			</Infobox>
			<Paragraph>
				The 40km bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>

			<Subtitle>Run</Subtitle>
			<Infobox>
				<b>Distance:</b> 10 kilometers (6.2 miles)
			</Infobox>
			<Paragraph>
				The 10k run travels through Downtown LA before heading West along Wilshire Blvd. towards Koreatown. This two loop course is very spectator friendly.
			</Paragraph>

			<Subtitle>2021 Course Maps</Subtitle>
			<Paragraph>
				<a href="https://herbalife24tri.la/map-details">https://herbalife24tri.la/map-details</a>
			</Paragraph>

			<Title>Sprint Triathlon</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 750 meters
			</Infobox>
			<Paragraph>
				The 750 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 15 miles
			</Infobox>
			<Paragraph>
				The 15 mile bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>

			<Subtitle>Run</Subtitle>
			<Infobox>
				<b>Distance:</b> 5k (3.1 miles)
			</Infobox>
			<Paragraph>
				The 5k run travels through Downtown LA before heading West along Wilshire Blvd. towards Koreatown. This one loop course is very spectator friendly.
			</Paragraph>

			<Subtitle>2021 Course Maps</Subtitle>
			<Paragraph>
				<a href="https://herbalife24tri.la/map-details">https://herbalife24tri.la/map-details</a>
			</Paragraph>

			<Title>International Aquabike</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 1500 meters (.9 mile)
			</Infobox>
			<Paragraph>
				The 1500 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 40 kilometers (25 miles)
			</Infobox>
			<Paragraph>
				The 40km bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>			
		</>
	);
}
