import { RESULTS_2019_URL, RESULTS_2021_URL, RESULTS_2022_URL } from './pageData';
import ShoppingBag from '../common/icons/ShoppingBag';
import {
	ABOUT_PATH,
	RULES_PATH,
	FAQ_PATH,
	CORPORATE_CHALLENGE_PATH,
	LA_TRI_CLUB_PATH,
	TRAINING_PLAN_PATH,
	POINT_TO_POINT_PATH,
	WEEKEND_SCHEDULE_PATH,
	EXPO_PATH,
	ROAD_CLOSURE_PATH,
	ELITES_PATH,
	JDRF_PATH,
	AMBASSADOR_PROGRAM_PATH,
	EVENTS_5K_RUN_PATH,
	EVENTS_BIKE_TOUR_PATH,
	EVENTS_TRIATHLON_AQUABIKE_PATH,
	MAP_DETAILS_PATH,
	SPORTS_NUTRITION_PATH,
	CONTACT_PATH,
	PHOTO_GALLERY_PATH,
	BLOG_PATH,
	MAP_PATH,
	HOME_PATH,
	HOME_TRIATHLON_PATH,
	HOME_AQUABIKE_PATH,
	HOME_5K_PATH,
	HOME_BIKETOUR_PATH,
	PTO_PRO_AM_PATH,
	VIRTUAL_CHALLENGE_PATH,
} from './pathConstants';

const MENU_LIST = [
	{
		label: 'Home',
		mainMenu: true,
		hasSubMenu: false,
		link: HOME_PATH,
		alternateLinks: ['/', HOME_5K_PATH, HOME_AQUABIKE_PATH, HOME_TRIATHLON_PATH, HOME_BIKETOUR_PATH],
	},
	{
		label: 'Info',
		mainMenu: true,
		link: 'info',
		hasSubMenu: true,
		subMenu: [
			{
				label: 'About',
				link: ABOUT_PATH,
			},
			{
				label: 'Rules',
				link: RULES_PATH,
			},
			{
				label: 'USAT State Champ',
				link: 'usat',
			},
			{
				label: 'FAQ',
				link: FAQ_PATH,
			},
			{
				label: 'Corporate Challenge',
				link: CORPORATE_CHALLENGE_PATH,
			},
			{
				label: 'Ambassador Program',
				link: AMBASSADOR_PROGRAM_PATH,
			},
			{
				label: 'Results (2019)',
				link: RESULTS_2019_URL,
				external: true,
			},
			{
				label: 'Results (2021)',
				link: RESULTS_2021_URL,
				external: true,
			},
			{
				label: 'Results (2022)',
				link: RESULTS_2022_URL,
				external: true,
			},
			{
				label: 'Volunteer',
				link: 'https://www.titanvolunteers.com/volunteers/sign_up_individual/465-Herbalife24-Triathlon-Los-Angeles-2022',
				external: true,
			},
		],
	},
	{
		label: 'The Events',
		mainMenu: true,
		link: 'course',
		hasSubMenu: true,
		subMenu: [
			/* {
				label: 'Course Descriptions',
				link: 'course-description',
			}, */
			{
				label: 'Triathlon / Aquabike',
				link: EVENTS_TRIATHLON_AQUABIKE_PATH,
			},
			{
				label: 'Bike Tour',
				link: EVENTS_BIKE_TOUR_PATH,
			},
			{
				label: '5K Run',
				link: EVENTS_5K_RUN_PATH,
			},
			{
				label: 'PTO Pro-Am',
				link: PTO_PRO_AM_PATH,
			},
			{
				label: 'Map Details',
				link: MAP_DETAILS_PATH,
			},
			{
				label: 'Interactive Map',
				link: MAP_PATH,
				disableOnMobile: true,
			},
			{
				label: 'Virtual Challenge',
				link: VIRTUAL_CHALLENGE_PATH,
				mainMenu: true,
				hasSubMenu: false,
			},
			{
				label: 'Road Closures',
				link: 'road-closures',
			},
		],
	},
	{
		label: 'Training',
		mainMenu: true,
		link: 'training',
		hasSubMenu: true,
		subMenu: [
			{
				label: 'LA Tri Club',
				link: LA_TRI_CLUB_PATH,
			},
			{
				label: 'Training Plans',
				link: TRAINING_PLAN_PATH,
			},
			{
				label: 'Nutrition',
				link: SPORTS_NUTRITION_PATH,
			},
		],
	},
	{
		label: 'Race Weekend',
		mainMenu: true,
		link: 'race-weekend',
		hasSubMenu: true,
		subMenuBackgroundLabel: 'Weekend',
		subMenu: [
			{
				label: 'Point to Point Explained',
				link: POINT_TO_POINT_PATH,
			},
			{
				label: 'Weekend Schedule',
				link: WEEKEND_SCHEDULE_PATH,
			},
			{
				label: 'Packet Pick Up / Expo',
				link: EXPO_PATH,
			},
			/* {
				label: 'Hotels',
				link: 'hotels',
				inActive: true,
			}, */
			{
				label: 'Exhibitors',
				link: 'https://eventhub.net/events/2022-Herbalife24-Triathlon-Los-Angeles----5k-Run-Walk---22-mile-Bike-Tour_2853',
				external: true,
			},
			{
				label: 'Road Closures',
				link: ROAD_CLOSURE_PATH,
			},
			{
				label: 'Elites',
				link: ELITES_PATH,
			},
		],
	},
	{
		label: 'Virtual Challenge',
		link: VIRTUAL_CHALLENGE_PATH,
		mainMenu: true,
		hasSubMenu: false,
	},
	{
		label: 'JDRF',
		link: JDRF_PATH,
		mainMenu: true,
		hasSubMenu: false,
	},
	{
		label: 'About Herbalife24',
		mainMenu: true,
		link: 'https://www.herbalife.com/our-products/energy-fitness/sports/',
		external: true,
		hasSubMenu: false,
	},
	/* {
		label: 'Press',
		mainMenu: true,
		link: 'press',
		hasSubMenu: false
	}, */
	{
		label: 'Blog',
		mainMenu: true,
		link: BLOG_PATH,
		hasSubMenu: false,
	},
	{
		label: 'Contact',
		mainMenu: true,
		link: CONTACT_PATH,
		hasSubMenu: false,
	},
	{
		label: 'Photo Gallery',
		mainMenu: true,
		link: PHOTO_GALLERY_PATH,
		hasSubMenu: false,
	},
	{
		label: 'Store',
		mainMenu: true,
		external: true,
		link: 'https://eagraphics.com/herbalife24trila/shop/home',
		hasSubMenu: false,
		icon: <ShoppingBag />,
	},
];

export default MENU_LIST;
