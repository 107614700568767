import { useCallback, useEffect, useState } from 'react';
import { noop } from './utilities';

function getOrientation() {
	return (
		window.screen?.orientation?.type ||
		window.screen?.mozOrientation ||
		window.screen?.msOrientation ||
		window.orientation
	);
}

function supportsScreenOrientation() {
	if (
		window.screen?.orientation ||
		window.screen?.mozOrientation ||
		window.screen?.msOrientation
	) {
		return true;
	} else {
		return false;
	}
}

function isLandscape(orientation) {
	switch (orientation) {
		case 'landscape-primary':
		case 'landscape-secondary':
		case 90:
		case -90: {
			return true;
		}

		default: {
			return false;
		}
	}
}

export default function useIsLandscape(onChange = noop) {
	const [orientation, setOrientation] = useState(false);

	useEffect(() => {
		setOrientation(isLandscape(getOrientation()));
	}, []);

	const onOrientationChange = useCallback(
		(e) => {
			const newOrientation = isLandscape(getOrientation());

			setOrientation(newOrientation);
			onChange(newOrientation);
		},
		[onChange]
	);

	useEffect(() => {
		if (supportsScreenOrientation()) {
			window.screen.orientation.addEventListener('change', onOrientationChange);
		} else {
			window.addEventListener('orientationchange', onOrientationChange);
		}

		return () => {
			if (supportsScreenOrientation()) {
				window.screen.orientation.removeEventListener(
					'change',
					onOrientationChange
				);
			} else {
				window.removeEventListener('orientationchange', onOrientationChange);
			}
		};
	}, [onOrientationChange]);

	return orientation;
}
