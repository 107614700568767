import create from 'zustand';

const useStore = create((set) => ({
	scrollY: 0,
	dispatchScrollY: (val) => set({ scrollY: val }),
	poi: {
		active: null,
		isOpen: false,
	},
	dispatchPoi: (v) => set((state) => ({ poi: { ...state.poi, ...v } })),
}));

export default useStore;
