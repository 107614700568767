import useWindowSize from '../../common/utilities/useWindowSize';
import styles from './BlogBackground.module.scss';
import { useEffect } from 'react';
import useStore from '../../states/useStore';
import { useSpring, a } from '@react-spring/web';
import useBlogList from './useBlogList';

export default function BlogBackground() {
	const { width, height } = useWindowSize();
	// const [recentPost, setRecentPost] = useState({});
	const [blogList] = useBlogList();
	const recentPost = blogList[0] ?? {};

	const [{ yHeader }] = useSpring(() => ({
		yHeader: 0,
		config: { duration: 1 },
	}));
	const [{ yBody }] = useSpring(() => ({
		yBody: -40,
		config: { duration: 1 },
	}));

	useEffect(() => {
		return useStore.subscribe(
			(scrollY) => {
				yBody.start(-Math.min(scrollY, height) - 40);
				yHeader.start(-Math.min(scrollY, height) / 2);
			},
			(state) => state.scrollY
		);
	}, [yBody, yHeader, height]);

	return (
		<div className={styles.BlogBackground}>
			<a.div className={styles.header} style={{ width, height, y: yHeader }}>
				<div className={styles.img} style={{ backgroundImage: `url(${recentPost.titleImage})` }} />
				<div className={styles.gradient} />
			</a.div>
			<a.div className={styles.body} style={{ width, height: height + 40, y: yBody }}></a.div>
		</div>
	);
}
