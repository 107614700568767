import { COURSE_DESCRIPTION_PATH } from '../../data/pathConstants';
import Infobox from '../Infobox/Infobox';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import courseDescriptionBg from '../../media/page-bgs/events_triathlon_aquabike_bg.jpg';

export const CourseDescriptionData = {
	id: 'courseDescription',
	url: COURSE_DESCRIPTION_PATH,
	pageBg: courseDescriptionBg,
	pageTitle: 'Course Descriptions',
	pageSummary: 'An urban athlete experience crafted for all levels of fitness.',
	pageIcon: '/assets/icons/map.svg',
	pageIntro:
		'The Herbalife24 Triathlon Los Angeles provides a one of a kind tour through the heart of Los Angeles. The swim starts at iconic Venice beach, followed by a relatively flat and fast ride through various LA neighborhoods en route to Downtown Los Angeles. The run takes runners through more of Downtown LA and Koreatown.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA course description, Herbalife24 triathlon Los Angeles course description, Herbalife24 triathlon Los Angeles, Herbalife24 triathlon LA, venice beach, venice, marina del rey, helms bakery, midcity, koreatown, figueroa corridor, university park, L.A. Live, la live, macarthur park, downtown la, westlake',
	metaDescription:
		'Course description for the Herbalife24 Triathlon Los Angeles 2021. The course provides a one-of-a-kind tour through the heart of Los Angeles.',
}

export default function CourseDescription() {
	return (
		<>
			<Title>International Triathlon</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 1500 meters (.9 mile)
			</Infobox>
			<Paragraph>
				The 1500 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to
				the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 40 kilometers (25 miles)
			</Infobox>
			<Paragraph>
				The 40km bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic
				Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>

			<Subtitle>Run</Subtitle>
			<Infobox>
				<b>Distance:</b> 10 kilometers (6.2 miles)
			</Infobox>
			<Paragraph>
				The 10k run travels through Downtown LA before heading West along Wilshire Blvd. towards Koreatown. This two
				loop course is very spectator friendly.
			</Paragraph>

			<Title>Sprint Triathlon</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 750 meters
			</Infobox>
			<Paragraph>
				The 750 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to
				the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 15 miles
			</Infobox>
			<Paragraph>
				The 15 mile bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic
				Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>

			<Subtitle>Run</Subtitle>
			<Infobox>
				<b>Distance:</b> 5k (3.1 miles)
			</Infobox>
			<Paragraph>
				The 5k run travels through Downtown LA before heading West along Wilshire Blvd. towards Koreatown. This one loop
				course is very spectator friendly.
			</Paragraph>

			<Title>International Aquabike</Title>
			<Subtitle>Swim</Subtitle>
			<Infobox>
				<b>Distance:</b> 1500 meters (.9 mile)
			</Infobox>
			<Paragraph>
				The 1500 meter swim will be a course in the Pacific Ocean. The swim will end on the beach, with a short run to
				the transition area in Venice Beach. Expect waves.
			</Paragraph>

			<Subtitle>Bike</Subtitle>
			<Infobox>
				<b>Distance:</b> 40 kilometers (25 miles)
			</Infobox>
			<Paragraph>
				The 40km bike leaves Venice Beach and follows Venice Blvd to La Brea, for a quick uphill climb to Olympic
				Boulevard. Olympic Blvd. features rolling hills and a nice descent into Downtown LA.
			</Paragraph>

			<Title>Sprint Aquabike</Title>
			<Infobox>
				<b>Distance:</b> 750 meters (.45 mile)
			</Infobox>
			<Paragraph>
				750m swim starting at Venice Beach followed by a 15 mile bike, ending at the DTLA Finish Line Festival.
			</Paragraph>

			<Title>Herbalife24 DTLA 5k Run/Walk</Title>
			<Infobox>
				<b>Distance:</b> 5k (3.1 miles)
			</Infobox>
			<Paragraph>
				The Herbalife24 DTLA 5k Run/Walk travels part of the triathlon run course - taking runners through Downtown LA
				and Koreatown before a spectacular finish at LA Live.
			</Paragraph>

			<Title>Bike Tour</Title>
			<Infobox>
				<b>Distance:</b> 22 miles
			</Infobox>
			<Paragraph>
				Enjoy a 22 mile bike tour through the heart of LA, starting in Venice Beach and ending at the DTLA Finish Line
				Festival.
			</Paragraph>
		</>
	);
}