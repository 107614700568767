import { AMBASSADOR_PROGRAM_PATH } from '../../data/pathConstants';
import Profile from '../Profile/Profile';

import ambassadorProgramBg from '../../media/page-bgs/ambassador_program_bg.jpg';

export const AmbassadorProgramData = {
	id: 'ambassadorProgram',
	url: AMBASSADOR_PROGRAM_PATH,
	pageBg: ambassadorProgramBg,
	pageTitle: 'Ambassador Program',
	pageSummary: 'Meet our ambassadors.',
	pageIcon: '/assets/icons/icon_schedule.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, JW Marriott, JW Marriott Los Angeles, JW Marriott LA, LA Live, microsoft theater events, microsoft theater',
	metaDescription: 'Meet our ambassadors.',
};

export default function AmbassadorProgram() {
	return (
		<>
			<Profile name="James Brennan" link="@mandalan">
				<p>
					10 years ago James joined the Triathlon Team at Sony Pictures, where he worked at the time. He soon became
					co-captain of the team and for several years helped raise funds for Children's Hospital Los Angeles and built
					a community of athletes around the sport. Since being introduced to triathlon he continues to be inspired by
					endurance challenges and has so far completed 2 Half Ironmans, competed in the International Distance
					Herbalife24 Triathlon in both its first two years, raced in the LA Marathon, and completed long distance
					cycling and ocean swim races. James continues to train year-round with the LA Tri Club, LA Running Club, and
					Southern California Aquatics. He is also a songwriter and music producer, creating a tropical house genre of
					dance/pop music under his artist name, Mandalan.
				</p>
			</Profile>

			<Profile name="Nathan Patton" link="@nathan.carrothers">
				<p>
					Nathan first dipped his toes into the triathlon waters in 2020 after running forced him to look at other forms
					of exercise. After building a solid year of triathlon training thanks to COVID and race cancellations, he made
					his debut in May 2021 at IRONMAN St. George 70.3. Nathan competed in three additional 70.3 races in 2021
					including the 70.3 World Championships in St. George. He trains with Tower 26 out of Los Angeles, and will
					fight you for the Mandeville Canyon local legend title.
				</p>
			</Profile>

			<Profile name="Carleigh Moore" link="@adventure.moore">
				<p>
					Carleigh ventured into the sport of triathlon in 2009 after spending the summer teaching a couple to swim for
					the Long Beach Triathlon. Fast-forward to 2022 and the Herbalife24 Triathlon LA will be her 48th triathlon. In
					that time she has completed 7 Ironman 70.3 distances and two Ironmans. Endurance sports changed her life, led
					her to meet her incredible husband and also changed her professional endeavors. “If only that couple knew what
					an impact they made on my life by introducing me to this sport,” said Carleigh. She truly loves all distances
					and elements of the sport and you can always find her on the race course with a huge grin on her face, even
					when she’s in pain. She loves encouraging athletes from all backgrounds to tackle their dreams. After leaving
					Los Angeles shortly before COVID, Carleigh looks forward to returning to a city she called home for so many
					years for a very special race across the City of Angels!
				</p>
			</Profile>

			<Profile name="Janae Carey" link="@thejanaewillow @getcareydaway_">
				<p>
					Janae is currently stationed in San Diego as a Naval Officer on limited duty due to an injury from her
					deployment in Japan. As a part of her recovery, she began to bike more and decided to give open water swimming
					a try. She ended up loving it and needed an outlet to focus her attention towards since her military career
					was shifting. Last year, the Herbalife24 Triathlon LA was the day before her birthday and Janae challenged
					herself to participate in the sprint Aquabike. She had the best time and is now planning to do 4 races this
					year!
				</p>
			</Profile>

			<Profile name="Allyson O'Neil" link="@allysononeil">
				<p>
					In a mid-pandemic YOLO moment, Allyson signed up for her first triathlon last summer and fell in love with the
					sport immediately! As she loaded her bike into her car after the 2021 Herbalife24 Triathlon LA Sprint, she was
					already thinking about and planning her 2022 race season. “The triathlon community is welcoming like no other
					and I especially love the accessibility of triathlons, with the sport offering up races for everyone from
					first-timers to Olympic athletes,” said Allyson. When she's not training, Allyson divides up her time as a
					television producer, wife to her #1 fan, and full-time dog mom to rescue pups, Ned and Goose.
				</p>
			</Profile>

			<Profile name="Diana Ramirez" link="@dmlovelife_">
				<p>
					At the beginning of 2021, Diana couldn’t even run a mile. She was out of shape and not in her best health. She
					decided to take her health back and signed up for a race. She decided to do a triathlon because she thought
					“how hard can it be? Swim, bike and run!” But she was in for a big surprise! She signed up for the Malibu
					Triathlon in September. It was a challenging race, but she was determined to improve and signed up for the
					Herbalife24 Triathlon the next month. This year she is training for her first Half Ironman. In her spare time,
					Diana enjoys going to local bookstores, trying new vegan food, and photography.
				</p>
			</Profile>

			<Profile name="Joshua Izumigawa" link="@daizbiz">
				<p>
					Prior to 2013, Josh would not have considered himself an endurance athlete. He lifted weights in the gym,
					played recreational sports during the week and spent most weekends playing beach volleyball. In fact, running
					a mile was a chore. But on a dare, Josh and some friends entered the Redondo Beach Sprint triathlon with no
					training and little knowledge about what triathlon even meant. The race was full of beginner mistakes: using a
					surfing wetsuit, riding on a mountain bike with suspension unlocked, crushing a protein shake and changing
					clothes before the run.
				</p>

				<p>
					It was grueling, but the challenge and that finish line feeling was exhilarating! And that's how Josh became
					hooked! Almost a decade later, Josh definitely considers himself an endurance athlete with 2 full Ironmans, 9
					half Ironmans, 7 half marathons, 7 full marathons and countless olympic and sprint distance triathlons under
					his belt! Don't get it twisted though - Josh is never one of those athletes on the podium, but that's not what
					he's about. Josh loves triathlon for the healthy balance it brings to his life and the variety of training he
					gets to do every week to keep things fun and fresh! And he wants you to know that you don't have to do
					triathlons to win! Just enjoy the ride!
				</p>
			</Profile>

			<Profile name="Sydney Miyatake" link="@sam5x01 and @fit5yd">
				<p>
					While in school at the University of Hawai’i, Sydney would venture down to Kona for weekend trips with her
					friends. They ended up agreeing to volunteer at an Ironman on the Kona side of the island. “We had no idea
					what an Ironman was, but it was something different to do so we agreed. We handed out soda water and were told
					to “dodge the sponges,” said Sydney.
				</p>

				<p>
					After watching all the athletes in awe, Sydney told herself this was something she wanted to accomplish by the
					time she was 30 years old. 30 came and went and she hadn’t heard or seen anything about triathlon or Ironmans.
					A couple more years went by and Sydney had gained a lot of weight and was in a deep hole of depression and
					anxiety. After a lot of hard work, she eventually signed up for a half marathon, began swimming, and got a
					hold of a cheap bike so she could sign up for her first Sprint Triathlon. It was “just to see if I liked it,”
					said Sydney. She soon fell in love with the sport and quickly signed up for more races.
				</p>

				<p>
					“I wish I knew about triathlons as a child,” she said. “This is why I wanted to become an Herbalife24
					Triathlon LA ambassador. I want to reach out to my community and get the youth involved. Kids run, bike and
					swim all the time, why not make it a race, right?!”
				</p>

				<p>
					Thanks to triathlons Sydney feels confident physically and mentally. “I’m so happy I found and fell in love
					with this sport. It’s really changed the way I live and it’s a happier life,” she said.
				</p>
			</Profile>
		</>
	);
}
