import { useSpring, a } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import MainMenuStatusState from '../../states/MainMenuStatus';
import styles from './Landscape.module.scss';

export default function CityLights() {
	const ref = useRef();
	const MainMenuStatus = useRecoilValue(MainMenuStatusState);
	const width = 2080;

	const lightsTranStyles = useSpring({
		opacity: MainMenuStatus ? 0 : 1,
		y: MainMenuStatus ? 40 : 0,
	});

	const lightUp = () => {
		if (!ref.current) return;
		const random = Math.floor(1 + Math.random() * ref.current.childElementCount);
		const child = document.querySelector(`.${styles.CityLights} path:nth-child(${random})`);
		child.style.opacity = 1;
		setInterval(() => (child.style.opacity = 0), 8850);
	};

	useEffect(() => {
		const loop = setInterval(lightUp, 800);
		return () => clearInterval(loop);
	}, []);

	return (
		<div className={styles.CityLights} style={{ width }}>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2726.66 735.01">
				<a.g ref={ref} data-name="CITY HT" style={lightsTranStyles}>
					<path
						d="M857.87,437.08a4.54,4.54,0,0,1-4.5-4.27c.06-2.66,2.75-3.94,3.9-4.38l.24-.09.26,0c2.67.41,3.93,1.88,3.85,4.51-.11,3.65-2.28,4.19-3.57,4.19Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M948,468.23c-2.21,0-3.59-1.51-4.1-4.49a2.89,2.89,0,0,1,.58-2.23,5,5,0,0,1,3.27-1.87,4.69,4.69,0,0,1,.62-.05c2.22,0,2.39,2,2.48,2.91.06.75.12,1.09.3,1.14l.86.27-.17.88c-.44,2.28-1.7,3.44-3.74,3.44Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1112.92,499.14a3.72,3.72,0,0,1-3.73-3.41,2.23,2.23,0,0,1,0-.37c.23-2.23,1.26-4.51,3.51-4.51,1.92,0,3.5,1.38,4.32,3.57l0,.1,0,.1c.55,3.67-2.29,4.22-3.36,4.43l-.36.07h-.41Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1068.29,498.26a5.07,5.07,0,0,1-.55,0,3.87,3.87,0,0,1-3.11-1.08,2.5,2.5,0,0,1-.35-2.25c.33-1.45.91-4.07,3.35-4.07a3.32,3.32,0,0,1,.53,0,4.49,4.49,0,0,1,3.75,4.29C1071.83,497,1070.37,498.26,1068.29,498.26Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M872.51,452.54a3.34,3.34,0,0,1-.62-.06c-2.58-.49-2.62-3.5-2.63-4.49a2.84,2.84,0,0,1,.69-2.11,3.39,3.39,0,0,1,2.53-.74l.86,0h.7l.09,0a3,3,0,0,1,2.35,3.53l0,.14C876,450.68,874.51,452.54,872.51,452.54Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1308.31,483.65h-.3a3.38,3.38,0,0,1-2.25-1.39,3.29,3.29,0,0,1-.55-2.49v-.08a5.29,5.29,0,0,1,2.57-3.69c.2-.15.39-.29.58-.45l.79-.64.63.8a6.34,6.34,0,0,0,.91.85c.9.72,2.13,1.72,1.66,3.42C1311.75,482.2,1310.16,483.65,1308.31,483.65Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1398.36,436.94c-1.82,0-2.94-1.52-3-4.07a3.56,3.56,0,0,1,.72-2.66,2.34,2.34,0,0,1,1.74-.65l.51,0a3.51,3.51,0,0,1,.88-.12c.9,0,2.48.42,2.93,3.23a3.75,3.75,0,0,1-3.22,4.21,3.11,3.11,0,0,1-.43,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1097,483.81c-2.32,0-2.6-2.42-2.69-3.22l0-.15a2.6,2.6,0,0,1,.47-2,4,4,0,0,1,3-1.1l.24,0h.37c2,0,3,1.43,3.88,2.69l.44.64-.51.59a7.72,7.72,0,0,1-4.59,2.55A3.44,3.44,0,0,1,1097,483.81Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M886.59,467.15a3.22,3.22,0,0,1-3.17-2.74,2.71,2.71,0,0,1,0-.41c0-2.33,1.92-3,3.44-3.55l1-.37.34-.14.34.11a3,3,0,0,1,2.09,2.86,4,4,0,0,1-.86,3.06,3.75,3.75,0,0,1-2.72,1.15Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1428.23,483.28a7.7,7.7,0,0,0-.86-.85,4.62,4.62,0,0,1-2-3.67,2.35,2.35,0,0,1,2.74-2.5h.83c2.23,0,3.67,1.26,3.67,3.2a4.11,4.11,0,0,1-2.19,3.36,9,9,0,0,0-.76.59l-.78.71Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M510.32,327.5a8.14,8.14,0,0,0-.5-.75,4.85,4.85,0,0,1-1.14-2.49,3.25,3.25,0,0,1,.83-2.45,3.61,3.61,0,0,1,2.69-1.05h.26a3.7,3.7,0,0,1,2.61,1.15,2.94,2.94,0,0,1,.73,2.19c-.12,2-1.65,2.61-2.77,3.08a6.54,6.54,0,0,0-1.27.63l-.89.62Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1083.6,483.82a3.75,3.75,0,0,1-.73-.07,3.59,3.59,0,0,1-3.11-3.57,2.73,2.73,0,0,1,.57-2.16,2.26,2.26,0,0,1,1.73-.64l.46,0h.35a.63.63,0,0,0,.2,0l.31-.15.31.08a5.79,5.79,0,0,0,.79.06,2.6,2.6,0,0,1,2.22.79,2,2,0,0,1,.18,1.77c-.05.16-.09.34-.14.54C1086.46,481.65,1086,483.82,1083.6,483.82Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M933.1,467.16a2.77,2.77,0,0,1-.49,0H932a2.62,2.62,0,0,1-2-.65,2.45,2.45,0,0,1-.61-1.83,3.69,3.69,0,0,1,3.37-3.95,3.77,3.77,0,0,1,.57-.05c2.55,0,2.89,2.69,3,3.58l0,.13a2,2,0,0,1-.54,1.6A3.77,3.77,0,0,1,933.1,467.16Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M437.22,281.05a2.81,2.81,0,0,1-.88-.15l-.32-.08c-.91-.23-3-.77-2.63-3.38a3.78,3.78,0,0,1,3.17-3.43h.21a3.17,3.17,0,0,1,2.37.92,3,3,0,0,1,.53,2.41,4.81,4.81,0,0,0,0,.52C439.64,279.8,438.69,281.05,437.22,281.05Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M511.2,311.63a2.55,2.55,0,0,1-1.91-.7,3.94,3.94,0,0,1-.76-3c0-2.54,1.68-2.82,2.41-2.82.16,0,.34,0,.53,0a3.6,3.6,0,0,1,3.27,2.62l0,.11V308c.14,2.95-1.25,3.45-2.62,3.55l-.33,0A4.84,4.84,0,0,1,511.2,311.63Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M512.21,343.31c-1.05,0-1.77-1-2.47-1.92a10.67,10.67,0,0,0-.68-.87l-.71-.76.78-.67a2.88,2.88,0,0,0,.56-1.07,3.34,3.34,0,0,1,2.35-2.53l.35-.09.33.16a3.86,3.86,0,0,1,2.15,4c0,3.41-1.79,3.78-2.56,3.78Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M933.08,482.69a3.64,3.64,0,0,1-3.62-3.38v-.07a2.62,2.62,0,0,1,.46-2.12,3.19,3.19,0,0,1,2.48-.88l.26,0H933a2.55,2.55,0,0,1,2.76,3.17c0,.09,0,.26,0,.42,0,.93-.05,2.88-2.36,2.88h-.27Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1445,482.83a1.24,1.24,0,0,1-.33,0c-1.74-.23-4-.91-4.13-4,0-2.3,1.55-2.64,2.47-2.64h.12c2,.05,2.94,1.35,3.73,2.39.21.28.42.55.64.8l.66.72-.71.68a6.58,6.58,0,0,0-.62.73C1446.33,482.14,1445.81,482.83,1445,482.83Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1128.63,482.72a4.18,4.18,0,0,1-.59,0h-.36a2.94,2.94,0,0,1-2.25-.68,2.41,2.41,0,0,1-.59-1.79,3.66,3.66,0,0,1,3.27-3.93h.11a4.24,4.24,0,0,1,.53,0c2.26,0,2.34,2,2.38,3.1v.23a3.15,3.15,0,0,1-.7,2.46A2.4,2.4,0,0,1,1128.63,482.72Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M946.85,482.7a2.26,2.26,0,0,1-1.53-.51,3.23,3.23,0,0,1-.89-2.4,3.64,3.64,0,0,1,.82-2.93,3.06,3.06,0,0,1,2.61-.62l.78.06a2.5,2.5,0,0,1,2,.61,1.28,1.28,0,0,1,.27,1l-2-.26a.73.73,0,0,0,.4.74,5.54,5.54,0,0,0-.76-.08c-.29,0-.6,0-.9-.07-.73-.09-1,.06-1,.1s-.25.35-.19,1.35c.05.75.13.95.18,1h0a1,1,0,0,0,.45,0l.2,2Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1099.63,497.11a5.32,5.32,0,0,1-4.55-2.53,2.15,2.15,0,0,1,.09-2.2,3,3,0,0,1,2.33-1.53,1.52,1.52,0,0,1,.42.06c.28.07.61.12.93.18,1.3.23,2.91.52,3.14,2s.1,2.66-.53,3.35a2.18,2.18,0,0,1-1.68.66Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M435.79,296c-2.24,0-2.33-1.82-2.37-2.6a3.87,3.87,0,0,0,0-.48,3.08,3.08,0,0,1,.58-2.52,3.31,3.31,0,0,1,2.6-.86H437a2.67,2.67,0,0,1,2.07.83c.57.68.71,1.67.46,3.31-.22,1.12-1.56,2.28-3.61,2.32Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M766.72,373.76c-1.66,0-2.55-.75-2.63-2.24a3.64,3.64,0,0,1,3.06-4.07,4.84,4.84,0,0,1,.87-.08,2.28,2.28,0,0,1,1.73.66,2.71,2.71,0,0,1,.62,2.05c-.11,2.21-1.43,3.41-2.69,3.63A9.32,9.32,0,0,1,766.72,373.76Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M391,498.25a2.41,2.41,0,0,1-2.69-3.06c.28-2,1.44-3.26,3-3.26a1.72,1.72,0,0,1,.32,0c1.75.18,2.83,1.54,2.9,3.64a2.46,2.46,0,0,1-.56,2,2.86,2.86,0,0,1-2.26.69h-.17Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1322.61,482.69a2.35,2.35,0,0,1-1.88-.74,2.9,2.9,0,0,1-.44-2.38,3.56,3.56,0,0,1,3.55-3.33h.19l.24,0a2.08,2.08,0,0,1,1.58.69c.65.72.69,1.82.56,3.23-.15,1.11-1.41,2.34-3.46,2.49Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M572.7,327.18a5.09,5.09,0,0,1-1-.12,3.75,3.75,0,0,1-2.47-1.57,2.83,2.83,0,0,1-.29-2.2,3.09,3.09,0,0,1,2.81-2.54,2.78,2.78,0,0,1,.75.11,3.48,3.48,0,0,1,2.52,3.75C575,326.22,574.12,327.18,572.7,327.18Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1398.13,420.4a3.87,3.87,0,0,1-2.69-3.59,2.69,2.69,0,0,1,.6-2.09,2.33,2.33,0,0,1,1.8-.69,4.45,4.45,0,0,1,.61,0,3.3,3.3,0,0,1,3.17,3.36,3.42,3.42,0,0,1-3.1,3h-.2Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M872.7,466.09a2.53,2.53,0,0,1-2.34-2,2.18,2.18,0,0,0-.35-.72l-1.27-.79,1.22-.87c.22-.16.46-.38.71-.6a3.5,3.5,0,0,1,2.12-1.21,1.31,1.31,0,0,1,.44.07l.1,0a10.62,10.62,0,0,1,3.11,2.15l.34.34-.06.48c-.29,2.67-1.62,3-2.88,3a3.27,3.27,0,0,0-.39,0A3.33,3.33,0,0,1,872.7,466.09Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M857.74,420.47a3.45,3.45,0,0,1-3.34-2.89c-.18-1.79,1.05-3.19,3.07-3.48a4.34,4.34,0,0,1,.71-.06c1.54,0,2.43,1,2.43,2.68a3.75,3.75,0,0,1-1.52,2.95,4.91,4.91,0,0,0-.46.45l-.35.42Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M572.54,482.69l-.5,0a3.28,3.28,0,0,1-3.25-3.28c0-1.44,1.06-2.6,2.82-3.08l.27-.08.26.08a3.81,3.81,0,0,1,2.82,3.53,2.71,2.71,0,0,1-.59,2.15A2.37,2.37,0,0,1,572.54,482.69Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M737.86,482.71a5.12,5.12,0,0,1-.64,0,3.34,3.34,0,0,1-3.05-2.83,2.34,2.34,0,0,1,.51-1.89,2.57,2.57,0,0,1,2-.63,2.26,2.26,0,0,0,.74-.07l.26-.43.5-.05a2,2,0,0,1,.34,0,1.76,1.76,0,0,1,1.34.51,2.09,2.09,0,0,1,.42,1.68,1.94,1.94,0,0,0,0,.33,3.25,3.25,0,0,1-.53,2.68A2.43,2.43,0,0,1,737.86,482.71Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M752.44,482.84a2.89,2.89,0,0,1-2.61-1.64,2.2,2.2,0,0,1-.17-.45l0-.08v-.09c-.25-2.64,2.13-3.67,4-4.5l1.4-.6V477a7.33,7.33,0,0,0,.2,1.45c.26,1.25.65,3.14-1.61,4.13A2.86,2.86,0,0,1,752.44,482.84Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M436.69,311.47a1.67,1.67,0,0,1-.31,0,3.05,3.05,0,0,1-2.35-1.34,2.15,2.15,0,0,1,.05-2.1,3.38,3.38,0,0,0,.14-.57c.14-.68.45-2.27,2.14-2.27a2.9,2.9,0,0,1,.48,0,3.48,3.48,0,0,1,2.83,3.19v.24l-.1.23C439.14,309.84,438.41,311.47,436.69,311.47Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M632,404.81a2,2,0,0,1-.34,0,3.06,3.06,0,0,1-2.8-3.19c.12-1.33.86-2.73,2.24-2.73a2.29,2.29,0,0,1,1.16.35,4.37,4.37,0,0,0,.49.12A2.29,2.29,0,0,1,635,402l0,.17-.08.16C634.46,403.26,633.71,404.81,632,404.81Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M572.2,467.14a2.42,2.42,0,0,1-1.8-.65,3.22,3.22,0,0,1-.62-2.45c0-.14,0-.28,0-.42,0-.78,0-2.84,2-2.84l.39,0a3.62,3.62,0,0,1,2.87,3.68,2.43,2.43,0,0,1-.5,1.8,2.75,2.75,0,0,1-2,.84Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1383.87,451.59a2.4,2.4,0,0,1-1.79-.65,3.19,3.19,0,0,1-.63-2.44c0-.14,0-.29,0-.43V448c0-.74,0-2.72,2.19-2.77h.16l.17.05a3.84,3.84,0,0,1,2.69,3.76,2.35,2.35,0,0,1-2.45,2.58Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M572,342.59a1.46,1.46,0,0,1-.29,0,3.11,3.11,0,0,1-2.37-1.36,2.12,2.12,0,0,1,.06-2.08,3.2,3.2,0,0,0,.16-.6c.14-.73.45-2.23,2-2.23a2.37,2.37,0,0,1,.63.08,4.65,4.65,0,0,1,2.05,1.5,7.09,7.09,0,0,0,.54.55l.56.49-.29.67C574.45,340.93,573.72,342.59,572,342.59Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M844,436.91a2.89,2.89,0,0,1-2-1.54c-1.31-2.35.23-3.57,1.25-4.37a4.92,4.92,0,0,0,1.2-1.16l1.34-2,.48,2.38c.52,2.59.78,5-1.64,6.56l-.24.15Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M916.4,451.27l-.5-.55c-1-1.05-1.52-1.62-1-2.37a24.15,24.15,0,0,1,2.7-2.74l.88-.76.62,1a6.92,6.92,0,0,0,.44.59,3.32,3.32,0,0,1,1,2.17c-.11,1.57-1.35,2.72-3.24,3l-.54.08Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1414.34,404.5a2.16,2.16,0,0,1-1-.27l-.33-.06a2.39,2.39,0,0,1-1.89-1,2,2,0,0,1,0-1.79,2.52,2.52,0,0,0,.11-.51c.13-.69.42-2.33,2.15-2.33a2.9,2.9,0,0,1,.48,0,3.51,3.51,0,0,1,2.82,2.92l0,.23-.07.22C1416.4,402.78,1415.86,404.5,1414.34,404.5Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M618.6,404.59a1.44,1.44,0,0,1-1-.47c-.14-.14-.47-.16-1-.17-.8,0-2.45,0-2.75-2a2,2,0,0,1,1.71-2.38c.45-.14.65-.22.75-.4l.48-.87.88.47a3.59,3.59,0,0,1,2.33,3.5C620,404.17,619.25,404.59,618.6,404.59Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M422.38,296h-.63a2.29,2.29,0,0,1-1.93-.85,2.73,2.73,0,0,1-.29-2.43s.91-2.73,2.33-2.73c1.78.16,2.79,1.51,2.79,3.61A2.1,2.1,0,0,1,422.38,296Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M586.32,467.27c-.67,0-1.8-.32-1.86-2.5a3,3,0,0,0-.14-.62,2.43,2.43,0,0,1,0-2,1.77,1.77,0,0,1,1.31-.76l.43,0c1.69,0,2.07,1.53,2.27,2.36s.3,1,.48,1l-.27,2a3.42,3.42,0,0,0-.84.24A3.85,3.85,0,0,1,586.32,467.27Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M858,450.45h-.46c-2.19-.15-2.15-2.24-2.13-2.92a2.2,2.2,0,0,1,2.37-2.38l.4,0c.91,0,2.39.38,2.43,2.62A2.39,2.39,0,0,1,858,450.45Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M542.22,497.83l-.31,0h-.09l-.09,0a8.78,8.78,0,0,1-2.56-1.28l-.89-.64,4.14-4.72.73,1.05c.17.24.36.46.54.67a3.84,3.84,0,0,1,1.09,2,2.55,2.55,0,0,1-.43,1.86,2.58,2.58,0,0,1-1.66,1.08A2.49,2.49,0,0,1,542.22,497.83Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M768.46,358.22a2.68,2.68,0,0,1-.72-.11c-1.47-.47-2.8-1.7-2.45-3.41a3.88,3.88,0,0,1,1.34-1.94c.16-.15.33-.31.48-.47l.74-.82.74.82c.13.14.27.27.42.41a3.27,3.27,0,0,1,1.34,2.89,2.76,2.76,0,0,1-.58,2.08A1.75,1.75,0,0,1,768.46,358.22Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M527.37,357.78a2.19,2.19,0,0,1-.89-.2,3.5,3.5,0,0,0-.46-.11,2.4,2.4,0,0,1-2.38-2.64,2.15,2.15,0,0,1,2-2,2.75,2.75,0,0,0,.47-.14l.34-.89.89.31c.58.19,1.94.74,2.22,1.8a1.45,1.45,0,0,1-.34,1.38s-.06.31-.1.49C529,356.4,528.74,357.78,527.37,357.78Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M512.38,357.87a2.62,2.62,0,0,1-1-.23,17,17,0,0,1-2.14-1.34l-1-.71,4.21-4,2.8,3.23-.46.64a5.81,5.81,0,0,0-.39.71C514.09,356.86,513.59,357.87,512.38,357.87Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1323.45,466.78a3.84,3.84,0,0,1-2.13-4,2.16,2.16,0,0,1,2.12-2.09h0a2,2,0,0,1,.51.06l.21.05.17.13.41.28c.72.49,2.07,1.39,1.64,3.06l0,.1a4.74,4.74,0,0,1-1.94,2.34l-.46.29Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M707.75,389.14a1.59,1.59,0,0,1-1.16-.55,4.35,4.35,0,0,0-.61-.47,2.71,2.71,0,0,1-1.49-3.21c.34-1.11,1-1.76,1.89-1.76a1.88,1.88,0,0,1,1.14.41,21.63,21.63,0,0,1,1.87,1.66c.3.29.63.61,1,.94l1.35,1.24-1.78.47a1.92,1.92,0,0,0-.91.6A1.87,1.87,0,0,1,707.75,389.14Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M525.33,326.6a8,8,0,0,1-1.55-2.39l0-.08,0-.1a1.74,1.74,0,0,1,.26-1.45,2.34,2.34,0,0,1,1.84-.74l.2,0h.32a2.4,2.4,0,0,1,1.81.67,2.65,2.65,0,0,1,.57,2.1l0,.41L526,327.3Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1172.53,482.23a1.46,1.46,0,0,1-1.11-.47,2.33,2.33,0,0,1-.4-1.84c0-.11,0-.22,0-.31,0-2.22,1.9-2.23,2.61-2.24h.23c.89,0,2.24.33,2.32,2.49l0,.52-.41.31a9.08,9.08,0,0,1-2.88,1.49l-.09,0h-.09Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M692.7,404.67l-.26,0c-1.16-.19-2-1.68-2.29-2.63a1.76,1.76,0,0,1,.23-1.54,2.47,2.47,0,0,1,2-.88h.27c1.65,0,2.53,1.08,2.59,3.2l0,.58-.5.3,0,0A3.34,3.34,0,0,1,692.7,404.67Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M421.6,373.75a3.26,3.26,0,0,1-.58-.06,2.16,2.16,0,0,1-1.57-2.5V371a2.19,2.19,0,0,1,2.28-2.48,3.2,3.2,0,0,1,.52,0l.23,0a1.77,1.77,0,0,1,1.56.73A1.84,1.84,0,0,1,424,371l-.08.17-.14.13a3.18,3.18,0,0,0-.17.54C423.45,372.44,423.12,373.75,421.6,373.75Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M692.1,389.25a2,2,0,0,1-1.36-.54l-.11-.1-.07-.12c-1.16-1.93-.58-3.72,1.72-5.31l1.39-1,.17,1.68c0,.35.11.7.18,1,.25,1.2.58,2.86-1.08,4.08l-.15.12-.19.05A2.07,2.07,0,0,1,692.1,389.25Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M947.57,451.41c-1.85,0-2.09-1.62-2.19-2.32a2.38,2.38,0,0,0-.15-.68l-2.08-1.34,2.45-.46a4.07,4.07,0,0,1,4.52,2.33,1.11,1.11,0,0,1,0,1,3.08,3.08,0,0,1-1.78,1.41A4.5,4.5,0,0,1,947.57,451.41Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1459.31,482.34a1.93,1.93,0,0,1-1.33-.61l-.34-.05a2.05,2.05,0,0,1-1.48-.74,1.73,1.73,0,0,1-.22-1.38,2.68,2.68,0,0,1,2.63-2.17,2.81,2.81,0,0,1,.49.05h.08l.12,0a4.51,4.51,0,0,1,1.58,1.14,7.21,7.21,0,0,0,.64.59l1.15.93-1.29.72a2.22,2.22,0,0,0-.47.49A2,2,0,0,1,1459.31,482.34Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M647.75,419.36c-.24,0-.48,0-.71-.05l-.17,0a2,2,0,0,1-1.72-2.92,3.14,3.14,0,0,1,2.49-1.17,2,2,0,0,1,.38,0,2.08,2.08,0,0,1,2.07,2l0,.23-.07.22A2.28,2.28,0,0,1,647.75,419.36Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M768.34,388.25l-.47,0-.22,0h-.24a2.24,2.24,0,0,1-2-.9,2.09,2.09,0,0,1,0-2.08l0-.06,0-.07a8.1,8.1,0,0,1,1.59-1.82l1-.81,2,3.91v.24A1.53,1.53,0,0,1,768.34,388.25Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1383.32,436a1.49,1.49,0,0,1-1.21-.54,1.61,1.61,0,0,1-.23-1.42l0-.17.1-.15a3.18,3.18,0,0,1,2.51-1.82,2.48,2.48,0,0,1,.54.06l.11,0,.1.05a2.37,2.37,0,0,1,1.31,1.74v.16a1.9,1.9,0,0,1-2.07,2.06c-.21,0-.44,0-.65,0h-.08A2.8,2.8,0,0,1,1383.32,436Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1474.67,481.51c-.74,0-1.78-.35-2.5-2a1.5,1.5,0,0,1,0-1.45,1.68,1.68,0,0,1,1.5-.65l.24,0c.2,0,.41,0,.62,0a2.18,2.18,0,0,1,2.26,2.11l.07.47-.32.35a.94.94,0,0,0-.14.18,2.11,2.11,0,0,1-1.24,1A2.33,2.33,0,0,1,1474.67,481.51Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M512.32,372.41a1.7,1.7,0,0,1-1-.32c-.53-.35-2.14-1.43-1.59-3.28a1.46,1.46,0,0,1,1.4-1.17,1.8,1.8,0,0,1,1.39.78l.2,0a1.83,1.83,0,0,1,1.79,1.9v.59l-.52.28s-.15.18-.23.27A1.81,1.81,0,0,1,512.32,372.41Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M569.66,354.42l2.72-3.11.75.86a15.73,15.73,0,0,1,1.59,2.13,1.63,1.63,0,0,1-.37,1.82l-.2.28-.56,1Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1112.42,482.91a8.72,8.72,0,0,1-.43-1.67c-.22-2.17,1.13-2.6,1.72-2.69l.38,0c1.15,0,1.83.8,2,2.36l.07.52-3.26,2.9Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2022.34,521a2.45,2.45,0,0,1-2.73-2.7,3.92,3.92,0,0,1,.76-3,2.51,2.51,0,0,1,1.89-.71,5.27,5.27,0,0,1,.65,0c.14,0,.3,0,.45,0,.78,0,2.6.09,2.56,2.49,0,2.11-1.11,3.45-2.15,3.69a9.4,9.4,0,0,1-1.43.13Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2037,427.81a2.49,2.49,0,0,1-2.38-2,2.52,2.52,0,0,0-.42-.84l-.35-.33,0-.48a3.07,3.07,0,0,1,2.77-2.78,4.64,4.64,0,0,1,.67,0,3.26,3.26,0,0,1,3.49,3.13c.12,1.71-1.15,3-3.32,3.32a3.13,3.13,0,0,1-.5,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2008.39,505.54l-.42,0h-.65a2.76,2.76,0,0,1-2.17-.74,2.67,2.67,0,0,1-.53-2.17c0-.21,0-.42,0-.64a2.87,2.87,0,0,1,.57-2.25,2.36,2.36,0,0,1,1.76-.64h.29a3.65,3.65,0,0,1,3.64,3.67,2.6,2.6,0,0,1-.59,2.06A2.46,2.46,0,0,1,2008.39,505.54Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1736.43,474.43a2.3,2.3,0,0,1-1.71-.63,3.38,3.38,0,0,1-.67-2.56v-.3a2.87,2.87,0,0,1,.59-2.28,2.28,2.28,0,0,1,1.76-.69l.41,0h.08a3.64,3.64,0,0,1,3.43,3.78,2.53,2.53,0,0,1-.58,2,2.4,2.4,0,0,1-1.8.64h-1Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1856.66,505.55a2.28,2.28,0,0,1-1.72-.67,3.58,3.58,0,0,1-.64-2.76V502a3.06,3.06,0,0,1,.64-2.31,2.37,2.37,0,0,1,1.74-.62h.35a3.47,3.47,0,0,1,3.41,3c.17,1.83-1.07,3.17-3.17,3.47A4.55,4.55,0,0,1,1856.66,505.55Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1871.78,505.58a2.39,2.39,0,0,1-1.85-.71,3.09,3.09,0,0,1-.6-2.38v-.31a3.18,3.18,0,0,1,.63-2.44,2.26,2.26,0,0,1,1.68-.62l.52,0c2,.19,3.34,1.45,3.33,3.22s-1.32,3.06-3.4,3.2Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2067.47,474.47a2.84,2.84,0,0,1-2.27-.81,2.71,2.71,0,0,1-.48-2.29c.3-2,1.5-3.22,3.05-3.22a2.11,2.11,0,0,1,.35,0A3.4,3.4,0,0,1,2071,472a2.23,2.23,0,0,1-.45,1.7,2.79,2.79,0,0,1-2.13.76l-.42,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2067.17,536.64c-1.59,0-2.46-.93-2.46-2.62a3.57,3.57,0,0,1,2.78-3.69,2.55,2.55,0,0,1,.54-.06c1.37,0,2.08,1.15,2.65,2.08.1.16.2.33.31.49l.33.51-.45.8a3.89,3.89,0,0,1-3,2.45A4.93,4.93,0,0,1,2067.17,536.64Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1751.47,474.44a2.34,2.34,0,0,1-1.81-.71,2.77,2.77,0,0,1-.59-2.19,3.83,3.83,0,0,1,2.82-3.5l.27-.08.26.07c1.76.48,2.82,1.64,2.83,3.1a3.32,3.32,0,0,1-3.25,3.27,5.13,5.13,0,0,1-.52,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1722,474.35c-1.49,0-2.62-1.16-3-3a3,3,0,0,1,.41-2.56,2.26,2.26,0,0,1,1.84-.76h.44a3.47,3.47,0,0,1,3.4,2.78,3.11,3.11,0,0,1-2.54,3.52,3.46,3.46,0,0,1-.59.06Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2112.91,505.39c-2.48-.15-3-2.57-3.23-4.91l2-.21a.93.93,0,0,0-.91-.75.8.8,0,0,0-.38.09,3.61,3.61,0,0,1,1.72-.57h.17c2.25,0,3.71,1.31,3.71,3.35a3,3,0,0,1-3,3Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2097.19,505.54a2.26,2.26,0,0,1-1.77-.69,3.58,3.58,0,0,1-.57-2.77,3.22,3.22,0,0,1,3.2-2.87,3.08,3.08,0,0,1,2.92,3.2,3.41,3.41,0,0,1-3.4,3.12h-.38Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1737.17,505.4c-1.78,0-3-1.38-3.13-3.51a2.68,2.68,0,0,1,.59-2.1,2.37,2.37,0,0,1,1.8-.68h0a3.13,3.13,0,0,1,.66-.08c1.7,0,2.13,1.54,2.37,2.36a3.72,3.72,0,0,0,.19.59,2.3,2.3,0,0,1-.09,2.24,2.91,2.91,0,0,1-2.39,1.18Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2066.17,551.72a1.46,1.46,0,0,1-1.09-.46,2.17,2.17,0,0,1-.34-1.78c0-.13,0-.25,0-.34a3.2,3.2,0,0,1,.48-2.69,2.18,2.18,0,0,1,1.75-.74,3.81,3.81,0,0,1,.64.06,3.38,3.38,0,0,1,2.38,1.08,2.37,2.37,0,0,1,.56,1.84c-.22,1.82-1.86,2.32-2.84,2.62a6.25,6.25,0,0,0-.61.2,2.74,2.74,0,0,1-1,.21Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1841.52,505.61a2.21,2.21,0,0,1-2.22-2.11,3.27,3.27,0,0,1,.56-2.63,2.28,2.28,0,0,1,1.77-.69h.34a4.38,4.38,0,0,0,.5,0,5.94,5.94,0,0,1,.72,0,2.07,2.07,0,0,1,2.18,1.7c.33,1.48-.6,2.84-2.36,3.49A4.31,4.31,0,0,1,1841.52,505.61Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1992.73,521c-1.58,0-2.31-1.22-3-2.29l-.67-1.1,3.58-3.38.57,1.3h0a4.05,4.05,0,0,1,.46.11,2.12,2.12,0,0,1,1.48.91,1.68,1.68,0,0,1,.1,1.31,4.17,4.17,0,0,0-.09.46c-.12.77-.4,2.58-2.32,2.69Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1737.71,459a4.06,4.06,0,0,1-1.41-.35,3.14,3.14,0,0,0-.83-.25l-.28-2c.19,0,.29-.18.5-1s.6-2.35,2.28-2.35l.4,0a1.74,1.74,0,0,1,1.31.76,2.43,2.43,0,0,1,0,2,3.29,3.29,0,0,0-.14.6c-.06,2.21-1.19,2.53-1.86,2.53Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2052.8,489.91a3.28,3.28,0,0,1-3-2.88,2.69,2.69,0,0,1,1.36-2.66l.36-.3.41-.37.53.15a3.31,3.31,0,0,1,2.4,3.13c0,.12,0,.24,0,.36a2.54,2.54,0,0,1-.48,2,1.88,1.88,0,0,1-1.44.55Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1797.46,505.5a2.39,2.39,0,0,1-.84-.16,3.53,3.53,0,0,1-2.43-3.59l0-.42.31-.27c.13-.12.29-.28.46-.46.65-.67,1.16-1.2,1.81-1.2h.25l.19.09a3.44,3.44,0,0,1,2.15,3.51c-.15,1.66-.8,2.5-1.93,2.5Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1917.38,504.33a3,3,0,0,1-2.46-1.8,4.38,4.38,0,0,0-.28-.42l-.17-.25v-.29a2.16,2.16,0,0,1,1.91-2.47,4.08,4.08,0,0,1,.59,0,3.71,3.71,0,0,1,3.42,2.27l.19.45-.24.43a4.59,4.59,0,0,1-2.27,2l-.09,0-.1,0a2.81,2.81,0,0,1-.49.05Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1977.24,488.87c-.9,0-2.66-.11-2.66-2.66v-.48l3.06-2.48.54,1.15h0s.28.06.42.09a1.89,1.89,0,0,1,1.76,2.13v.09l0,.09a3,3,0,0,1-2.85,2.08Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2007.88,427.7a2.81,2.81,0,0,1-.49-.05,3.65,3.65,0,0,1-2.78-3.26v-.14l0-.14a2.25,2.25,0,0,1,2.31-1.66l.5,0a2.24,2.24,0,0,1,1.71.66,3.1,3.1,0,0,1,.64,2.35C2009.66,427.54,2008.3,427.7,2007.88,427.7Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2068.54,458.5a2.37,2.37,0,0,1-1.12-.34l-.09-.05-.07-.06a2.4,2.4,0,0,0-.69-.28,2.4,2.4,0,0,1-1.64-1.13,2,2,0,0,1,0-1.64l0-.08.05-.08a4,4,0,0,1,3.24-1.91h.12a1.69,1.69,0,0,1,1.21.62,2.72,2.72,0,0,1,.34,2.13,2.55,2.55,0,0,0,0,.34c0,.07,0,.17,0,.27a2.22,2.22,0,0,1-.37,1.78,1.34,1.34,0,0,1-1,.43Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1811.88,504.39a2.69,2.69,0,0,1-2.21-.73,1.85,1.85,0,0,1-.34-1.62,3.78,3.78,0,0,1,1.23-1.93,5.8,5.8,0,0,0,.48-.52l.64-.8.79.67a8.17,8.17,0,0,1,1.56,1.76l0,.08,0,.08a2.26,2.26,0,0,1,0,2.22,1.68,1.68,0,0,1-1.39.77l-.79,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1977.57,505.45c-1.72,0-1.88-1.67-2.07-3.6l.87-.09-.51-.52a2.21,2.21,0,0,1,1.85-1,2,2,0,0,1,2,2.16c0,.1,0,.21,0,.32,0,.68.1,2.48-1.91,2.67Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1917.11,489.93a5.48,5.48,0,0,1-1.88-.37l-1.09-.38.53-1c.85-1.62,1.95-2.44,3.29-2.44a4.16,4.16,0,0,1,2,.6l.82.46-.41.85a3.56,3.56,0,0,1-3.29,2.3Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M2037.74,441.66a7.63,7.63,0,0,1-1.63-1.63l0,0,0-.05a2,2,0,0,1-.14-1.83,2.11,2.11,0,0,1,1.65-1.16l.31,0a2.12,2.12,0,0,1,.44,0,2,2,0,0,1,1.52,2.39,11.75,11.75,0,0,1-.56,1.87l-.48,1.19Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M998.7,297.92c-2.65-.06-4-3-4.3-3.9l-.09-.24,0-.26c.4-2.59,1.79-3.85,4.26-3.85h.25a4.58,4.58,0,0,1,3.35,1.2,3.42,3.42,0,0,1,.84,2.55,4.55,4.55,0,0,1-4.27,4.5h-.08Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1029.25,207.44c-1.93,0-3.37-2-3.64-3.88a2.73,2.73,0,0,1,.43-2.16,3.28,3.28,0,0,1,2.46-1c.71-.06,1.06-.11,1.11-.28l.25-.87.9.17c2.31.44,3.46,1.73,3.44,3.83s-1.52,3.6-4.5,4.11Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M911.56,127.28c-2.32,0-2.54-2.24-2.65-3.45l0-.24c-.17-2.3,1.34-3.34,2.68-4.26l.64-.44.59.51a7.72,7.72,0,0,1,2.55,4.59,2.55,2.55,0,0,1-.43,2,3.85,3.85,0,0,1-2.72,1.22l-.17,0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1030,267.91c-2.38,0-3-2-3.56-3.51-.11-.31-.22-.63-.34-.94l-.13-.34.11-.33a3,3,0,0,1,2.85-2.09l.44,0a3.47,3.47,0,0,1,3.78,3.6,3.19,3.19,0,0,1-.6,2.33,3.16,3.16,0,0,1-2.11,1.26,1.84,1.84,0,0,1-.41,0h0Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1336.75,263.85a3.22,3.22,0,0,1,.9-2.53,3.76,3.76,0,0,1,2.89-1,3.68,3.68,0,0,1,2.61,1.14,2.94,2.94,0,0,1,.73,2.18l-2-.13a.91.91,0,0,0-.22-.71,2.17,2.17,0,0,0-2.6-.1,1.28,1.28,0,0,0-.31,1Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1494.25,394.12a3.81,3.81,0,0,1-3.78-3.44,2.63,2.63,0,0,1,.35-2,3.69,3.69,0,0,1,2.52-1.29l.27-.06a3.78,3.78,0,0,1,.62-.06c1.73,0,2.57,1.46,3.18,2.53l.22.37.57.95-1,.47c-.08,0-.19.37-.26.57a2.37,2.37,0,0,1-2.36,2Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1030.34,221.87a3.67,3.67,0,0,1-3.67-3.37v0a2.79,2.79,0,0,1,.47-2.17,4.32,4.32,0,0,1,3.06-1.37l.13,0,.23,0a2.25,2.25,0,0,1,1.71.9,3.79,3.79,0,0,1,.82,2.89v.25a3,3,0,0,1-.61,2.3,2.47,2.47,0,0,1-1.82.64h-.32Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1203.29,225.69a3,3,0,0,1-.86-.13l-.35-.1c-.9-.23-3-.78-2.61-3.37a3.79,3.79,0,0,1,3.16-3.44h.22a3.17,3.17,0,0,1,2.37.92,3,3,0,0,1,.53,2.41,4.81,4.81,0,0,0,0,.52C1205.72,224.45,1204.77,225.69,1203.29,225.69Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1339.28,251.25a2.58,2.58,0,0,1-1.92-.71,4,4,0,0,1-.76-3c0-2.54,1.7-2.81,2.43-2.81a3.91,3.91,0,0,1,.57,0,3.56,3.56,0,0,1,3.22,2.62l0,.11v.12c.13,2.93-1.18,3.43-2.62,3.53l-.33,0A4.84,4.84,0,0,1,1339.28,251.25Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M911.5,96.69a3.64,3.64,0,0,1-3.65-3.33,2.57,2.57,0,0,1,.44-2.11,3.29,3.29,0,0,1,2.57-.87l.59,0a2.86,2.86,0,0,1,2.28.86,2.72,2.72,0,0,1,.52,2.25v.36c0,.93,0,2.85-2.4,2.85h-.35Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1045.19,206.87a3,3,0,0,1-2.45-.88,3.1,3.1,0,0,1-.58-2.56c0-.34.07-.63.09-.9,0-.74.15-2.11,1.42-2.11h.18c2.18.2,4.5.79,4.78,3.6a2.44,2.44,0,0,1-.48,1.94,3.25,3.25,0,0,1-2.45.89Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M925.16,126.68a2.9,2.9,0,0,1-2.19-1.24,2.14,2.14,0,0,1-.49-1.86c.07-.28.13-.61.19-.94.23-1.28.52-2.87,2-3.13.55-.05,1-.07,1.31-.07,1.79,0,2.71.77,2.72,2.28a5.32,5.32,0,0,1-2.54,4.69A1.89,1.89,0,0,1,925.16,126.68Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1201.88,240.65c-2.28,0-2.35-1.83-2.38-2.61,0-.16,0-.32,0-.48a3,3,0,0,1,.58-2.5,3.3,3.3,0,0,1,2.6-.86h.38a2.61,2.61,0,0,1,2.06.82c.58.68.72,1.67.47,3.31-.22,1.13-1.56,2.29-3.63,2.32Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1400.7,266.81a4.66,4.66,0,0,1-1-.13,3.77,3.77,0,0,1-2.46-1.56,2.82,2.82,0,0,1-.3-2.2,3.17,3.17,0,0,1,2.82-2.54,2.71,2.71,0,0,1,.74.11,3.47,3.47,0,0,1,2.53,3.75C1403,265.85,1402.12,266.81,1400.7,266.81Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1029.28,281.28l-1.7-1.06a3.21,3.21,0,0,1,1.73-1.17c.81-.3.81-.3.71-.76a3.53,3.53,0,0,1-.07-.83c0-.59,0-.77-1.27-.91l.22-2c2.67.29,3,1.62,3,2.88a2.12,2.12,0,0,0,0,.39c.5,2.17-1.17,2.79-2,3.09A2.18,2.18,0,0,0,1029.28,281.28Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1508.34,381.46c-2.27,0-3.23-2.15-4.07-4l-.63-1.41h1.54a7.62,7.62,0,0,0,1.49-.2,7.53,7.53,0,0,1,1.49-.21,2.71,2.71,0,0,1,2.6,1.82,2.88,2.88,0,0,1-1.38,3.82,2.75,2.75,0,0,1-.45.17l-.07,0h-.08Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1523.22,321.82a2.76,2.76,0,0,1-.57-.06,3.55,3.55,0,0,1-2.17-1.52,2.57,2.57,0,0,1-.31-2,3.44,3.44,0,0,1,3.19-2.61h.23l.21.09c.82.36,2.73,1.21,2.54,3.15a3.06,3.06,0,0,1-3.07,2.94Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1202.78,256.09a1.94,1.94,0,0,1-.33,0,3.1,3.1,0,0,1-2.35-1.36,2.12,2.12,0,0,1,.06-2.08,3,3,0,0,0,.14-.57c.14-.68.45-2.27,2.13-2.27a2.2,2.2,0,0,1,.49.05,3.46,3.46,0,0,1,2.83,3.17v.25l-.1.23C1205.22,254.46,1204.49,256.09,1202.78,256.09Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M798.64,229a3.11,3.11,0,0,1-2.51-1.39,2,2,0,0,1,.14-2,2,2,0,0,0,.13-.48,2.36,2.36,0,0,1,2.39-2.24h.29l.18,0,.17.08c.92.44,2.63,1.26,2.47,3.17a3.08,3.08,0,0,1-3.06,2.84Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1400.06,282.21a1.77,1.77,0,0,1-.32,0,3.11,3.11,0,0,1-2.36-1.36,2.12,2.12,0,0,1,.06-2.08,3.84,3.84,0,0,0,.15-.59c.15-.72.45-2.23,2-2.23a2.39,2.39,0,0,1,.64.08,4.7,4.7,0,0,1,2.07,1.51,6.81,6.81,0,0,0,.52.54l.55.48-.29.66C1402.53,280.54,1401.82,282.21,1400.06,282.21Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M999.65,309.78A3.48,3.48,0,0,1,997,308a5.37,5.37,0,0,0-1.16-1.21l-2-1.33,2.37-.48a14.89,14.89,0,0,1,2.77-.35,4.16,4.16,0,0,1,3.79,2,1.18,1.18,0,0,1,.12.9,3.44,3.44,0,0,1-1.41,1.69A3.67,3.67,0,0,1,999.65,309.78Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1004.39,437.41a1.44,1.44,0,0,1-1-.47c-.15-.16-.55-.16-1-.17-.79,0-2.45,0-2.75-2a2,2,0,0,1,1.71-2.37c.45-.15.66-.23.77-.42l.47-.85.87.46a3.6,3.6,0,0,1,2.34,3.49C1005.81,437,1005,437.41,1004.39,437.41Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1524.18,335.92c-.23,0-.48,0-.73-.05a4,4,0,0,0-.49,0l-.61,0a2.07,2.07,0,0,1-2.23-2.53l0-.24.32-.47a3.27,3.27,0,0,1,2.65-1.89,2.8,2.8,0,0,1,.48,0,3.07,3.07,0,0,1,2.74,3.17v.1A2,2,0,0,1,1524.18,335.92Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1187.83,240.65a2.27,2.27,0,0,1-1.92-.84,2.75,2.75,0,0,1-.29-2.44s.88-2.73,2.31-2.73h.15c1.66.16,2.67,1.52,2.65,3.62a2.13,2.13,0,0,1-2.33,2.38h-.57Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M752.3,92.69a2.64,2.64,0,0,1-.55-.06,3.75,3.75,0,0,1-1.91-1.32,5.52,5.52,0,0,0-.49-.48l-.83-.74.83-.75c.14-.13.27-.27.41-.42a3.39,3.39,0,0,1,2.53-1.37l.36,0h.24a2.36,2.36,0,0,1,2.05.83,1.91,1.91,0,0,1,.21,1.8A3.19,3.19,0,0,1,752.3,92.69Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1508.27,351a3.73,3.73,0,0,1-3.36-2.15,1.14,1.14,0,0,1,0-1,4.45,4.45,0,0,1,2.35-1.95,2.33,2.33,0,0,1,.59-.08,3.07,3.07,0,0,1,2.43,1.67l.28.39.1.12.06.15a2.1,2.1,0,0,1-1.22,2.68,2.47,2.47,0,0,1-.6.13A3.79,3.79,0,0,1,1508.27,351Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1340.47,297.49a2.62,2.62,0,0,1-1-.23,16.75,16.75,0,0,1-2.15-1.35l-1-.71,4.2-4,2.81,3.24-.47.64a4.72,4.72,0,0,0-.4.71C1342.17,296.48,1341.67,297.49,1340.47,297.49Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M782.85,153.57a3.11,3.11,0,0,1-.83-.13,2.47,2.47,0,0,1-1.69-1.41,1.72,1.72,0,0,1,.34-1.62,25.07,25.07,0,0,1,2.09-2.34l1.76-1.84L785,148a1.92,1.92,0,0,0,.6.91,1.83,1.83,0,0,1,.67,1.33,1.54,1.54,0,0,1-.55,1.12,5.22,5.22,0,0,0-.47.62A2.88,2.88,0,0,1,782.85,153.57Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1507.85,305.8a3.23,3.23,0,0,1-3.1-2.35,2.44,2.44,0,0,1,.64-2.19v-.65h1c2.95,0,3.4,1.57,3.65,2.41a2.19,2.19,0,0,0,.13.39,1.41,1.41,0,0,1-1.25,2.36h-.53A3.44,3.44,0,0,1,1507.85,305.8Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1356.79,264.19l-2-.16a1.28,1.28,0,0,0,0-.58s-.24,0-.58,0l-.16,0a2.2,2.2,0,0,0-.5.06.49.49,0,0,0,.23-.35l-1.94.49a1.68,1.68,0,0,1,.24-1.45,2.32,2.32,0,0,1,1.84-.75l.2,0a2.71,2.71,0,0,1,2.15.68A2.6,2.6,0,0,1,1356.79,264.19Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M912.05,50.52c-.19,0-.38,0-.56,0l-.31,0c-2.22,0-2.23-1.89-2.24-2.61v-.22c0-.83.28-2.25,2.49-2.33l.52,0,.31.41a9,9,0,0,1,1.49,2.89l0,.09v.09a1.53,1.53,0,0,1-.35,1.23A1.82,1.82,0,0,1,912.05,50.52Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M814.35,213a2.08,2.08,0,0,1-.92-.22,3.12,3.12,0,0,1-1.17-2.42l0-.13a2,2,0,0,1,.4-1.57,2.27,2.27,0,0,1,1.59-.82l.22,0,.2.07a2.46,2.46,0,0,1,1.71,3V211a2,2,0,0,1-2,2Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M783.41,92.76a2.69,2.69,0,0,1-1.11-.27l-.06,0-.06,0a7.77,7.77,0,0,1-1.83-1.6l-.81-1,3.9-2h.24a1.58,1.58,0,0,1,1.22.49,2,2,0,0,1,.38,1.62,1.85,1.85,0,0,0,0,.23,2.55,2.55,0,0,1-.57,2A1.71,1.71,0,0,1,783.41,92.76Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1339,309.25Zm.52-.33-1.72-.48a1.48,1.48,0,0,1,1.22-1.16,1.8,1.8,0,0,1,1.68.91Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M762,499.56a3.82,3.82,0,0,0-.42-.37,3,3,0,0,1-1.19-1.66c-.15-.84.36-1.4,1-2,.28-.31.64-.69,1-1.2l1-1.33.69,1.53a22,22,0,0,1,.86,2.21,2.12,2.12,0,0,1-1,2.44,1.35,1.35,0,0,0-.32.26l-.7,1Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M912.28,109.56a2,2,0,0,1-2.17-1.77,1.92,1.92,0,0,1,.34-1.51,2.76,2.76,0,0,1,2-.88l.52-.06.86,1c.33.38.68.78,1,1.18l1,1.13-1.45.5a10.82,10.82,0,0,1-1.63.42Z"
						style={{ opacity: 0 }}
					/>
					<path
						d="M1048.52,174.82a3.89,3.89,0,0,1-3.42-3,5,5,0,0,0-.46-1.05l-1.26-1.9,2.25.36a3.71,3.71,0,0,1,3.46,2.94,4.34,4.34,0,0,0,.47,1.1l1.3,2Z"
						style={{ opacity: 0 }}
					/>
				</a.g>
			</svg>
		</div>
	);
}
