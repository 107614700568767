import styles from './Title.module.scss';

export default function Title({ id, children }) {
	return id ? (
		<h3 id={id} className={styles['title']}>
			{children}
		</h3>
	) : (
		<h3 className={styles['title']}>{children}</h3>
	);
}