import { useState, useEffect, useCallback } from 'react';
import useIsLandscape from './useIsLandscape';

function useWindowSize() {
	const isClient = typeof window === 'object';

	const getSize = useCallback(() => {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		};
	}, [isClient]);

	const [windowSize, setWindowSize] = useState(getSize);

	const updateSize = useCallback(() => {
		setWindowSize(getSize());
	}, [getSize]);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		window.addEventListener('resize', updateSize);

		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, [isClient, updateSize]);

	useIsLandscape(updateSize);

	return { ...windowSize, updateSize };
}

export default useWindowSize;
