import useEventListener from '../common/utilities/useEventListener';

const getMobileOS = () => {
	const ua = navigator.userAgent;
	if (/android/i.test(ua)) {
		return 'Android';
	} else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
		return 'iOS';
	}
	return 'Other';
};

export default function useScrollFixiOS() {
	const os = getMobileOS();

	// Appears to help with scrolling on iOS.
	// I took the idea from body-scroll-lock.
	const preventDefault = (e) => {
		if (os === 'iOS') {
			e.preventDefault();
		}
	};
	useEventListener('touchmove', preventDefault, document);
}
