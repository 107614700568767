import React from 'react';
import styles from '../HomeInfobox.module.scss';

export default function Home5k() {
	return (
		<>
			<div className={styles['home-bullet']}>Perfect for friends and family groups</div>
			<div className={styles['home-bullet']}>Finish Festival party at L.A. Live in Downtown L.A.</div>
			<div className={styles['home-bullet']}>Exceptional participant goodie bag inclusions</div>
		</>
	);
}
