import { useEffect, useRef } from 'react';

export default function useRefState(val) {
	const ref = useRef(val);

	useEffect(() => {
		ref.current = val;
	}, [val]);

	return ref;
}
