import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import useStyleProps from '../../utilities/useStyleProps';
import useInteraction from '../../utilities/useInteraction';
import { noop } from '../../utilities/utilities';
import styles from './ButtonBase.module.scss';

export default function ButtonBase({
	isDisabled = false,
	isActive = false,
	children,
	className,
	hoverClassName,
	activeClassName,
	onPress = noop,
	onPressStart = noop,
	onPressEnd = noop,
	onLongPress = noop,
	onHover = noop,
	...otherProps
}) {
	const styleProps = useStyleProps(otherProps);
	const ref = useRef();

	const { bind, isHovered, isPressed } = useInteraction({
		onPress: onPress,
		onPressStart: onPressStart,
		onPressEnd: onPressEnd,
		onLongPress: onLongPress,
		isDisabled: isDisabled,
	});

	useEffect(() => {
		onHover();
	}, [isHovered, onHover]);

	const buttonClassNames = classNames(styles.ButtonBase, className, {
		[hoverClassName]: hoverClassName && (isHovered || isPressed),
		[activeClassName]: activeClassName && isActive,
	});

	return (
		<div
			{...bind()}
			className={buttonClassNames}
			style={{
				...styleProps,
				cursor: isDisabled ? 'default' : 'pointer',
			}}
			tabIndex="0"
			ref={ref}
		>
			{children}
		</div>
	);
}
