import Button from '../Button/Button';
import styles from './Jdrf.module.scss';
import Paragraph from '../Text/Paragraph';
import { JDRF_PATH } from '../../data/pathConstants';

import jdrfBg from '../../media/page-bgs/jdrf_bg.jpg';

export const JdrfData = {
	id: 'jdrf',
	url: JDRF_PATH,
	pageBg: jdrfBg,
	pageTitle: 'JDRF',
	pageSummary:
		'Herbalife Nutrition and JDRF are partnering to change the future of millions of people living with type 1 diabetes by working together toward a world without T1D.',
	pageIcon: '/assets/jdrf/jdrf-logo.png',
	pageIntro: "<img class='jdrf-intro-logo' src='/assets/jdrf/jdrf-logo.png' />",
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, JDRF, Juvenile Diabetes Research Foundation',
	metaDescription:
		'Who are the fittest companies in Los Angeles? The Corporate Challenge is an opportunity for leading Los Angeles companies to engage in “friendly” competition...',
};

export default function Jdrf() {
	return (
		<>
			<Button
				label="Donate / More Info"
				href={'https://www2.jdrf.org/site/TR?fr_id=8059&pg=entry'}
				gatrack={true}
				addWidth={100}
			/>
			<Paragraph>
				Herbalife Nutrition and JDRF are partnering to change the future of millions of people living with type 1 diabetes by working together toward a world without T1D.
			</Paragraph>
			<Paragraph>
				JDRF is leading the fight against type 1 diabetes (T1D), an autoimmune disease that affects over 1.25 million people of all ages.  The cause is unknown and there currently is no cure.  It requires constant management, a lifelong dependence on injected insulin and can create life-threatening complications.
			</Paragraph>
			<Paragraph>
				<img src="/assets/jdrf/jdrf-1.jpg" alt="" />
			</Paragraph>
			<Paragraph>
				JDRF is laser focused on finding a cure for T1D by funding research, advocating for policies that accelerate access to new therapies, and providing a support network for those around the world impacted by T1D. You can be a T1D Champion and earn some great swag and benefits on race weekend. See how you can support JDRF and make a difference in those affected by type 1 diabetes.
			</Paragraph>
			<Paragraph>
				<img src="/assets/jdrf/jdrf-2.jpg" alt="" />
			</Paragraph>
			<Paragraph>
				<b>What is Type 1 Diabetes:</b>
				<br />
				<iframe
					title="what is diabetes"
					className={styles['video']}
					src="https://player.vimeo.com/video/351396118\"
					frameBorder="0\"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph>
				<b>JDRF's Commitment:</b>
				<br />
				<iframe
					title="commitment"
					className={styles['video']}
					src="https://player.vimeo.com/video/335253253\"
					frameBorder="0\"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allowFullScreen
				></iframe>
			</Paragraph>

			<Paragraph style={{ fontStyle: 'italic', fontSize: '14px' }}>
				JDRF is an accredited 501(c)(3) nonprofit agency. Donations may be tax deductible under U.S and state law. If you have any questions, please contact the JDRF Southern California Chapter at 1391 Warner Avenue, Suite D, Tustin, California 92780 or <a href="mailto:southernca@jdrf.org" target="_blank" rel="noreferrer">southernca@jdrf.org</a>.
			</Paragraph>
			<Button
				theme="dark"
				label="Donate / More Info"
				href={'https://www2.jdrf.org/site/TR?fr_id=8059&pg=entry'}
				gatrack={true}
				addWidth={100}
			/>
		</>
	);
}