const sizeMap = {
	XS: 14,
	S: 18,
	M: 22,
	L: 40,
	XL: 64,
};

export default function useIconSize(size) {
	const width = sizeMap[size] ?? sizeMap.M;

	return [width, width];
}
