import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function PhotoGridPreviewRef({ onClose, ...props }, ref) {
	// Default values
	const { portalClassName = 'PhotoGridPreviewPortal' } = props;

	// const Document = useRecoilValue(DocumentState);
	const rootElemRef = useRef(null);

	// Lazy-initializing the ref: https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
	function getRootElem() {
		if (!rootElemRef.current) {
			rootElemRef.current = document.createElement('div');
			rootElemRef.current.className = portalClassName;
		}
		return rootElemRef.current;
	}

	// Appends Portal root to the parentElem. Removes when it is done.
	useEffect(() => {
		const currentRootRef = rootElemRef.current;
		// const parentElem = document.querySelector(parentSelector);
		const parentElem = document.getElementById('modal-root');

		parentElem.appendChild(currentRootRef);

		return () => {
			currentRootRef.remove();
		};
	}, []);

	return ReactDOM.createPortal(
		<div className="preview" ref={ref}>
			<button className="action action--close" onClick={onClose}>
				<svg
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 21.9 21.9"
					enableBackground="new 0 0 21.9 21.9"
				>
					<path d="M14.1,11.3c-0.2-0.2-0.2-0.5,0-0.7l7.5-7.5c0.2-0.2,0.3-0.5,0.3-0.7s-0.1-0.5-0.3-0.7l-1.4-1.4C20,0.1,19.7,0,19.5,0  c-0.3,0-0.5,0.1-0.7,0.3l-7.5,7.5c-0.2,0.2-0.5,0.2-0.7,0L3.1,0.3C2.9,0.1,2.6,0,2.4,0S1.9,0.1,1.7,0.3L0.3,1.7C0.1,1.9,0,2.2,0,2.4  s0.1,0.5,0.3,0.7l7.5,7.5c0.2,0.2,0.2,0.5,0,0.7l-7.5,7.5C0.1,19,0,19.3,0,19.5s0.1,0.5,0.3,0.7l1.4,1.4c0.2,0.2,0.5,0.3,0.7,0.3  s0.5-0.1,0.7-0.3l7.5-7.5c0.2-0.2,0.5-0.2,0.7,0l7.5,7.5c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l1.4-1.4c0.2-0.2,0.3-0.5,0.3-0.7  s-0.1-0.5-0.3-0.7L14.1,11.3z" />
				</svg>
				<span className="text-hidden">Close</span>
			</button>
			<div className="description--preview description"></div>
		</div>,
		getRootElem()
	);
}

const PhotoGridPreview = React.forwardRef(PhotoGridPreviewRef);

export default PhotoGridPreview;
