import { Route, Switch } from 'react-router';
import {
	ABOUT_PATH,
	HOME_PATH,
	RULES_PATH,
	FAQ_PATH,
	CORPORATE_CHALLENGE_PATH,
	LA_TRI_CLUB_PATH,
	TRAINING_PLAN_PATH,
	POINT_TO_POINT_PATH,
	WEEKEND_SCHEDULE_PATH,
	EXPO_PATH,
	ROAD_CLOSURE_PATH,
	ELITES_PATH,
	JDRF_PATH,
	COURSE_DESCRIPTION_PATH,
	AMBASSADOR_PROGRAM_PATH,
	EVENTS_5K_RUN_PATH,
	EVENTS_BIKE_TOUR_PATH,
	EVENTS_TRIATHLON_AQUABIKE_PATH,
	EVENTS_5K_VIRTUAL_PATH,
	MAP_DETAILS_PATH,
	SPORTS_NUTRITION_PATH,
	CONTACT_PATH,
	PHOTO_GALLERY_PATH,
	BLOG_PATH,
	MAP_PATH,
	HOME_TRIATHLON_PATH,
	HOME_AQUABIKE_PATH,
	HOME_5K_PATH,
	HOME_BIKETOUR_PATH,
	USAT_PATH,
	PTO_PRO_AM_PATH,
	VIRTUAL_CHALLENGE_PATH,
} from '../../data/pathConstants';
import usePageStyles from '../usePageStyles';
import HomeBG from '../../components/Home/HomeBG';
import ContentPageBackground from '../../components/ContentPage/ContentPageBackground';
import BlogBackground from '../../components/Blog/BlogBackground';
import BlogPostBackground from '../../components/Blog/BlogPostBackground';
import ThreeMap from '../../components/ThreeMap/ThreeMap';

export default function BackgroundLayer() {
	const pageStyles = usePageStyles(1);

	return (
		<div style={pageStyles}>
			<Switch>
				<Route path={HOME_PATH} exact>
					<HomeBG />
				</Route>
				<Route path={HOME_TRIATHLON_PATH} exact>
					<HomeBG type={'sprint'} />
				</Route>
				<Route path={HOME_AQUABIKE_PATH} exact>
					<HomeBG type={'aquabike'} />
				</Route>
				<Route path={HOME_5K_PATH} exact>
					<HomeBG type={'5k'} />
				</Route>
				<Route path={HOME_BIKETOUR_PATH} exact>
					<HomeBG type={'biketour'} />
				</Route>
				<Route
					path={[
						ABOUT_PATH,
						RULES_PATH,
						FAQ_PATH,
						CORPORATE_CHALLENGE_PATH,
						LA_TRI_CLUB_PATH,
						TRAINING_PLAN_PATH,
						POINT_TO_POINT_PATH,
						WEEKEND_SCHEDULE_PATH,
						USAT_PATH,
						EXPO_PATH,
						ROAD_CLOSURE_PATH,
						ELITES_PATH,
						JDRF_PATH,
						COURSE_DESCRIPTION_PATH,
						AMBASSADOR_PROGRAM_PATH,
						EVENTS_5K_RUN_PATH,
						EVENTS_BIKE_TOUR_PATH,
						EVENTS_TRIATHLON_AQUABIKE_PATH,
						EVENTS_5K_VIRTUAL_PATH,
						MAP_DETAILS_PATH,
						SPORTS_NUTRITION_PATH,
						CONTACT_PATH,
						PHOTO_GALLERY_PATH,
						PTO_PRO_AM_PATH,
						VIRTUAL_CHALLENGE_PATH,
					]}
				>
					<ContentPageBackground />
				</Route>
				<Route path="/blog/:slug">
					<BlogPostBackground />
				</Route>
				<Route path={BLOG_PATH}>
					<BlogBackground />
				</Route>
				<Route path={MAP_PATH}>
					<ThreeMap />
				</Route>
			</Switch>
		</div>
	);
}
