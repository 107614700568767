import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

export default function Portal({
	className = null,
	parentSelector = 'body',
	children,
}) {
	const rootElemRef = useRef(null);

	// Lazy-initializing the ref: https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
	function getRootElem() {
		if (!rootElemRef.current) {
			rootElemRef.current = document.createElement('div');
			if (className) {
				rootElemRef.current.className = className;
			}
		}
		return rootElemRef.current;
	}

	// Appends Portal root to the parentElem. Removes when it is done.
	useEffect(() => {
		const currentRootRef = rootElemRef.current;
		const parentElem = document.querySelector(parentSelector);
		parentElem.appendChild(currentRootRef);

		return () => {
			currentRootRef.remove();
		};
	}, [parentSelector]);

	return ReactDOM.createPortal(children, getRootElem());
}
