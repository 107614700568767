import React from 'react';
import BlogListItem from './BlogListItem';
import styles from './BlogList.module.scss';

export default function BlogList({ posts }) {
	return (
		<div className={styles.BlogList}>
			{posts.map((post, index) => {
				if (index === 0) {
					return null;
				}
				
				return <BlogListItem key={index} post={post} />;
			})}
		</div>
	);
}
