import { useBounds } from './Bounds';
import { useLayoutEffect, useState } from 'react';
import * as THREE from 'three';
import useStore from '../../states/useStore';

export default function SelectToZoom({ children }) {
	const api = useBounds();
	const [startPos] = useState(() => new THREE.Vector3(-0.7414, 14.3, 6.6743));
	const isOpen = useStore((state) => state.poi.isOpen);

	useLayoutEffect(() => {
		if (!isOpen) {
			api.to({ position: startPos, target: [0, 0, 0] });
		}
	}, [isOpen, api, startPos]);

	return (
		<group
			onClick={(e) => {
				e.stopPropagation();
				if (e.delta <= 2) {
					if (e.object.parent.name === 'poi') {
						api.refresh(e.object.parent).fit();
					} else if (e.object.parent.parent.name === 'poi') {
						api.refresh(e.object.parent.parent).fit();
					}
				}
			}}
			onPointerMissed={(e) => {
				if (e.button === 0) {
					api.to({ position: startPos, target: [0, 0, 0] });
				}
			}}
		>
			{children}
		</group>
	);
}
