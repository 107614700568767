import { HOME_PATH } from '../../data/pathConstants';

export const HomeData = {
	id: 'home',
	url: HOME_PATH,
	eventDate: 'Sunday, October 24, 2021',
	eventDateMobile: 'Sunday, October 24, 2021',
	eventLocation:
		'Herbalife Nutrition is excited to announce the results of its inaugural Herbalife24 Triathlon Los Angeles race. Follow the link below.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, Herbalife tri, Los Angeles tri, LA tri, H24 tri, H24 triathlon, Herbalife24, LA Live, down town Los Angeles, triathlon, triathlon wetsuit, triathlon training, triathlon training plan, triathlon clothing, triathletes, triathlon bike, triathlon bikes, triathlon shorts, triathlon calendar, triathlon events, triathlon magazine, triathlons, mini triathlon, triathlon distance, types of triathlons, triathlon near me, triathlon types, triathlete, triathlon suit, duathlon, triathlon meaning, triathlon logo, tri athlete, triathlon websites, triathlon times, running, Race triathlon, 2021 triathlons',
	metaDescription:
		'Register for the Herbalife24 Triathlon Los Angeles on October 24, 2021. Starting in Venice Beach and ending in Downtown Los Angeles at L.A. Live, join us for a one-of-a-kind journey through the heart of L.A.Register for the Herbalife24 Triathlon Los Angeles on October 24, 2021. Starting in Venice Beach and ending in Downtown Los Angeles at L.A. Live, join us for a one-of-a-kind journey through the heart of L.A.',
};

export default function Home() {
	return;
}