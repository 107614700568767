export default function ProfileIcon() {
	return (
		<svg
			id="Profile_icon"
			data-name="Profile icon"
			xmlns="http://www.w3.org/2000/svg"
			width="22"
			height="22"
			viewBox="0 0 22 22"
		>
			<rect id="Rectangle_190" data-name="Rectangle 190" width="22" height="22" rx="2" fill="#b1b1b1" />
			<g id="Group_543" data-name="Group 543" transform="translate(6 4.25)">
				<circle
					id="Ellipse_6"
					data-name="Ellipse 6"
					cx="2.976"
					cy="2.976"
					r="2.976"
					transform="translate(2.418)"
					fill="#fff"
				/>
				<path
					id="Path_552"
					data-name="Path 552"
					d="M26.019,150a5.394,5.394,0,0,0-5.394,5.394H31.412A5.394,5.394,0,0,0,26.019,150Z"
					transform="translate(-20.625 -142.56)"
					fill="#fff"
				/>
			</g>
		</svg>
	);
}
