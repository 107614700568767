import React from 'react';
import { PHOTO_GALLERY_PATH } from '../../data/pathConstants';
import PhotoGrid from '../PhotoGrid/PhotoGrid';

import contactBg from '../../media/page-bgs/contact_bg.jpg';
import { PHOTOS_2022_URL } from '../../data/pageData';

export const PhotoGalleryData = {
	id: 'photoGallery',
	url: PHOTO_GALLERY_PATH,
	pageBg: contactBg,
	pageTitle: 'Photo Gallery',
	pageSummary: 'Special moments from 2019 and 2021.',
	pageIcon: '/assets/icons/highlights.svg',
	metaKeywords:
		'Herbalife 24, race highlights, register, fast course, DTLA, down town Los Angeles, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, triathlon relay, map, swim, bike, run',
	metaDescription: 'Special moments from 2019 and 2021.',
};

const gallery = [
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0313.jpg',
		source: '/assets/photo-gallery/fullsize/355A0313.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0362.jpg',
		source: '/assets/photo-gallery/fullsize/355A0362.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0374.jpg',
		source: '/assets/photo-gallery/fullsize/355A0374.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0463.jpg',
		source: '/assets/photo-gallery/fullsize/355A0463.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0495.jpg',
		source: '/assets/photo-gallery/fullsize/355A0495.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A0531.jpg',
		source: '/assets/photo-gallery/fullsize/355A0531.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1018.jpg',
		source: '/assets/photo-gallery/fullsize/355A1018.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1168.jpg',
		source: '/assets/photo-gallery/fullsize/355A1168.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1231.jpg',
		source: '/assets/photo-gallery/fullsize/355A1231.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1252.jpg',
		source: '/assets/photo-gallery/fullsize/355A1252.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1303.jpg',
		source: '/assets/photo-gallery/fullsize/355A1303.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1391.jpg',
		source: '/assets/photo-gallery/fullsize/355A1391.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/355A1405.jpg',
		source: '/assets/photo-gallery/fullsize/355A1405.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/7F6A1703.jpg',
		source: '/assets/photo-gallery/fullsize/7F6A1703.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/7F6A1794.jpg',
		source: '/assets/photo-gallery/fullsize/7F6A1794.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/7F6A1833.jpg',
		source: '/assets/photo-gallery/fullsize/7F6A1833.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/7F6A2216.jpg',
		source: '/assets/photo-gallery/fullsize/7F6A2216.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH106839.jpg',
		source: '/assets/photo-gallery/fullsize/DH106839.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH106924.jpg',
		source: '/assets/photo-gallery/fullsize/DH106924.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH106963.jpg',
		source: '/assets/photo-gallery/fullsize/DH106963.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107005.jpg',
		source: '/assets/photo-gallery/fullsize/DH107005.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107077.jpg',
		source: '/assets/photo-gallery/fullsize/DH107077.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107567.jpg',
		source: '/assets/photo-gallery/fullsize/DH107567.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107810.jpg',
		source: '/assets/photo-gallery/fullsize/DH107810.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107944.jpg',
		source: '/assets/photo-gallery/fullsize/DH107944.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH107960.jpg',
		source: '/assets/photo-gallery/fullsize/DH107960.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108319.jpg',
		source: '/assets/photo-gallery/fullsize/DH108319.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108324.jpg',
		source: '/assets/photo-gallery/fullsize/DH108324.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108363.jpg',
		source: '/assets/photo-gallery/fullsize/DH108363.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108387.jpg',
		source: '/assets/photo-gallery/fullsize/DH108387.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108444.jpg',
		source: '/assets/photo-gallery/fullsize/DH108444.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108468.jpg',
		source: '/assets/photo-gallery/fullsize/DH108468.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108484.jpg',
		source: '/assets/photo-gallery/fullsize/DH108484.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108564.jpg',
		source: '/assets/photo-gallery/fullsize/DH108564.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108675.jpg',
		source: '/assets/photo-gallery/fullsize/DH108675.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108760.jpg',
		source: '/assets/photo-gallery/fullsize/DH108760.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108823.jpg',
		source: '/assets/photo-gallery/fullsize/DH108823.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH108916.jpg',
		source: '/assets/photo-gallery/fullsize/DH108916.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109028.jpg',
		source: '/assets/photo-gallery/fullsize/DH109028.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109071.jpg',
		source: '/assets/photo-gallery/fullsize/DH109071.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109091.jpg',
		source: '/assets/photo-gallery/fullsize/DH109091.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109097.jpg',
		source: '/assets/photo-gallery/fullsize/DH109097.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109121.jpg',
		source: '/assets/photo-gallery/fullsize/DH109121.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109145.jpg',
		source: '/assets/photo-gallery/fullsize/DH109145.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109212.jpg',
		source: '/assets/photo-gallery/fullsize/DH109212.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109710.jpg',
		source: '/assets/photo-gallery/fullsize/DH109710.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109725.jpg',
		source: '/assets/photo-gallery/fullsize/DH109725.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109753.jpg',
		source: '/assets/photo-gallery/fullsize/DH109753.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109817.jpg',
		source: '/assets/photo-gallery/fullsize/DH109817.jpg',
		dataSize: '1080x720',
	},
	{
		thumbnail: '/assets/photo-gallery/thumbnail/DH109830.jpg',
		source: '/assets/photo-gallery/fullsize/DH109830.jpg',
		dataSize: '1080x720',
	},
];

export default function PhotoGallery() {
	return (
		<>
			<div style={{ backgroundColor: '#353535', borderRadius: '4px', padding: '10px 14px', marginBottom: 20 }}>
				For <strong>2022</strong> individual participant photos please click{' '}
				<a href={PHOTOS_2022_URL} target="_blank\">
					HERE
				</a>
				.
			</div>

			<div style={{ backgroundColor: '#353535', borderRadius: '4px', padding: '10px 14px', marginBottom: 20 }}>
				For <strong>2021</strong> individual participant photos please click{' '}
				<a href="https://freephotos.finisherpix.com/gallery/2021herbalife24trila\" target="_blank\">
					HERE
				</a>
				.
			</div>

			<div style={{ backgroundColor: '#353535', borderRadius: '4px', padding: '10px 14px', marginBottom: 20 }}>
				For <strong>2019</strong> individual participant photos please click{' '}
				<a href="http://www.finisherpix.com/home.html\" target="_blank\">
					HERE
				</a>
				.
			</div>

			<PhotoGrid gallery={gallery} />
		</>
	);
}
