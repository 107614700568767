import React, { useCallback, useEffect } from 'react';
import ReactGA from 'react-ga';
import { isLinkDisabled } from '../utils';
import styles from './MenuItem.module.scss';
import { useHistory, useLocation, Link } from 'react-router-dom';
import useInteraction from '../../../common/utilities/useInteraction';

function MenuDashLine() {
	return (
		<svg
			className={styles['main-menu-dash-line']}
			width="259"
			height="20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<line x1="0" y1="10" x2="100" y2="10"></line>
		</svg>
	);
}

function MenuItem({ item, mainMenuOpen, subMenuOpen, hidden, onHover, onPressMobile }) {
	const history = useHistory();
	const location = useLocation();

	const onPress = useCallback(
		(e) => {
			if (!e) return;

			if (item.hasSubMenu || item.external) {
				if (item.external) {
					ReactGA.event({
						category: 'External Links',
						action: 'Clicked an external link',
						value: `${item.label} - ${item.external}`,
					});
				}

				return;
			}

			e.preventDefault();
			history.push(`${item.link}`);
		},
		[item, history]
	);

	const { bind, isHovered, isPressed } = useInteraction({ onPress });

	useEffect(() => {
		if (isHovered) {
			onHover(item);
		}
	}, [isHovered, onHover, item]);

	useEffect(() => {
		if (isPressed) {
			if (item.hasSubMenu || item.external) {
				onPressMobile(item);

				return;
			}

			history.push(`${item.link}`);
		}
	}, [isPressed, onPressMobile, item, history]);

	const classNames = `${styles['main-menu-item']} ${
		item.hasSubMenu ? styles['main-menu-item--sub-menu'] : styles['main-menu-item--menu-link']
	} ${isHovered || (item.hasSubMenu && subMenuOpen) ? styles['main-menu-item--hovered'] : ''} ${
		isLinkDisabled(item.link, location.pathname, item) ? styles['is-disabled'] : ''
	} ${item.inActive ? styles['main-menu-item--inactive'] : ''} ${mainMenuOpen ? styles['is-active'] : ''}`;

	return (
		<div {...bind()} className={classNames} style={{ display: hidden ? 'none' : '' }}>
			<div className={styles['menu-item-wrapper']}>
				{item.hasSubMenu ? (
					<>
						<MenuDashLine />
						<div className={styles['main-menu-item-label']}>{item.label}</div>
					</>
				) : (
					<Link
						to={{ pathname: item.link }}
						target={`${item.external ? '_blank' : '_self'}`}
						rel={`${item.external ? 'external noopener noreferrer' : ''}`}
					>
						<MenuDashLine />
						<span className={styles['main-menu-item-label']}>
							{item.icon && <span className={styles.icon}>{item.icon}</span>} {item.label}
						</span>
					</Link>
				)}
			</div>
		</div>
	);
}

export default MenuItem;
