import React from 'react';
import styles from './HomeInfobox.module.scss';
import Home5k from './Infobox/Home5k';
import HomeTriathlon from './Infobox/HomeTriathlon';
import HomeAquabike from './Infobox/HomeAquabike';
import HomeBiketour from './Infobox/HomeBiketour';
// import LearnMoreLink from './LearnMoreLink';

function Infobox(type) {
	switch (type) {
		case '5k':
			return <Home5k />;
		case 'aquabike':
			return <HomeAquabike />;
		case 'biketour':
			return <HomeBiketour />;
		case 'sprint':
			return <HomeTriathlon />;
		default:
			return null;
	}
}

export default function HomeInfobox({ type }) {
	return (
		<div className={styles.HomeInfobox}>
			<div className={styles.inner}>
				{Infobox(type)}
				{/* <LearnMoreLink /> */}
			</div>
		</div>
	);
}
