import React, { useCallback, useEffect, useRef } from 'react';
import PhotoGridPreview from './PhotoGridPreview';
// import { useSetRecoilState } from 'recoil';
import './PhotoGrid.module.scss';

/*
	These files has been included to "piblic\index.html" to make this feature fully functional

	<script src="%PUBLIC_URL%/image-grid-effects/modernizr-custom.js"></script>
	<script src="%PUBLIC_URL%/image-grid-effects/imagesloaded.pkgd.min.js"></script>
	<script src="%PUBLIC_URL%/image-grid-effects/masonry.pkgd.min.js"></script>
	<script src="%PUBLIC_URL%/image-grid-effects/classie.js"></script>
	<script src="%PUBLIC_URL%/image-grid-effects/main.js"></script>
 */

/*=============== GridFx  ===============*/
const Modernizr = window.Modernizr;
const GridFx = window.GridFx;

const SUPPORT = { transitions: Modernizr.csstransitions };
// transition end event name
const transEndEventNames = {
	WebkitTransition: 'webkitTransitionEnd',
	MozTransition: 'transitionend',
	OTransition: 'oTransitionEnd',
	msTransition: 'MSTransitionEnd',
	transition: 'transitionend',
};

const transEndEventName = transEndEventNames[Modernizr.prefixed('transition')];

const onEndTransition = function (el, callback) {
	let onEndCallbackFn = function (ev) {
		if (SUPPORT.transitions) {
			if (ev.target !== this) return;
			this.removeEventListener(transEndEventName, onEndCallbackFn);
		}
		if (callback && typeof callback === 'function') {
			callback.call(this);
		}
	};
	if (SUPPORT.transitions) {
		el.addEventListener(transEndEventName, onEndCallbackFn);
	} else {
		onEndCallbackFn();
	}
};

const OPTIONS = {
	imgPosition: {
		// x and y can have values from 0 to 1 (percentage). If negative then it means the alignment is left and/or top rather than right and/or bottom
		// so, as an example, if we want our large image to be positioned vertically on 25% of the screen and centered horizontally the values would be x:1,y:-0.25
		x: 1,
		y: 1,
	},
	pagemargin: 0,
	onOpenItem: function (instance, item) {
		let win = { width: window.innerWidth, height: window.innerHeight };
		instance.items.forEach(function (el) {
			if (item !== el) {
				let delay = Math.floor(Math.random() * 150);
				el.style.WebkitTransition =
					'opacity .6s ' +
					delay +
					'ms cubic-bezier(.5,1,.2,1), -webkit-transform .6s ' +
					delay +
					'ms cubic-bezier(.5,1,.2,1)';
				el.style.transition =
					'opacity .6s ' + delay + 'ms cubic-bezier(.5,1,.2,1), transform .6s ' + delay + 'ms cubic-bezier(.5,1,.2,1)';

				el.style.WebkitTransform = 'translate3d(-' + win.width + 'px,0,0)';
				el.style.transform = 'translate3d(-' + win.width + 'px,0,0)';
				el.style.opacity = 0;
			}
		});
	},
	onCloseItem: function (instance, item) {
		instance.items.forEach(function (el) {
			if (item !== el) {
				let delay = Math.floor(Math.random() * 150);
				el.style.WebkitTransition =
					'opacity .3s ' +
					delay +
					'ms cubic-bezier(.5,1,.2,1), -webkit-transform .3s ' +
					delay +
					'ms cubic-bezier(.5,1,.2,1)';
				el.style.transition =
					'opacity .3s ' + delay + 'ms cubic-bezier(.5,1,.2,1), transform .3s ' + delay + 'ms cubic-bezier(.5,1,.2,1)';

				el.style.WebkitTransform = 'translate3d(0,0,0)';
				el.style.transform = 'translate3d(0,0,0)';
				el.style.opacity = 1;

				onEndTransition(el, function () {
					el.style.transition = 'none';
					el.style.WebkitTransform = 'none';
				});
			}
		});
	},
};
/*=============== GridFx -- end  ===============*/

export default function PhotoGrid({ gallery }) {
	const handleImageLoaded = (index) => {
		if (gallery.length === index + 1) {
			// wipeout when all images are loaded
			// this.props.setWipeoutToggle(true);
		}
	};

	const refPhotoGrid = useRef();
	const previewWrapper = useRef();
	const gridFX = useRef();

	const onClose = useCallback(() => {
		gridFX.current._closeItem();
	}, []);

	useEffect(() => {
		gridFX.current = new GridFx(refPhotoGrid.current, previewWrapper.current, OPTIONS);
	}, []);

	return (
		<div className="PhotoGrid photo-grid" ref={refPhotoGrid}>
			{gallery.map((item, key) => (
				<div key={key} className="grid__item" data-size={item.dataSize || '1280x1280'}>
					<a href={item.source} className="img-wrap">
						<img src={item.thumbnail} onLoad={() => handleImageLoaded(key)} alt="" />
					</a>
				</div>
			))}

			<div id="photo-grid-preview-wrapper">
				<button
					className="action--close"
					style={{
						opacity: 0,
						position: 'fixed',
						pointerEvents: 'none',
					}}
				></button>

				<PhotoGridPreview onClose={onClose} ref={previewWrapper} />
			</div>
		</div>
	);
}
