import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactGA from 'react-ga';
import { TweenMax, Power4 } from 'gsap';
import styles from './AnimatedButton.module.scss';
import ButtonBase from '../../common/components/button/ButtonBase';
import { useMobileQuery } from '../../common/utilities/MediaQuery';

const defaultWidth = 180;
const defaultWidth2 = 172;

function Button(props) {
	const { addWidth, isOpen, href, gatrack, label, fbq, action, params, theme, fullWidth, fill, registerBtn } = props;

	const isMobile = useMobileQuery();
	const hasMounted = useRef(false);
	const [widthProp] = useState(isNaN(addWidth) ? 0 : parseInt(addWidth, 10));
	const [defaultStyle] = useState(fullWidth ? { width: '100%' } : { width: `${defaultWidth + widthProp}px` });

	// const [slantedButtonLine] = useState({width: `${85 + (widthProp/2)}px`});
	const [slantedButtonLine, setSlantedButtonLine] = useState({ width: `${85 + widthProp / 2}px` });
	const [slantedButtonBgStyle] = useState({ width: `${defaultWidth2 + widthProp}px` });
	const [slantedButtonLine1] = useState({
		marginLeft: `${defaultWidth / 2 + widthProp / 2 - widthProp / 2}px`,
		width: `${defaultWidth + widthProp}px`,
	});
	const [lineAnimatingIn, setLineAnimatingIn] = useState(false);
	const [lineAnimatingOut, setLineAnimatingOut] = useState(false);

	const refLine = useRef({
		line1: null,
		line2: null,
		line3: null,
		line4: null,
		line5: null,
	});

	const lineIds = useRef(['line1', 'line3', 'line4', 'line5']);
	const els = useRef(null);
	const lineStyleDefault = useRef({
		transform: `matrix(1, 0, 0, 1, -${defaultWidth + widthProp}, 0)`,
		ease: Power4.easeInOut,
	});

	useEffect(() => {
		if (theme !== 'gradient') {
			setSlantedButtonLine({ width: `${85 + widthProp / 2}px` });
		} else {
			setSlantedButtonLine({
				marginLeft: 0,
				width: `${defaultWidth + widthProp}px`,
			});
		}
	}, [theme, widthProp]);

	useEffect(() => {
		els.current = lineIds.current.map((label) => {
			return refLine.current[label];
		});

		if (props.hasOwnProperty('isOpen')) {
			TweenMax.set(els.current, lineStyleDefault.current);
		}
	}, [widthProp, props]);

	useEffect(() => {
		if (!hasMounted.current) {
			hasMounted.current = true;
		} else {
			if (props.hasOwnProperty('isOpen')) {
				if (isOpen === true && lineAnimatingIn === false) {
					setLineAnimatingIn(true);
					setLineAnimatingOut(false);

					TweenMax.to(els.current, 0.275, {
						transform: `matrix(1, 0, 0, 1, 0, 0)`,
						ease: Power4.easeInOut,
					});
				}

				if (isOpen === false && lineAnimatingOut === false) {
					setLineAnimatingIn(false);
					setLineAnimatingOut(true);

					TweenMax.to(els.current, 0.5, lineStyleDefault.current);
				}
			}
		}
	}, [props, isOpen, setLineAnimatingIn, setLineAnimatingOut, lineAnimatingIn, lineAnimatingOut]);

	const handleClick = useCallback(() => {
		if (href) {
			if (gatrack) {
				ReactGA.event({
					category: 'External Links',
					action: `Clicked the ${label} link`,
					value: `${label} - ${href}`,
				});
			}

			if (fbq) {
				fbq('track', fbq); // eslint-disable-line
			}

			window.open(href, '_blank');
			return;
		}

		if (action) {
			params ? action(params) : action();
		} else {
			console.log('No action added. Hint: <Button action={functionHere} label="Label Here" />');
		}
	}, [href, gatrack, fbq, action, params, label]);

	let className = `${styles['SlantedButton']} 
 
	${theme ? styles[theme] : ''}
	${fullWidth ? styles['fullWidth'] : ''} ${fill ? styles['fill'] : ''} ${
		!registerBtn && isMobile ? styles['small'] : ''
	}`;

	return (
		<ButtonBase className={className} hoverClassName={styles['is-hovered']} style={defaultStyle} onPress={handleClick}>
			<div className={styles['slanted-button-item']} style={defaultStyle}>
				<div className={styles['slanted-button-outline']} style={defaultStyle}>
					<div ref={refLine.line1} className={styles['slanted-button-line1']} style={slantedButtonLine1}>
						<div className={styles['slanted-button-inner1']}></div>
					</div>
					<div className={styles['slanted-button-line2']}>
						<div className={styles['slanted-button-inner2']}></div>
					</div>
					<div ref={refLine.line3} className={styles['slanted-button-line3']}>
						<div className={styles['slanted-button-inner3']}></div>
					</div>
					<div ref={refLine.line4} className={styles['slanted-button-line4']} style={slantedButtonLine}>
						<div className={styles['slanted-button-inner4']}></div>
					</div>
					<div ref={refLine.line5} className={styles['slanted-button-line5']} style={slantedButtonLine}></div>
				</div>
				<div className={`${styles['slanted-button-text']} ${styles['slanted-button-text1']}`}>{props.label}</div>
				<div className={styles['slanted-button-bg']} style={slantedButtonBgStyle}></div>
				<div className={`${styles['slanted-button-text']} ${styles['slanted-button-text2']}`}>{props.label}</div>
				<div className={styles['slanted-button-hit']}></div>
			</div>
		</ButtonBase>
	);
}

export default Button;
