import { useMediaQuery } from 'react-responsive';
import styles from '../theme/media-query.module.scss';

// Hooks for testing the media properties.

export const useDesktopLargeQuery = () => {
	return useMediaQuery({ query: styles.queryDesktopLarge });
};

export const useDesktopQuery = () => {
	return useMediaQuery({ query: styles.queryDesktop });
};

export const useTabletQuery = () => {
	return useMediaQuery({ query: styles.queryTablet });
};

export const useTabletLargeQuery = () => {
	return useMediaQuery({ query: styles.queryTabletLarge });
};

export const useMobileQuery = () => {
	return useMediaQuery({ query: styles.queryMobile });
};

// Components for rendering based on the above tests.

export const DesktopLarge = ({ children }) => {
	const isDesktopLarge = useDesktopLargeQuery();
	return isDesktopLarge ? children : null;
};

export const Desktop = ({ children }) => {
	const isDesktop = useDesktopQuery();
	return isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
	const isTablet = useMobileQuery();
	return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
	const isMobile = useMobileQuery();
	return isMobile ? children : null;
};
