import LosAngelesGalaxyLogo from '../media/sponsors/Los_Angeles_Galaxy_logo.svg';
import CRGLogo from '../media/sponsors/CRG-logo.svg';
import MSLogo from '../media/sponsors/Microsoft-logo.svg';
import LongoToyotaLogo from '../media/sponsors/LongoToyota-logo.svg';
import BlueseventyLogo from '../media/sponsors/blue70-logo.svg';
import CedarSinaiLogo from '../media/sponsors/cedars-sinai-logo-portrait.svg';
import TwoXULogo from '../media/sponsors/2xu-vector-logo.svg';
import EAGraphLogo from '../media/sponsors/ea-graphics-logo-white.png';
import FiluetLogo from '../media/sponsors/filuet-logo-all-white.svg';
import PtoLogo from '../media/sponsors/pto-logo.svg';
import FormLogo from '../media/sponsors/form-logo.svg';
import CDWLogo from '../media/sponsors/cdw-logo.svg';
import INWLogo from '../media/sponsors/inw-logo.svg';

export const sponsorData = [
	{
		src: PtoLogo,
		alt: 'PTO',
		name: 'pto',
		link: 'https://protriathletes.org/',
	},
	{
		src: LosAngelesGalaxyLogo,
		alt: 'Galaxy Logo',
		name: 'galaxy',
		link: 'https://www.lagalaxy.com/',
	},
	{
		src: FiluetLogo,
		alt: 'Filuet Logo',
		name: 'filuet',
		link: 'https://www.filuet.land/',
	},
	{
		src: MSLogo,
		alt: 'Microsoft Logo',
		name: 'microsoft',
		link: 'https://www.microsoft.com/',
	},
	{
		src: CDWLogo,
		alt: 'CDW Logo',
		name: 'cdw',
		link: 'https://www.cdw.com/',
	},
	{
		src: INWLogo,
		alt: 'INW Logo',
		name: 'inw',
		link: 'https://inw-group.com/',
	},
	{
		src: LongoToyotaLogo,
		alt: 'Longo Toyota Logo',
		name: 'longo-toyota',
		link: 'https://www.longotoyota.com/',
	},
	{
		src: CRGLogo,
		alt: 'CRG Logo',
		name: 'crg',
		link: 'https://www.curagroup.com/',
	},
	{
		src: BlueseventyLogo,
		alt: 'Blueseventy Logo',
		name: 'blueseventy',
		link: 'https://www.blueseventy.com/',
	},
	{
		src: EAGraphLogo,
		alt: 'EA Graphics Logo',
		name: 'ea-graphics',
		link: 'https://eagraphics.com/',
	},
	{
		src: TwoXULogo,
		alt: '2XU Logo',
		name: '2xu',
		link: 'https://us.2xu.com//',
	},
	{
		src: CedarSinaiLogo,
		alt: 'Cedars Sinai - Kerlan Jobe Institute',
		name: 'cedars-sinai',
		link: 'https://kerlanjobe.org/',
	},
	{
		src: FormLogo,
		alt: 'FORM',
		name: 'form',
		link: 'https://www.formswim.com/',
	},
];
