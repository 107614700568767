import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Axios from 'axios';
import styles from './Contact.module.scss';
import Button from '../../components/Button/Button';
import { useMobileQuery, useDesktopQuery } from '../../common/utilities/MediaQuery';
import { CONTACT_PATH } from '../../data/pathConstants';
import Subscribe from '../Subscribe/Subscribe';
import contactBg from '../../media/page-bgs/contact_bg.jpg';

export const ContactData = {
	id: 'contact',
	url: CONTACT_PATH,
	pageBg: contactBg,
	pageTitle: 'Contact',
	pageSummary: <div>Subscribe to our mailing list for the latest updates or get in touch with us below. <br /><Subscribe /></div>,
	pageIcon: '/assets/icons/contact.svg',
	metaKeywords: 'Herbalife 24, Herbalife24 triathlon LA questions, contact information',
	metaDescription: 'Have questions about the Herbalife24 Triathlon Los Angeles? Contact us here!',
};

///////////////
//Form Elements
///////////////
function Input({ type, value, onChange, name, placeholder }) {
	switch (type) {
		case 'text':
			return (
				<input
					className={styles['form-input']}
					type="text"
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				/>
			);
		case 'textarea':
			return (
				<textarea
					className={styles['form-text-area']}
					rows="4"
					cols="50"
					name={name}
					placeholder={placeholder}
					value={value}
					onChange={onChange}
				></textarea>
			);
		default:
			return;
	}
}

function FieldSet({ children, required = false }) {
	return (
		<fieldset className={styles['form-fieldset']}>
			<div className={`${styles['form-fields']} ${required ? styles['is-required'] : ''}`}>{children}</div>
		</fieldset>
	);
}

function FieldSetColumn({ children }) {
	return (
		<fieldset className={styles['form-fieldset']}>
			<div className={`${styles['form-fields']} ${styles['form-with-column']}`}>{children}</div>
		</fieldset>
	);
}

function Field({ name, value, placeholder, type, onChange }) {
	return (
		<div className={styles['form-field-item']}>
			<Input type={type} name={name} placeholder={placeholder} value={value} onChange={onChange} />
		</div>
	);
}

function FieldColumn({
	name,
	value,
	placeholder,
	type,
	onChange,
	column = false,
	required = false,
	leftPadding = false,
}) {
	const className = `
	${styles['form-field-item']}
	${leftPadding ? styles['form-field-item-left-padding'] : ''} 
	${styles['form-field-item-50' + required ? ' is-required' : '']}
	`;

	return (
		<div className={className}>
			<Input type={type} name={name} placeholder={placeholder} value={value} onChange={onChange} />
		</div>
	);
}

function ContactItem({ title, email }) {
	return (
		<div className={styles['contacts-contact-item']}>
			<span className={styles['contacts-contact-name']}>{title}</span>
			<a href={`mailto:${email}`} className={styles['contacts-contact-email-address']}>
				{email}
			</a>
		</div>
	);
}

///////////////
//EO Form Elements
///////////////

export default function ContactPage() {
	const isMobile = useMobileQuery();
	const isDesktop = useDesktopQuery();
	const [captchaValue, setCaptchaValue] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [required, setRequired] = useState({
		firstName: false,
		lastName: false,
		email: false,
		message: false,
		captchaValue: false,
	});
	// const [canSubmit, setCanSubmit] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const [submitInProgress, setSubmitInProgress] = useState(false);
	const [submitError, setSubmitError] = useState(false);

	const _reCaptchaRef = useRef();
	const refContactForm = useRef();

	const handleCanSubmit = useCallback(() => {
		return captchaValue && firstName && lastName && email && message;
	}, [captchaValue, firstName, lastName, email, message]);

	const handleSetRequired = useCallback(() => {
		//changed the name bec of setter conflict
		setRequired({
			firstName: firstName ? false : true,
			lastName: lastName ? false : true,
			email: email ? false : true,
			message: message ? false : true,
			captchaValue: captchaValue ? false : true,
		});
	}, [captchaValue, firstName, lastName, email, message, setRequired]);

	const handleCaptchaChange = useCallback(
		(value) => {
			setCaptchaValue({
				captchaValue: value,
			});
		},
		[setCaptchaValue]
	);

	const onSubmit = useCallback(
		(e) => {
			e.preventDefault();

			if (handleCanSubmit()) {
				submitInProgress(true);
			} else {
				handleSetRequired();
			}
		},
		[handleCanSubmit, submitInProgress, handleSetRequired]
	);

	useEffect(() => {
		Axios.post('https://nahrt5ali6.execute-api.us-west-2.amazonaws.com/dev/herbalife-sendmail', {
			'g-recaptcha-response': captchaValue,
			firstName: firstName,
			lastName: lastName,
			email: email,
			message: message,
		})
			.then((response) => {
				if (response.data && response.data.errorMessage) {
					setSubmitInProgress(false);
					setSubmitError(response.data.errorMessage);
				} else {
					setSubmitInProgress(false);
					setSubmitError('');
					setSubmitted(true);
				}
			})
			.catch((error) => {});

		return;
	}, [
		submitInProgress,
		captchaValue,
		firstName,
		lastName,
		email,
		message,
		setSubmitInProgress,
		setSubmitError,
		setSubmitted,
	]);

	const getInputButton = useCallback(() => {
		if (submitInProgress) {
			return <div className={`${styles['form-message']} ${styles['is-inprogress']}`}>Submitting...</div>;
		}

		if (submitted) {
			return (
				<div className={`${styles['form-message']} ${styles['is-success']}`}>
					Thank you for your message! We will get in touch with you shortly.
				</div>
			);
		}

		return (
			<React.Fragment>
				<Button submit={onSubmit} isMobile={isMobile} label="Contact" />
				<div style={{ width: 50 }}></div>
				<input type="submit" value="Submit" style={{ opacity: 0, width: 0, height: 0, margin: 0 }} />
			</React.Fragment>
		);
	}, [submitInProgress, submitted, onSubmit, isMobile]);

	return (
		<div className={styles['inner-wrap']}>

			<div className={styles['contact-row']}>
				<form className={styles.form} ref={refContactForm} onSubmit={onSubmit}>
					<h3 className={`${styles['detail-body-title']} ${isDesktop ? styles['no-overlap'] : ''}`}>Get in Touch</h3>

					<FieldSetColumn>
						<FieldColumn
							name="firstName"
							value={firstName}
							placeholder="First Name"
							onChange={(e) => setFirstName(e.target.value)}
							required={required.firstName}
							type="text"
						/>

						<FieldColumn
							name="lastName"
							value={lastName}
							placeholder="Last Name"
							onChange={(e) => setLastName(e.target.value)}
							required={required.lastName}
							type="text"
							leftPadding
						/>
					</FieldSetColumn>

					<FieldSet required={required.email}>
						<Field
							name="email"
							value={email}
							placeholder="Your email address"
							onChange={(e) => setEmail(e.target.value)}
							type="text"
						/>
					</FieldSet>

					<FieldSet required={required.message}>
						<Field
							name="message"
							value={message}
							placeholder="Message"
							onChange={(e) => setMessage(e.target.value)}
							type="textarea"
						/>
					</FieldSet>

					<FieldSet>
						<ReCAPTCHA
							className={`${styles['recaptcha' + required.lastName ? ' is-required' : '']}`}
							theme="light"
							ref={_reCaptchaRef}
							sitekey="6Ldd7XUUAAAAAEFnifZMaYW_wO9D6SHKKWvBlCfo"
							onChange={handleCaptchaChange}
						/>
					</FieldSet>

					{submitError ? (
						<FieldSet>
							<div className={`${styles['form-message']} ${styles['is-error']}`}>{submitError}</div>
						</FieldSet>
					) : null}

					<FieldSet>{getInputButton()}</FieldSet>
				</form>

				<div className={styles.contacts}>
					<div className={styles['contacts-wrap']}>
						<h3
							className={`${styles['detail-body-title']}  ${isDesktop ? styles['no-element'] : styles['no-overlap']}`}
						>
							Contacts
						</h3>

						<ContactItem title="Registration" email="info@herbalife24tri.la" />
					</div>
				</div>
			</div>
		</div>
	);
}
