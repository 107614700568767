import { EXPO_PATH } from '../../data/pathConstants';
import { Item, List } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Title from '../Text/Title';

import expoBg from '../../media/page-bgs/expo_bg.jpg';
import expo from '../../media/expo-1.jpg';
import expoLocation from '../../media/expo-location.jpg';
import expoPark from '../../media/expo-park.jpg';

export const ExpoData = {
	id: 'expo',
	url: EXPO_PATH,
	pageBg: expoBg,
	pageTitle: 'Expo',
	pageSummary: '',
	pageIcon: '/assets/icons/crg-logo.svg',
	pageIconSize: 'full',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, JW Marriott, JW Marriott Los Angeles, JW Marriott LA, LA Live, microsoft theater events, microsoft theater',
	metaDescription: 'Learn about our pre-event Race Expo with music, fun giveaways and more!',
};

export default function Expo() {
	return (
		<>
			<Title>Pre-Event Race Expo</Title>
			<List>
				<Item>Saturday May 14, 2022 11:00am - 5:00pm</Item>
				<Item>
					<strong>Location:</strong>{' '}
					<a
						href="https://www.lacclink.com/events/detail/herbalife24-tri-la-packet-pick-up"
						rel="noopener noreferrer"
						target="_blank"
					>
						Los Angeles Convention Center 1201 S. Figueroa St. Los Angeles, CA 90015 – Petree Hall C/D
					</a>
				</Item>
				<Item>
					<strong>Packet pick-up is REQUIRED for this race.</strong>
				</Item>
				<List>
					<Item>5K runners have the option of picking up their packet on race day.</Item>
				</List>
			</List>

			<img src={expo} alt="expo" />

			<Paragraph>
				The Expo will include a packet pick-up area where participants can ask questions about the course, begin to
				organize Transition Area 1 (T1) & Transition Area 2 (T2) bags, and pick-up their official race shirt (and maybe
				some other goodies!).
			</Paragraph>
			<Paragraph>
				In addition, the Expo will have music, fun giveaways, and various booths from both local and international
				endurance brands. Come sample triathlete-focused nutrition items, training products, and other merchandise.
			</Paragraph>

			<Paragraph>The Expo will be located in Petree Hall.</Paragraph>

			<img src={expoLocation} alt="expo location" />

			<Title>Parking</Title>
			<Paragraph>
				The Los Angeles Convention Center (LACC) offers convenient parking for the 2021 Herbalife24 Tri Packet pick-up
				and Expo.{' '}
				<strong>
					The nearest parking garage to the Expo (located at Petree Hall) will be the West Hall Parking Garage.
				</strong>{' '}
				Pay a daily flat rate of $15-20 depending on event and parking location - no validation required.
			</Paragraph>

			<Paragraph>General parking garage hours are 5:30am - 9:00pm daily.</Paragraph>
			<Paragraph>
				Cash and all major credit cards (Visa, MasterCard, American Express and Discover) accepted. We do not accept
				checks.
			</Paragraph>
			<Paragraph>
				For more information, visit{' '}
				<a href="https://www.lacclink.com/attendees/parking" rel="noopener noreferrer" target="_blank">
					https://www.lacclink.com/attendees/parking
				</a>
			</Paragraph>

			<img src={expoPark} alt="expo park" />
		</>
	);
}
