import React from 'react';
import styles from './Landscape.module.scss';
import { useRecoilValue } from 'recoil';
import LandscapeActiveState from '../../states/LandscapeActive';
import useWindowSize from '../../common/utilities/useWindowSize';
import City from './City';
import MountainsFront from './MountainsFront';
import MountainsBack from './MountainsBack';
import CityLights from './CityLights';
import { useSpring, a } from '@react-spring/web';
import { useMobileQuery } from '../../common/utilities/MediaQuery';

function Landscape() {
	const { width, height } = useWindowSize();
	const landscapeActive = useRecoilValue(LandscapeActiveState);
	const isMobile = useMobileQuery();

	const tStyles = useSpring({
		opacity: landscapeActive ? 1 : 0,
	});

	return (
		<a.div className={styles.Landscape} style={{ ...tStyles, width, height, willChange: 'opacity' }}>
			<div
				style={{
					transform: `scale(${isMobile ? 0.9 : 1}) translateY(${isMobile ? '145px' : '15px'})`,
					transformOrigin: 'bottom',
					height: '100%',
					width: '100%',
				}}
			>
				<MountainsBack />
				<MountainsFront />
				<City />
				<CityLights />
			</div>
		</a.div>
	);
}

export default Landscape;
