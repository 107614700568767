import React from 'react';
import styles from './SocialMedia.module.scss';

function SocialMedia () {
	return (
		<div className={styles['main-menu-social-list']}>
		    <a className={`${styles['main-menu-social-item']} ${styles['main-menu-social-item-facebook']}`} href="https://www.facebook.com/Herbalife24-Triathlon-Los-Angeles-824125981119863" target="_blank" rel="noopener noreferrer">
		        <svg className={styles['main-menu-social-item-svg']} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 42 77" aastyle="enable-background:new 0 0 42 77;">
		            <g>
		                <path className={styles.st0} d="M40,0L30,0C18.8,0,11.6,7.4,11.6,18.9v8.7h-10c-0.9,0-1.6,0.7-1.6,1.6v12.6c0,0.9,0.7,1.6,1.6,1.6h10v31.9
							c0,0.9,0.7,1.6,1.6,1.6h13.1c0.9,0,1.6-0.7,1.6-1.6V43.4h11.7c0.9,0,1.6-0.7,1.6-1.6l0-12.6c0-0.4-0.2-0.8-0.5-1.1
							c-0.3-0.3-0.7-0.5-1.1-0.5H27.8v-7.4c0-3.6,0.8-5.4,5.5-5.4l6.7,0c0.9,0,1.6-0.7,1.6-1.6V1.6C41.6,0.7,40.9,0,40,0L40,0z M40,0"></path>
		            </g>
		        </svg>
		    </a>
		    <a className={`${styles['main-menu-social-item']} ${styles['main-menu-social-item-twitter']}`} href="https://twitter.com/Herbalife24Tri" target="_blank" rel="noopener noreferrer">
		        <svg className={styles['main-menu-social-item-svg']} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612 612" aastyle="enable-background:new 0 0 612 612;">
		            <g>
		                <path d="M612,116.258c-22.525,9.981-46.694,16.75-72.088,19.772c25.929-15.527,45.777-40.155,55.184-69.411
							c-24.322,14.379-51.169,24.82-79.775,30.48c-22.907-24.437-55.49-39.658-91.63-39.658c-69.334,0-125.551,56.217-125.551,125.513
							c0,9.828,1.109,19.427,3.251,28.606C197.065,206.32,104.556,156.337,42.641,80.386c-10.823,18.51-16.98,40.078-16.98,63.101
							c0,43.559,22.181,81.993,55.835,104.479c-20.575-0.688-39.926-6.348-56.867-15.756v1.568c0,60.806,43.291,111.554,100.693,123.104
							c-10.517,2.83-21.607,4.398-33.08,4.398c-8.107,0-15.947-0.803-23.634-2.333c15.985,49.907,62.336,86.199,117.253,87.194
							c-42.947,33.654-97.099,53.655-155.916,53.655c-10.134,0-20.116-0.612-29.944-1.721c55.567,35.681,121.536,56.485,192.438,56.485
							c230.948,0,357.188-191.291,357.188-357.188l-0.421-16.253C573.872,163.526,595.211,141.422,612,116.258z"></path>
		            </g>
		        </svg>
		    </a>
		    <a className={`${styles['main-menu-social-item']} ${styles['main-menu-social-item-instagram']}`} href="https://www.instagram.com/herbalife24triathlon/" target="_blank" rel="noopener noreferrer">
		        <svg className={styles['main-menu-social-item-svg']} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 97.395 97.395" aastyle="enable-background:new 0 0 97.395 97.395;">
		            <g>
		                <path d="M12.501,0h72.393c6.875,0,12.5,5.09,12.5,12.5v72.395c0,7.41-5.625,12.5-12.5,12.5H12.501C5.624,97.395,0,92.305,0,84.895
							V12.5C0,5.09,5.624,0,12.501,0L12.501,0z M70.948,10.821c-2.412,0-4.383,1.972-4.383,4.385v10.495c0,2.412,1.971,4.385,4.383,4.385
							h11.008c2.412,0,4.385-1.973,4.385-4.385V15.206c0-2.413-1.973-4.385-4.385-4.385H70.948L70.948,10.821z M86.387,41.188h-8.572
							c0.811,2.648,1.25,5.453,1.25,8.355c0,16.2-13.556,29.332-30.275,29.332c-16.718,0-30.272-13.132-30.272-29.332
							c0-2.904,0.438-5.708,1.25-8.355h-8.945v41.141c0,2.129,1.742,3.872,3.872,3.872h67.822c2.13,0,3.872-1.742,3.872-3.872V41.188
							H86.387z M48.789,29.533c-10.802,0-19.56,8.485-19.56,18.953c0,10.468,8.758,18.953,19.56,18.953
							c10.803,0,19.562-8.485,19.562-18.953C68.351,38.018,59.593,29.533,48.789,29.533z"></path>
		            </g>
		        </svg>
		    </a>
		    <a className={`${styles['main-menu-social-item']} ${styles['main-menu-social-item-youtube']}`} href="https://www.youtube.com/channel/UCdEpKJhYFDLfYKygkXCIzAA" target="_blank" rel="noopener noreferrer">
		        <svg className={styles['main-menu-social-item-svg']} version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" space="preserve">
			        <g transform="translate(-74 -96) scale(1.35)">
			            <path d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80
						c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904
						C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728
						c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816
						c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096
						C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z" />
			        </g>
				</svg>
		    </a>
		</div>
	);
}

export default SocialMedia;