import styles from './SportsNutrition.module.scss';
import { HashLink } from 'react-router-hash-link';
import Title from '../Text/Title';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import { Item, List } from '../List/List';
import { SPORTS_NUTRITION_PATH } from '../../data/pathConstants';

import sportsNutritionBg from '../../media/page-bgs/sports_nutrition_bg.jpg';

export const SportsNutritionData = {
	id: 'sportsNutrition',
	url: SPORTS_NUTRITION_PATH,
	pageBg: sportsNutritionBg,
	pageTitle: 'Sports Nutrition',
	pageSummary:
		'You don’t have to be a professional triathlete to need sports nutrition. Learn how Herbalife can help.',
	pageIcon: '/assets/icons/sportsnutrition.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, dana ryan, sports nutrition, pre-workout nutrition, CR7, cr7 drive, post workout recovery',
	metaDescription:
		'You don’t have to be a professional triathlete to need sports nutrition. Learn how Herbalife can help. Dr. Dana Ryan shares her insights!',
};

export default function SportsNutrition() {
	return (
		<>
			<Title>Contents</Title>
			<Paragraph>
				<HashLink to={'#intro'}>Intro: Dr. Dana Ryan, Ph.D., M.A.</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#sports-nutrition'}>Sports Nutrition: Why It’s Important</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#training-video'}>Training Video Series: Herbalife24 Sports Nutrition</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#pre-workout'}>Pre Workout: Nutrition</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#during-workout'}>During Workout: Hydration</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#post-workout'}>Post-Workout: Recovery Tips</HashLink>
			</Paragraph>
			<Paragraph>
				<HashLink to={'#biography'}>Biography: Dr. Dana Ryan, Ph.D., M.A.</HashLink>
			</Paragraph>

			<Title id="intro">Dr. Dana Ryan, Ph.D.	, M.A.</Title>
			<Paragraph>
				The sport of Triathlon requires optimal nutrition before the event and during the race. We are fortunate to have Dr. Dana Ryan, Ph.D., M.A. share her insights! She is responsible for helping establish Herbalife Nutrition as a global leader in sports nutrition.
			</Paragraph>
			<Paragraph>
				<img alt="" className={styles['dr-dana-image']} src="/assets/sports-nutrition/image6.jpg" />
				<br />
				Dr. Dana Ryan, Ph.D., M.A.
				<br />
				Director, Sports Performance and Education Herbalife Nutrition
			</Paragraph>
			<Paragraph>
				Her primary focus is supporting Herbalife Nutrition-sponsored athletes around the world by developing nutrition and sports performance programs, as well as designing and managing sports clinical trials. Dr. Ryan has worked with members of the LA Galaxy, Flamengo, Pumas, Barcelona FC and Liga in Ecuador, Football Players training for the NFL Combine and others in support of their nutrition programs.
			</Paragraph>

			<Title id="sports-nutrition">Sports Nutrition: Why It’s Important</Title>
			<Paragraph>
				You don’t have to be a professional triathlete to need sports nutrition. Learn why it’s important and how anyone being active can benefit from Sports Nutrition.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-1"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/eb9tpxKL4Nk\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph></Paragraph>

			<Title id="training-video">Training Video Series: Herbalife24 Sports Nutrition</Title>
			<Paragraph>
				Below is the Herbalife24 Sports Nutrition Training video series is hosted by Dr. Dana Ryan, Ph.D., M.A.
			</Paragraph>
			<Paragraph>
				These insightful videos provide best practices to compliment the Herbalife24 Triathlon Los Angeles training
				program. Although made to be viewed in sequence, feel free to jump to a video topic that is right for you and
				your current training needs.
			</Paragraph>
			<Subtitle>Basics of Nutrition</Subtitle>
			<Paragraph>
				<iframe
					title="video-2"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/jbi8wLBKLSY\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Subtitle>Pre-Workout Nutrition</Subtitle>
			<Paragraph>
				<iframe
					title="video-3"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/diSQvBjD21k\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Subtitle>During Workout Nutrition</Subtitle>
			<Paragraph>
				<iframe
					title="video-4"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/6sXJeAsIhrk\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Subtitle>Post-Workout Nutrition</Subtitle>
			<Paragraph>
				<iframe
					title="video-5"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/NCGrACi8wZk\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;"
					allowFullScreen
				></iframe>
			</Paragraph>

			<Title id="pre-workout">Pre Workout: Nutrition</Title>
			<Paragraph>
				Learn how to boost your energy and mental focus before your workout. Dr. Dana Ryan, Ph.D., M.A., Director, Sports Performance and Education at Herbalife Nutrition, explains what pre-workout supplements are and why they should be taken before physical activity.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-6"
					className={styles['sports-nutrition-video']}
					src="https://player.vimeo.com/video/319562061\"
					frameBorder="0"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph>
				Herbalife24® Prepare helps support your workout before you’ve even begun. Learn the science behind behind{' '}
				<a href="https://herbalife24.com/products\" target="_blank\">
					Herbalife24® Prepare
				</a>
				, and how it helps you support your workout before you've even begun.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-7"
					className={styles['sports-nutrition-video']}
					src="https://player.vimeo.com/video/319138084\"
					frameBorder="0"
					webkitallowfullscreen="true"
					mozallowfullscreen="true"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph>Dr. Dana’s Pre-Workout Shake Recipe</Paragraph>
			<Paragraph>
				<img
					alt=""
					className={styles['dr-dana-pre-workout-shake']}
					src="/assets/sports-nutrition/dr-dana-pre-workout-shake.jpg"
				/>
			</Paragraph>

			<Title id="during-workout">During Workout: Hydration</Title>
			<Paragraph>
				Proper hydration is critical not only for general body functions, but also performance. Dehydration of as little as 2% from normal can impair performance. The more dehydrated you are, the more negative impact it has on performance. In addition to consuming fluids during exercise, it is important to make sure you are properly hydrated before starting a workout. Learn how to keep your hydration levels in check.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-8"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/l0Nvdl7mfss\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Subtitle>Here are a couple tips for maintaining hydration:</Subtitle>

			<List>
				<Item>
					Frequently address hydration status prior to exercise. Here are 2 easy ways to determine hydration status:
				</Item>
				<List>
					<Item>
						Urine color: Examining urine color is an easy way to assess hydration, the darker the color the more
						dehydrated you are. Aim for a clear light yellow color.
					</Item>
					<Item>
						Body weight: Especially when training in hot conditions, it is important to check body weight before and
						after exercise to ensure that you are not losing too much weight. For every pound of weight lost, drink
						16-24 oz of a carbohydrate and electrolyte based drink.
					</Item>
				</List>
				<Item>Know your environment (temperature and humidity)</Item>
				<List>
					<Item>Try to train in conditions that are similar to what is expected on race day.</Item>
					<Item>Develop and practice a drinking strategy that you will follow on race day</Item>
				</List>
				<Item>Don’t over drink!</Item>
				<List>
					<Item>
						Often people fear being dehydrated so they actually drink too much water. This results in a decrease in the
						sodium concentration in our body, called hyponatremia. Hyponatremia can be as big a problem as dehydration.
					</Item>
					<Item>
						If you aren’t losing weight during a long training session or your urine is clear and colorless you could be
						decreasing sodium concentrations, and it is important not to drink any more water.
					</Item>
				</List>
			</List>

			<Subtitle>CR7 Drive</Subtitle>
			<Paragraph>
				Herbalife24 has developed a sports drink to rapidly fuel your workouts and enhance hydration. With natural
				flavors and colors,{' '}
				<a href="https://herbalife24.com/products/\" target="_blank\">
					#CR7DRIVE
				</a>{' '}
				is a modern sports drink, light tasting and formulated to fuel the drive in all of us including{' '}
				<a
					href="https://video.herbalife.com/detail/videos/brand-sponsorships/video/5800865573001/heather-jackson:-herbalife24%C2%AE-cr7-drive?autoStart=true&page=1&sort_by=PUBLISH_DATE:DESC\"
					target="_blank\"
				>
					American Triathlete Heather Jackson
				</a>
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-9"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/y92Os6hEoHk\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph>
				Learn why you need to refuel your body after workout and why your post-workout nutrition is most effective when
				consumed 30 to 45 minutes afterward.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-10"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/OV5Rf2bZMns\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>

			<Title id="post-workout">Post-Workout: Recovery Tips</Title>
			<Paragraph>
				A solid training plan is key to a successful triathlon, however adding good nutrition to that plan can take your
				performance to the next level. Here are 5 simple tips to help your body recover and prepare you for your next
				workout!
			</Paragraph>

			<List>
				<Item>
					<u>Proper Fueling Before and During Exercise</u>
					<br />
					1-2 hours pre-exercise consume a high carbohydrate meal and keep hydrated during exercise with a
					carbohydrate/electrolyte drink
				</Item>
				<Item>
					<u>Eat within 30-45 minutes of finishing exercise</u>
					<br />
					This will help maximize gains and improve recovery
				</Item>
				<Item>
					<u>Tailor Carb to Protein ratio based on intensity and duration of workout</u>
					<br />
					For long endurance workouts, aim for a 3:1 Carbohydrate to Protein ratio. For resistance training or lighter
					workouts shoot for closer to a 1:1 ratio.
				</Item>
				<Item>
					<u>Support your body during recovery</u>
					<br />
					Remember to nourish your body after exhaustion and during temporary, exercise-induced muscle inflammation.
				</Item>
				<Item>
					<u>Sleep!</u>
					<br />
					Make sure to allow time for adequate sleep, 8 hours if possible. This when your body can restore itself to be
					ready for the next day’s training session
				</Item>
			</List>

			<Paragraph>
				Engineered to aid recovery, see how the science of{' '}
				<a href="https://herbalife24.com/products" target="_blank\">
					Herbalife24® Rebuild Strength
				</a>{' '}
				works with your body post-workout.
			</Paragraph>
			<Paragraph>
				<iframe
					title="video-11"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/IvcNxv3cpZs\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>
			<Paragraph>Recovery Recipe</Paragraph>
			<Paragraph>
				<iframe
					title="video-12"
					className={styles['sports-nutrition-video']}
					src="https://www.youtube.com/embed/1EaaRK1RRWg\"
					frameBorder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope;\"
					allowFullScreen
				></iframe>
			</Paragraph>

			<Title id="biography">Biography: Dr. Dana Ryan, Ph.D., M.A.</Title>
			<Paragraph>
				Dr. Ryan is a Herbalife sports Scientist and former collegiate athlete and coach. Dr. Ryan completed her PhD in
				Physical Activity, Nutrition and Wellness at Arizona State, where she studied the impact of Vitamin D
				supplementation on endothelial function, inflammation and VO2max in older adults. She holds a Master’s degree in
				Kinesiology and a Certificate in Nutrition for Optimal Health, Wellness and Performance. Before joining
				Herbalife, Dana taught Exercise Physiology and related courses at Cal State Los Angeles, and concurrently
				conducted research at UCLA on the impact of community-based nutrition and physical activity programs on Heart
				Disease risk. She is a passionate athlete, was the coxswain for the University of Washington Division 1 crew
				team as an undergraduate, and then went on to coach High School rowing in San Diego and Arizona for 8 years.
			</Paragraph>
		</>
	);
}
