import { useCallback, useState } from 'react';
import styles from './Subscribe.module.scss';
import Button from '../../components/Button/Button';
import SharpSpringForm from '../SharpSpringForm/SharpSpringForm';
import Modal from '../Modal/Modal';

function SignupForm() {
	return (
		<div className={styles.signup} id="mc_embed_signup">
			<div id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form">
				<div id="mc_embed_signup_scroll form-fields">
					<div className={styles['mc-field-wrapper']}>
						<div className={styles['mc-embed-title']}>Subscribe to our mailing list for race updates.</div>
						<SharpSpringForm
							id="newsletter-form"
							domain="app-3QN859UOU8.marketingautomation.services"
							formID="S7GwNDcySEzRTTIxTNM1MTRN1LVISUnTTU4ysrBIMTJPMzdLAgA"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

function Subscribe(props) {
	const [showModal, setShowModal] = useState();

	const handleOpenModal = useCallback(() => {
		setShowModal(true);
	}, [setShowModal]);

	const handleClose = useCallback(() => {
		setShowModal(false);
	}, [setShowModal]);

	return (
		<>
			<div className={styles.Subscribe}>
				<Button onPress={handleOpenModal} label="Subscribe" />
			</div>
			<Modal open={showModal} onRequestClose={handleClose}>
				<SignupForm />
			</Modal>
		</>
	);
}

export default Subscribe;
