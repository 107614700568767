import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router';

export default function useTracking() {
	const {pathname} = useLocation();
	
	useEffect(() => {
		ReactGA.initialize('UA-127875205-1', {
			// debug: true,
			siteSpeedSampleRate: 100
		});

		// fbq('init', '318194522148296'); // eslint-disable-line
		fbq('init', '199002122265161'); // eslint-disable-line
		fbq('init', '269764804227686'); // eslint-disable-line
		// eslint-disable-next-line
		snaptr('init', '39c53a6b-10fa-48e3-afdd-b48d3f984c0f', {
			//   'user_email': '__INSERT_USER_EMAIL__'
		});
	}, []);

	useEffect(() => {
		ReactGA.pageview(pathname);
		fbq('track', 'PageView'); // eslint-disable-line
		snaptr('track', 'PAGE_VIEW'); // eslint-disable-line
	}, [pathname]);
}