export function santizeLabel(label) {
	let re = /\W/g;

	if (label && label.replace) {
		return label.toLowerCase().replace(re, '-');
	} else {
		return '';
	}
}

export function isLinkDisabled(link, pathname, item) {
	return ((pathname === link) || (item.alternateLinks && item.alternateLinks.length > 0 && item.alternateLinks.includes(pathname)));
}