import { useEffect, useRef, useState } from 'react';

export default function useBlobify(object) {
	const blobUrlRef = useRef(null);
	const [url, setUrl] = useState(null);
	const [isReady, setIsReady] = useState(false);

	useEffect(() => {
		setIsReady(false);
		fetch(object)
			.then((response) => response.blob())
			.then((blob) => {
				blobUrlRef.current = URL.createObjectURL(blob);
				setUrl(blobUrlRef.current);
				setIsReady(true);
			});

		return () => {
			URL.revokeObjectURL(blobUrlRef.current);
		};
	}, [object]);

	return [url, isReady];
}
