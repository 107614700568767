import useLayerStyles from '../useLayerStyles';
import Menu from '../../components/Menu/Menu';

export default function MenuLayer() {
	const layerStyles = useLayerStyles(5);

	return (
		<div style={layerStyles}>
			<Menu />
		</div>
	);
}
