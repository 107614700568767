import { ROAD_CLOSURE_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';
import roadClosureBg from '../../media/page-bgs/rules_bg.jpg';
import roadClosureImg from '../../media/road-closures.jpg';

export const RoadClosureData = {
	id: 'roadClosures',
	url: ROAD_CLOSURE_PATH,
	pageBg: roadClosureBg,
	pageTitle: 'Road Closures',
	pageSummary:
		'Street closure information for the Herbalife24 Triathlon Los Angeles can be found below. Please plan ahead for alternate routes to avoid street closures.',
	pageIcon: '/assets/icons/highlights.svg',
	metaKeywords:
		'Herbalife 24, race highlights, register, fast course, DTLA, down town Los Angeles, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, triathlon relay, road closure, traffic',
	metaDescription:
		'Street closure information for the Herbalife24 Triathlon Los Angeles can be found below. Please plan ahead for alternate routes to avoid street closures.',
};

export default function RoadClosure() {
	return (
		<>
			<Title>
				Street closure information for the Herbalife24 Triathlon Los Angeles can be found below. Please plan ahead for
				alternate routes to avoid street closures.
			</Title>
			<Subtitle>CLOSURES</Subtitle>
			<Paragraph>
				Street Closure Grid -{' '}
				<a href="/assets/road-closures/road-closures.pdf" target="_blank" rel="noreferrer">
					PDF Download
				</a>
				<br />
				Crossing Points Grid -{' '}
				<a href="/assets/road-closures/crossing-points.pdf" target="_blank" rel="noreferrer">
					PDF Download
				</a>
				<br />
				Freeway Ramp Closure Grid -{' '}
				<a href="/assets/road-closures/ramp-closures.pdf" target="_blank" rel="noreferrer">
					PDF Download
				</a>
			</Paragraph>
			<br />
			<Paragraph>
				Closing and opening times are approximate.
				<br />
				LA Department of Transportation will open roads as quickly and as safely as possible.
				<br />
				Crossing points at major intersections will open as soon <u>after</u> the last cyclist has passed.
			</Paragraph>
			<br />
			<Paragraph>
				<a href="https://www.waze.com/events/herbalife24-triathlon-la-2022-05-15" target="_blank" rel="noreferrer">
					<img src={roadClosureImg} alt="Map of road closures" />
				</a>
			</Paragraph>
		</>
	);
}
