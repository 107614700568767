import { useCallback, useEffect, useState } from 'react';
import styles from './MenuButton.module.scss';
import useInteraction from '../../common/utilities/useInteraction';
import useWindowSize from '../../common/utilities/useWindowSize';
import { useTabletLargeQuery, useMobileQuery } from '../../common/utilities/MediaQuery';
import { useSpring, a } from '@react-spring/web';

const ICON = {
	burger: () => {
		return (
			<svg
				className={`${styles['menu-btn-icon']} ${styles['menu-btn-icon-burger']}`}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="14.5 20.5 34 16"
			>
				<g id="Menu_icon" data-name="Menu icon" transform="translate(-5 -3)">
					<line id="Line_7" data-name="Line 7" className={styles['cls-1']} x2="34" transform="translate(19.5 24.5)" />
					<line id="Line_8" data-name="Line 8" className={styles['cls-1']} x2="34" transform="translate(19.5 31.5)" />
					<line id="Line_9" data-name="Line 9" className={styles['cls-1']} x2="34" transform="translate(19.5 38.5)" />
				</g>
			</svg>
		);
	},
	back: () => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width="44.72"
				height="49.42"
				viewBox="0 0 44.72 49.42"
			>
				<defs>
					<filter id="Rectangle_171" x="0" y="0" width="36.055" height="36.055" filterUnits="userSpaceOnUse">
						<feOffset dy="3" input="SourceAlpha" />
						<feGaussianBlur stdDeviation="3" result="blur" />
						<feFlood floodOpacity="0.161" />
						<feComposite operator="in" in2="blur" />
						<feComposite in="SourceGraphic" />
					</filter>
					<filter id="Rectangle_183" x="0" y="13.364" width="36.055" height="36.055" filterUnits="userSpaceOnUse">
						<feOffset dy="3" input="SourceAlpha" />
						<feGaussianBlur stdDeviation="3" result="blur-2" />
						<feFlood floodOpacity="0.161" />
						<feComposite operator="in" in2="blur-2" />
						<feComposite in="SourceGraphic" />
					</filter>
				</defs>
				<g id="Arrow" transform="translate(-79.78 -72.869)">
					<g id="Group_576" data-name="Group 576" transform="translate(91.21 83.165) rotate(-45)">
						<g transform="matrix(0.71, 0.71, -0.71, 0.71, -0.8, -15.36)" filter="url(#Rectangle_171)">
							<rect
								id="Rectangle_171-2"
								data-name="Rectangle 171"
								width="22.53"
								height="3.004"
								transform="translate(9 21.93) rotate(-45)"
								fill="#fff"
							/>
						</g>
						<g transform="matrix(0.71, 0.71, -0.71, 0.71, -0.8, -15.36)" filter="url(#Rectangle_183)">
							<rect
								id="Rectangle_183-2"
								data-name="Rectangle 183"
								width="22.53"
								height="3.004"
								transform="translate(11.12 19.36) rotate(45)"
								fill="#fff"
							/>
						</g>
					</g>
					<line
						id="Arrow-2"
						data-name="Arrow"
						x2="30"
						transform="translate(93.5 94.5)"
						fill="none"
						stroke="#fff"
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
			</svg>
		);
	},
	close: () => {
		return (
			<svg
				className={`${styles['menu-btn-icon']} ${styles['menu-btn-icon-close']}`}
				xmlns="http://www.w3.org/2000/svg"
				viewBox="463.772 16.772 25.456 25.456"
			>
				<g id="Menu_icon" data-name="Menu icon" transform="translate(440 5)">
					<line
						id="Line_7"
						data-name="Line 7"
						className={styles['cls-1']}
						x2="34"
						transform="translate(24.479 12.479) rotate(45)"
					/>
					<line
						id="Line_9"
						data-name="Line 9"
						className={styles['cls-1']}
						x2="34"
						transform="translate(24.479 36.521) rotate(-45)"
					/>
				</g>
			</svg>
		);
	},
};

const BURGER = 'burger';
const CLOSE = 'close';
const BACK = 'back';

function MenuButton({ mainMenuOpen, subMenuOpen, setMainMenuOpen, setSubMenuOpen, setActiveSubMenuItem }) {
	const { width: winWidth } = useWindowSize();
	const [{ x }, spring] = useSpring(() => ({
		x: 0
	}));
	const isMobile = useMobileQuery();
	const isTabletLarge = useTabletLargeQuery();
	const [buttonType, setButtonType] = useState(BURGER);

	useEffect(() => {
		setButtonType(mainMenuOpen && !subMenuOpen ? CLOSE : mainMenuOpen && subMenuOpen ? BACK : BURGER);
	}, [mainMenuOpen, subMenuOpen]);

	const getButton = useCallback(() => {
		switch (buttonType) {
			case BURGER:
				return <button className={styles['menu-btn']}>{ICON[BURGER]()}</button>;
			case BACK:
				return <button className={styles['menu-btn']} style={{ opacity: buttonType === BACK ? 1 : 0 }}>{ICON[BACK]()}</button>;
			case CLOSE:
				return <button className={styles['menu-btn']} style={{ opacity: buttonType === CLOSE ? 1 : 0 }}>{ICON[CLOSE]()}</button>;
			default:
				return;
		}
	}, [buttonType]);

	const onPress = useCallback(() => {
		if (buttonType === BURGER) {
			setMainMenuOpen(true);
		}

		if (buttonType === CLOSE) {
			setMainMenuOpen(false);
		}

		if (buttonType === BACK) {
			setSubMenuOpen(false);
			setActiveSubMenuItem(null);
		}
	}, [setMainMenuOpen, setSubMenuOpen, setActiveSubMenuItem, buttonType]);

	const { bind } = useInteraction({ onPress });

	useEffect(() => {
		switch (buttonType) {
			case BURGER:
				spring.start({
					x: 0,
				});
				break;
			case BACK:
				if (isMobile) {
					spring.start({
						x: 36,
					});
				} else if (isTabletLarge) {
					spring.start({
						x: 285,
					});
				}
				break;
			case CLOSE:
				spring.start({
					x: winWidth - 70,
				});
				break;
			default:
				return;
		}
	}, [spring, isMobile, isTabletLarge, winWidth, buttonType]);

	return (
		<div className={styles.MenuButton}>
			<a.div className={styles['menu-icon-container']} {...bind()} style={{ x }}>
				{ getButton() }
			</a.div>
			<div className={` ${styles['menu-btn-bg']} ${buttonType !== BURGER ? styles['menu-open'] : ''}`}></div>
		</div>
	);
}

export default MenuButton;
