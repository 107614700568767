import veniceImg from '../media/poi/venice.jpg';
import marVistaImg from '../media/poi/mar-vista.jpg';
import culverCityImg from '../media/poi/culver-city.jpg';
import helmsBakeryImg from '../media/poi/helms-bakery.jpg';
import midCityImg from '../media/poi/mid-city.jpg';
import koreatownImg from '../media/poi/koreatown.jpg';
import dtlaImg from '../media/poi/dtla.jpg';
import laLiveImg from '../media/poi/la-live.jpg';
import mcarthurParkImg from '../media/poi/mcarthur-park.jpg';

export const MAP_DATA = {
	veniceBeach: {
		title: 'Venice Beach',
		copy: 'This global tourist destination is home to one of the most popular and divese beaches in Los Angeles, and is an iconic way to start the Herbalife24 Triathlon LA.',
		img: veniceImg,
	},
	marVista: {
		title: 'Mar Vista',
		copy: 'Up-and-coming neighborhood emerging as a hot-spot for the people of Los Angeles and visitors, alike. Mar Vista is successfully defining itself thanks to its critically acclaimed restaurant scene. The playful vibe of this neighborhood invites you to explore its side-streets and discover hidden gems. Mar Vista can quickly become the highlight of anyone’s stay in Los Angeles, and rightfully so.',
		img: marVistaImg,
	},
	culverCity: {
		title: 'Culver City',
		copy: 'Continually growing, Culver City is best known for its contemporary art scene and upscale shopping. The city epitomizes change in Los Angeles and provides the perfect junction of culture and art with a modern flare. The Arts District is a must see and boasts some of the area’s most acclaimed galleries, museums, and restaurants.',
		img: culverCityImg,
	},
	helmsBakery: {
		title: 'Helms Bakery',
		copy: 'Helms Bakery, now a collection of high-end shops and restaurants, was famously the "official baker" of the 1932 Los Angeles Summer Olympics providing bread to California residents.',
		img: helmsBakeryImg,
	},
	midCity: {
		title: 'Mid-City',
		copy: 'Make your way inland and you’ll stumble upon Midcity in Central Los Angeles. An often overlooked attachment between the westside and downtown, Midcity offers a unique story of its own based in music and art. Ray Charles had his recording studio in this burrow and famed German-American poet Charles Bukowski made his home in the more urban part of the neighborhood.',
		img: midCityImg,
	},
	koreatown: {
		title: 'Koreatown',
		copy: 'Often missed by visitors, Los Angeles’s Koreatown should top any list of places to see in LA. In fact, Anthony Bourdain – known travel enthusiast and foodie – said his perfect day in L.A. would be spent in the inner city’s Asian hub. Adventurous and eclectic, this neighborhood will take you, and your senses, on a wild jam-packed journey of people, smells, and food!',
		img: koreatownImg,
	},
	dtla: {
		title: 'Downtown',
		copy: 'Considered to be on a bit of a comeback, there is more to downtown Los Angeles than meets the eye. Its resurgence is mostly thanks to a thriving Downtown Arts District that paved the way for trendier business, breweries, and restaurants to find success. Perhaps the most unique aspect of downtown is the architectural adventure it offers. The mix of modern and traditional provides a unique backdrop for exploring and offers insight into the once-dismantled neighborhood’s past, present, and future.',
		img: dtlaImg,
	},
	laLive: {
		title: 'L.A. Live',
		copy: 'A modern entertainment complex and adjacent to the Staples Center, L.A. Live play host to major global events, concerts and is the permanent home of the Grammy Museum.',
		img: laLiveImg,
	},
	mcarthurPark: {
		title: 'McArthur Park',
		copy: "A popular filming location for both Hollywood films and music videos, Macarthur Park is home to a natural spring-fed lake and remains one of LA's History Culturual Monuments.",
		img: mcarthurParkImg,
	},
};
