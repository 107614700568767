import React, { useMemo } from 'react';
import styles from './Foreground.module.scss';
import { useRecoilValue } from 'recoil';
import ferrisWheelData from '../../media/lottie/carousel.json';
import palmTreeData from '../../media/lottie/trees.json';
import Lottie from '../Lottie/Lottie';
import ForegroundActiveState from '../../states/ForegroundActive';
import MainMenuStatusState from '../../states/MainMenuStatus';
import Runners from './Runners';
import Water from './Water';
import useWindowSize from '../../common/utilities/useWindowSize';
import Cyclists from './Cyclists';
import { useSpring, a } from '@react-spring/web';
import { useMediaQuery } from 'react-responsive';
import { useTabletQuery, useMobileQuery } from '../../common/utilities/MediaQuery';

function Foreground() {
	const ForegroundActive = useRecoilValue(ForegroundActiveState);
	const MainMenuStatus = useRecoilValue(MainMenuStatusState);
	const { width, height } = useWindowSize();
	const isCrampedForeground = useMediaQuery({ query: '(max-width: 1300px)' });
	const isTablet = useTabletQuery();
	const isMobile = useMobileQuery();

	const waterWidth = 1940;
	const cyclistWidth = 250;

	const carouselTranStyles = useSpring({
		y: MainMenuStatus ? height - 103 : height - 276 - 103,
	});
	const treeTranStyles = useSpring({
		y: MainMenuStatus ? height - 103 : height - 140 - 103,
	});
	const foregroundTranStyles = useSpring({
		y: MainMenuStatus ? 63 : isTablet ? 10 : 0,
	});

	const treeLeftProp = useMemo(() => {
		if (isTablet) {
			return -60;
		}
		if (isCrampedForeground) {
			return 100;
		}
		return 350;
	}, [isCrampedForeground, isTablet]);

	return (
		!isMobile && (
			<a.div className={styles.Foreground} style={{ ...foregroundTranStyles, height, width }}>
				<div
					style={{
						transformOrigin: 'bottom',
						transform: `scale(${isTablet ? 0.9 : 1})`,
						height: '100%',
						width: '100%',
					}}
				>
					{!isCrampedForeground && (
						<a.div className={styles.carousel} style={{ ...carouselTranStyles, left: 170 }}>
							<Lottie active={ForegroundActive || !MainMenuStatus} data={ferrisWheelData} name="carousel" />
						</a.div>
					)}
					{!isMobile && (
						<a.div
							className={styles.trees}
							style={{
								...treeTranStyles,
								left: treeLeftProp,
								transformOrigin: 'bottom',
								scale: isCrampedForeground ? 1.3 : 1,
							}}
						>
							<Lottie active={ForegroundActive || !MainMenuStatus} data={palmTreeData} name="palmTrees" />
						</a.div>
					)}

					<div className={styles.runners} style={{ top: height - 165, left: width * 0.75 - 70 }}>
						<Runners />
					</div>
					<div
						className={styles.cyclists}
						style={{
							width: cyclistWidth,
							top: height - 165,
							left: isTablet ? width / 2 - cyclistWidth / 2 - 110 : width / 2 - cyclistWidth / 2,
						}}
					>
						<Cyclists />
					</div>
					<div
						className={styles.water}
						style={{ width: waterWidth, top: height + 10, left: isCrampedForeground ? width - waterWidth + 240 : 0 }}
					>
						<Water width={waterWidth} />
					</div>
				</div>
			</a.div>
		)
	);
}

export default Foreground;
