import useLayerStyles from '../useLayerStyles';
import Foreground from '../../components/Foreground/Foreground';
import { Route, Switch } from 'react-router';
import { HOME_PATH, HOME_TRIATHLON_PATH, HOME_AQUABIKE_PATH, HOME_5K_PATH, HOME_BIKETOUR_PATH } from '../../data/pathConstants';

export default function ForegroundLayer() {
	const layerStyles = useLayerStyles(5);

	return (
		<Switch>
			<Route path={[HOME_PATH, HOME_TRIATHLON_PATH, HOME_AQUABIKE_PATH, HOME_5K_PATH, HOME_BIKETOUR_PATH]} exact>
				<div style={layerStyles}>
					<Foreground />
				</div>
			</Route>
		</Switch>
	);
}
