import { useState, useCallback, useMemo, useRef } from 'react';
import { useGesture } from '@use-gesture/react';
import { noop } from '../utilities/utilities';
import useRefState from '../utilities/useRefState';

function preventDefault(e) {
	if (e.cancelable) {
		e.preventDefault();
	}
}

export default function useInteraction(
	{
		onPress = noop,
		onPressEnd = noop,
		onPressStart = noop,
		onPressUp = noop,
		onDragStart = noop,
		onDrag = noop,
		onDragEnd = noop,
		onPinch = noop,
		onWheel = noop,
		onLongPress = noop,
		isDisabled = false,
		hasHover = true,
	} = {},
	configOptions = {}
) {
	const target = useRef(null);
	const initialClient = useRef(null);
	const initialTime = useRef(null);
	const intentionalCancel = useRef(false);
	const [isPressed, setIsPressed] = useState(false);
	const [isLongPressed, setIsLongPressed] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const longPressTimer = useRef(null);
	const minimumPressDuration = 500;

	const isPressedRef = useRefState(isPressed);
	const isLongPressedRef = useRefState(isLongPressed);

	// Handler that is called when a press is held for the minimumPressDuration.
	const triggerLongPress = useCallback(() => {
		onLongPress();
		setIsLongPressed(true);
	}, [onLongPress]);

	// Handler that is called when a press interaction starts.
	const triggerPressStart = useCallback(
		(event) => {
			target.current = event.target;
			setIsPressed(true);
			onPressStart(event);

			// WORKAROUND: On mobile Chrome, touch events grab focus after programatically
			// setting it ourselves. Preventing default here stops that.
			target.current.addEventListener('touchstart', preventDefault);
			target.current.addEventListener('touchend', preventDefault);

			if (onLongPress !== noop) {
				target.current.addEventListener('contextmenu', preventDefault, true);

				longPressTimer.current = setTimeout(
					() => triggerLongPress(),
					minimumPressDuration
				);
			}
		},
		[onPressStart, onLongPress, triggerLongPress]
	);

	// Handler that is called when a press interaction ends, either
	// over the target or when the pointer leaves the target.
	const triggerPressEnd = useCallback(() => {
		setIsPressed(false);
		onPressEnd();
		isPressedRef.current = false;

		target.current.removeEventListener('touchstart', preventDefault);
		target.current.removeEventListener('touchend', preventDefault);

		if (onLongPress !== noop) {
			target.current.removeEventListener('contextmenu', preventDefault, true);

			clearTimeout(longPressTimer.current);
			setIsLongPressed(false);
		}
	}, [onPressEnd, onLongPress, isPressedRef]);

	// Handler that is called when the press is released over the target.
	const triggerPress = useCallback(() => {
		if (isLongPressedRef.current) {
			triggerPressEnd();
			return;
		}

		onPress();
		triggerPressEnd();
	}, [onPress, triggerPressEnd, isLongPressedRef]);

	// Handler that is called when a press is released over the target,
	// regardless of whether it started on the target or not.
	const triggerPressUp = useCallback(() => {
		onPressUp();
	}, [onPressUp]);

	const gestures = useMemo(() => {
		let options = {};
		const hasPress =
			onPress !== noop ||
			onPressStart !== noop ||
			onPressEnd !== noop ||
			onPressUp !== noop ||
			onLongPress !== noop;

		if (hasHover) {
			options.onHover = ({ hovering }) => setIsHovered(hovering);
		}

		if (hasPress) {
			options.onPointerDown = ({ event }) => {
				if (event.button === 0) {
					if (initialClient.current === null) {
						initialClient.current = { x: event.clientX, y: event.clientY };
					}

					initialTime.current = Date.now();
					triggerPressStart(event);
				}
			};
			options.onPointerUp = ({ event }) => {
				if (event.button === 0) {
					if (isPressedRef.current) {
						if (
							intentionalCancel.current &&
							Date.now() - initialTime.current > 150
						) {
							initialClient.current = null;
							intentionalCancel.current = false;
							triggerPressEnd();
						} else {
							triggerPress();
						}
					} else {
						triggerPressUp();
					}
					isPressedRef.current = false;
				}
			};
			options.onPointerLeave = () => {
				isPressedRef.current && triggerPressEnd();
			};
			options.onPointerMove = ({ event }) => {
				if (isPressedRef.current) {
					if (
						Math.abs(initialClient.current.x - event.clientX) > 10 ||
						Math.abs(initialClient.current.y - event.clientY > 10)
					) {
						intentionalCancel.current = true;
					}
				}
			};
		}

		if (onDrag !== noop || onDragStart !== noop || onDragEnd !== noop) {
			options.onDragStart = (state) => {
				setIsDragging(true);
				onDragStart(state);
			};
			options.onDrag = onDrag;
			options.onDragEnd = (state) => {
				setIsDragging(false);
				onDragEnd(state);
			};
		}

		if (onPinch !== noop) {
			options.onPinch = onPinch;
		}

		if (onWheel !== noop) {
			options.onWheel = onWheel;
		}

		return options;
	}, [
		onPress,
		onPressEnd,
		onPressStart,
		onPressUp,
		onLongPress,
		triggerPressStart,
		triggerPress,
		triggerPressEnd,
		triggerPressUp,
		isPressedRef,
		onDrag,
		onDragStart,
		onDragEnd,
		onPinch,
		onWheel,
		hasHover,
	]);

	const bind = useGesture(gestures, {
		...configOptions,
		enabled: !isDisabled && configOptions.enabled,
	});

	return { bind, isHovered, isPressed, isLongPressed, isDragging };
}
