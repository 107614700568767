import styles from './Paragraph.module.scss';

export default function Paragraph({ children, style = {} }) {
	return (
		<p style={style} className={styles['paragraph']}>
			{children}
		</p>
	);
}

export { Paragraph as P };