import { useEffect, useState } from 'react';
import useMeasure from 'react-use-measure';
import useDebounce from './useDebounce';

export default function useInit({ debounceInterval = 70 } = {}) {
	const [init, setInit] = useState(false);
	const [firstLayout, setFirstLayout] = useState(false);
	const [ref, dimensions] = useMeasure();

	const debounceDimensions = useDebounce(dimensions, debounceInterval);

	useEffect(() => {
		if (debounceDimensions.height !== 0 && debounceDimensions.width !== 0) {
			setFirstLayout(true);
		}
	}, [debounceDimensions]);

	useEffect(() => {
		if (firstLayout) {
			setInit(true);
		}
	}, [firstLayout]);

	useEffect(() => {
		if (firstLayout && init) {
			if (debounceDimensions.height === 0 || debounceDimensions.width === 0) {
				setInit(false);
				setFirstLayout(false);
			}
		}
	}, [debounceDimensions.height, debounceDimensions.width, firstLayout, init]);

	return [ref, dimensions, init];
}
