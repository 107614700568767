// Source: https://codesandbox.io/s/mobile-animation-demos-plfqz?fontsize=14&hidenavigation=1&theme=dark

// copy a string to clipboard
export const stringToClipboard = (string) => {
	var $body = document.getElementsByTagName('body')[0];

	var $tempInput = document.createElement('INPUT');
	$body.appendChild($tempInput);
	$tempInput.setAttribute('value', string);
	$tempInput.select();
	document.execCommand('copy');
	$body.removeChild($tempInput);
	return;
};

export function noop() {}

// percent should be between 0 and 1
export const range = (start, end, percent) => (end - start) * percent + start;

export function clamp(value, min = -Infinity, max = Infinity) {
	return Math.min(Math.max(value, min), max);
}

export function round(value, decimals) {
	const precision = Math.pow(10, decimals);
	return Math.round((value + Number.EPSILON) * precision) / precision;
}

//Number should between Min and Max but if number is greater than Max the return value should be the Max and same as Min
export const clampNumber = (dynamicNumber, clampMin, clampMax) =>
	Math.max(
		Math.min(dynamicNumber, Math.max(clampMin, clampMax)),
		Math.min(clampMin, clampMax)
	);

// take a number ("val") in between the two numbers in arr1, and map it to a number in between the two numbers in arr2
export const rangeMap = (arr1, arr2, val) => {
	const percent = (val - arr1[0]) / (arr1[1] - arr1[0]);
	return range(arr2[0], arr2[1], percent);
};

// rangeMap with a guarantee that the returned number will be inside the bounds of arr2
export const clampedRangeMap = (arr1, arr2, val) => {
	const min = arr2[0] < arr2[1] ? arr2[0] : arr2[1];
	const max = min === arr2[0] ? arr2[1] : arr2[0];
	return clamp(min, max, rangeMap(arr1, arr2, val));
};

export const decelerationRates = {
	fast: 0.99,
	normal: 0.998,
};

// https://medium.com/@nathangitter/building-fluid-interfaces-ios-swift-9732bb934bf5
// note: velocity in UIkit is points per second, but react use gesture gives px per millisecond,
// so we can simplify somewhat
export const projection = (initialVelocity, rateName = 'normal') => {
	const decelerationRate = decelerationRates[rateName] || rateName;
	return (initialVelocity * decelerationRate) / (1 - decelerationRate);
};

export const findNearestNumberInArray = (n, arr) => {
	const sortedArr = [...arr].sort((a, b) => a - b);
	if (n <= sortedArr[0]) return sortedArr[0];
	if (n >= sortedArr[arr.length - 1]) return sortedArr[arr.length - 1];

	for (let i = 1; i < sortedArr.length; i++) {
		const prev = sortedArr[i - 1];
		const current = sortedArr[i];
		if (current === n) return current;
		if (current > n && prev < n) {
			return current - n < n - prev ? current : prev;
		}
	}
	return false;
};

// Sum all number item inside array: ex: [1, 2, 3] = 6
export const arrayNumbersTotal = (numbers) => {
	return numbers.reduce((a, b) => a + b, 0);
};

// https://github.com/granteagon/move/blob/master/src/index.js
export const moveArrayItem = (array, moveIndex, toIndex) => {
	const item = array[moveIndex];
	const length = array.length;
	const diff = moveIndex - toIndex;

	if (diff > 0) {
		// move left
		return [
			...array.slice(0, toIndex),
			item,
			...array.slice(toIndex, moveIndex),
			...array.slice(moveIndex + 1, length),
		];
	} else if (diff < 0) {
		// move right
		const targetIndex = toIndex + 1;
		return [
			...array.slice(0, moveIndex),
			...array.slice(moveIndex + 1, targetIndex),
			item,
			...array.slice(targetIndex, length),
		];
	}
	return array;
};

// https://stackoverflow.com/a/2117523
// Note: This function is compact but slow at generating UUIDs.
export function uuidv4() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		// eslint-disable-next-line
		(
			c ^
			(crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
		).toString(16)
	);
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text, font) {
	// re-use canvas object for better performance
	var canvas =
		getTextWidth.canvas ||
		(getTextWidth.canvas = document.createElement('canvas'));
	var context = canvas.getContext('2d');
	context.font = font;
	var metrics = context.measureText(text);
	return metrics.width;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
