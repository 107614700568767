import React, { useCallback } from 'react';
import ReactGA from 'react-ga';
import styles from './RegisterButton.module.scss';
import ButtonBase from '../../common/components/button/ButtonBase';

function RegisterButton(props) {
	const { href, gatrack, label, fbq, action, params } = props;

	const handleClick = useCallback(() => {
		if (href) {
			if (gatrack) {
				ReactGA.event({
					category: 'External Links',
					action: `Clicked the ${label} link`,
					value: `${label} - ${href}`,
				});
			}

			if (fbq) {
				fbq('track', fbq); // eslint-disable-line
			}

			window.open(href, '_blank');
			return;
		}

		if (action) {
			params ? action(params) : action();
		} else {
			console.log('No action added. Hint: <Button action={this.functionHere} label="Label Here" />');
		}
	}, [href, gatrack, fbq, action, params, label]);

	return (
		<ButtonBase className={styles.RegisterButton} hoverClassName={styles['is-hovered']} onPress={handleClick}>
			<div className={styles.text}>{label}</div>
		</ButtonBase>
	);
}

export default RegisterButton;
