import styles from './Profile.module.scss';

export default function Profile({ img, name, link, children }) {
	return (
		<div className={styles.Profile}>
			{
				img ? <img
						src={img}
						className={styles.Image}
						alt={`Ambassador - ${name}`}
					/> 
				: ''
			}
			
			<div className={styles.Info}>
				<h4 className={styles.Name}>
					{name}
				</h4>

				<div className={styles.Link}>
					<b>IG:</b> {link}
				</div>

				<div className={styles.Description}>
					{children}
				</div>
			</div>
		</div>
	);
}
