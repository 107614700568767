import AnimatedButton from '../../../components/Button/AnimatedButton';
import styles from './MainMenu.module.scss';
import SocialMedia from './SocialMedia';
import Policy from './Policy';
import { useCallback, useEffect, useRef } from 'react';
import { noop, rangeMap } from '../../../common/utilities/utilities';
import useWindowSize from '../../../common/utilities/useWindowSize';
import { useSpring, a } from '@react-spring/web';
import MenuItem from './MenuItem';
import { REGISTER_LABEL, REGISTER_URL } from '../../../data/pageData';
import { useSetRecoilState } from 'recoil';
import LandscapeActiveState from '../../../states/LandscapeActive';
import useInit from '../../../common/utilities/useInit';
import { useTabletLargeQuery, useMobileQuery } from '../../../common/utilities/MediaQuery';

function MainMenu({
	mainMenuOpen,
	subMenuOpen,
	activeSubMenu,
	setMainMenuOpen = noop,
	onHover = noop,
	menuList,
	onPressMobile = noop,
}) {
	const { width: winWidth, height: winHeight } = useWindowSize();
	const enteredContainer = useRef(false);
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const [ref, bounds, init] = useInit({ debounceInterval: 10 });
	const isMobile = useMobileQuery();
	const isTabletLarge = useTabletLargeQuery();
	const isMobileMenuActive = isMobile || isTabletLarge;

	const [{ listX, listY, listOpacity, altItemX, altItemOpacity }, api] = useSpring(() => ({
		listX: 0,
		listY: 0,
		listOpacity: 0,
		altItemX: 0,
		altItemOpacity: 0,
	}));

	const onMouseEnterMainMenuContainer = useCallback(() => {
		if (!isMobileMenuActive) {
			enteredContainer.current = true;
			setMainMenuOpen(true);
			setLandscapeActive(true);
		}
	}, [setMainMenuOpen, setLandscapeActive, isMobileMenuActive]);

	const onMouseLeaveMainMenuContainer = useCallback(() => {
		if (!subMenuOpen && !isMobileMenuActive) {
			setMainMenuOpen(false);

			enteredContainer.current = false;
		}
	}, [subMenuOpen, setMainMenuOpen, isMobileMenuActive]);

	useEffect(() => {
		if (!subMenuOpen && !mainMenuOpen) {
			enteredContainer.current = false;
		}
	}, [subMenuOpen, mainMenuOpen]);

	useEffect(() => {
		if (init) {
			const yCenterTop = winHeight / 2 - bounds.height / 2;
			api.set({ listY: yCenterTop > 160 ? yCenterTop : 160 });
			api.start({ listOpacity: 1 });
		}
	}, [winHeight, bounds.height, api, init]);

	useEffect(() => {
		if (mainMenuOpen) {
			api.stop();
			api.start({
				listX: 24,
				altItemX: 24,
				altItemOpacity: 1,
			});
		} else {
			api.stop();
			api.start({
				listX: 0,
				altItemX: 0,
				altItemOpacity: 0,
			});
		}
	}, [mainMenuOpen, api]);

	const onMouseMove = useCallback(
		({ pageX }) => {
			if (!enteredContainer.current) {
				const listX = rangeMap([0, winWidth / 2], [10, 0], pageX);
				const altItemX = rangeMap([0, winWidth / 2], [10, 0], pageX);

				api.start({
					listX,
					altItemX,
				});
			}
		},
		[api, winWidth]
	);

	useEffect(() => {
		window.addEventListener('mousemove', onMouseMove);

		return () => {
			window.removeEventListener('mousemove', onMouseMove);
		};
	}, [onMouseMove]);

	const onMainMenuHover = useCallback(
		(item) => {
			onHover(item);
			setMainMenuOpen(true);
		},
		[onHover, setMainMenuOpen]
	);

	const onMainMenuPress = useCallback(
		(item) => {
			onPressMobile(item);
			setMainMenuOpen(true);
		},
		[onPressMobile, setMainMenuOpen]
	);

	const listStyle = isMobileMenuActive ? { x: 10 } : { x: listX, y: listY, opacity: listOpacity };

	return (
		<div
			className={`${styles.MainMenu} ${mainMenuOpen ? styles['main-menu--active'] : ''}`}
			onMouseEnter={onMouseEnterMainMenuContainer}
			onMouseLeave={onMouseLeaveMainMenuContainer}
			style={{ height: winHeight }}
		>
			<div className={styles['main-menu-content']}>
				<a.div className={styles.rbutton} style={{ x: isMobileMenuActive ? 10 : altItemX }}>
					<AnimatedButton isOpen={mainMenuOpen} label={REGISTER_LABEL} href={REGISTER_URL} theme="gradient" />
				</a.div>

				<a.div className={styles.SocialMedia} style={{ x: isMobileMenuActive ? 10 : altItemX }}>
					<SocialMedia />
				</a.div>

				<a.div ref={ref} className={styles.list} style={listStyle}>
					{menuList.map((item, index) => {
						return (
							<MenuItem
								key={index}
								item={item}
								mainMenuOpen={mainMenuOpen}
								subMenuOpen={item.label === activeSubMenu}
								onHover={onMainMenuHover}
								onPressMobile={onMainMenuPress}
								hidden={item.mobileOnly}
							/>
						);
					})}
				</a.div>

				<a.div className={styles.Policy} style={{ x: isMobileMenuActive ? 10 : altItemX, opacity: altItemOpacity }}>
					<Policy />
				</a.div>
			</div>
		</div>
	);
}

export default MainMenu;
