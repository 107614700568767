import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import MainMenuStatusState from '../states/MainMenuStatus';
import { useLocation } from 'react-router';

export default function useResetMenu() {
	const location = useLocation();
	const setMainMenuStatus = useSetRecoilState(MainMenuStatusState);

	useEffect(() => {
		setMainMenuStatus(false);
	}, [location, setMainMenuStatus]);
}
