import { atom } from 'recoil';

const LogoBlobState = atom({
	key: 'LogoBlob',
	default: {
		url: '',
		isReady: '',
	},
});

export default LogoBlobState;
