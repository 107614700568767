import React, { useCallback } from 'react';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';
import useStore from '../../states/useStore';

const path = `${process.env.PUBLIC_URL}/assets/course-map/course-map-v6.glb`;

export default function MapModel({ children, ...props }) {
	const { nodes, materials } = useGLTF(path);
	const dispatchPoi = useStore((state) => state.dispatchPoi);

	const handleClick = useCallback(
		(e) => {
			e.stopPropagation();
			dispatchPoi({ isOpen: false });
		},
		[dispatchPoi]
	);

	return (
		<group {...props} dispose={null} onClick={handleClick}>
			<group rotation={[Math.PI / 2, 0, 0]} scale={[0.01, 0.01, 0.01]}>
				<group position={[-1721, 220.79, -114.82]} rotation={[0, 0, -2.23]} scale={[0.75, 0.75, 0.75]}>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Color_Bike_1.geometry}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="hotpink" />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Color_Bike_2.geometry}
						material={nodes.Color_Bike_2.material}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="hotpink" />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Color_Swim_1.geometry}
						material={materials['Mat-Swim.002']}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="#abe90e" />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Ghost_Bike_2.geometry}
						material={nodes.Ghost_Bike_2.material}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="black" transparent opacity={0.3} />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Ghost_Swim_1.geometry}
						material={nodes.Ghost_Swim_1.material}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="black" transparent opacity={0.3} />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Color_Run_1.geometry}
						material={materials['Mat-Bike.002']}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="yellow" />
					</mesh>
					<mesh
						castShadow
						receiveShadow
						geometry={nodes.Ghost_Bike_1.geometry}
						material={nodes.Ghost_Bike_1.material}
						position={[-1007.73, 2390.81, -552.81]}
						rotation={[0, 0, 2.23]}
						scale={[1.33, 1.33, 1.33]}
					>
						<meshBasicMaterial color="black" transparent opacity={0.3} />
					</mesh>
				</group>
				<group position={[-140.55, -154.76, -180.35]} rotation={[0, 0, -2.23]}>
					<group position={[661.05, -1420.55, 0]} rotation={[0, 0, 0.5]}>
						<mesh
							castShadow
							receiveShadow
							geometry={nodes.Cylinder.geometry}
							material={nodes.Cylinder.material}
							position={[0, 0, -100]}
						/>
						<mesh
							castShadow
							geometry={nodes.Flag_Checker.geometry}
							// material={materials['Mat-CheckerFlag.002']}
							position={[0, 0, -87.93]}
						>
							<meshStandardMaterial {...materials['Mat-CheckerFlag.002']} emissiveIntensity={0.35} emissive="red" />
						</mesh>
					</group>
					<group position={[-556.45, 1972.95, 0]} rotation={[0, 0, 1]}>
						<mesh
							castShadow
							receiveShadow
							geometry={nodes.Cylinder_2.geometry}
							material={nodes.Cylinder_2.material}
							position={[0, 0, -100]}
						/>
						<mesh
							castShadow
							geometry={nodes.Flag_Green.geometry}
							// material={materials['Mat-GreenFlag.002']}
							position={[0, 0, -87.93]}
						>
							<meshStandardMaterial {...materials['Mat-GreenFlag.002']} emissiveIntensity={0.5} emissive="green" />
						</mesh>
					</group>
				</group>
				<mesh castShadow receiveShadow geometry={nodes.Water.geometry} position={[1949.27, -849.08, -102.5]}>
					<meshStandardMaterial {...materials['Mat-Water.002']} side={THREE.FrontSide} shadowSide={THREE.FrontSide} />
				</mesh>
				<mesh
					castShadow
					receiveShadow
					geometry={nodes.City.geometry}
					// material={materials['Mat-City.002']}
					position={[0.24, 0.41, -529.43]}
					rotation={[0, 0, Math.PI]}
				>
					<meshStandardMaterial {...materials['Mat-City.002']} side={THREE.FrontSide} shadowSide={THREE.FrontSide} />
				</mesh>
			</group>
		</group>
	);
}

// useGLTF.preload(path);
