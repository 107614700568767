import React, { useEffect, useCallback, useRef } from 'react';
import styles from './SponsorTicker.module.scss';
import { useSprings, a } from '@react-spring/web';
import { sponsorData } from '../../data/sponsorData';

const HEIGHT = 160;

export default function SponsorTicker() {
	const idy = useCallback((y, l = sponsorData.length) => (y < 0 ? y + l : y) % l, []);
	const getPos = useCallback((i, firstVis, firstVisIdy) => idy(i - firstVis + firstVisIdy), [idy]);
	const [springs, api] = useSprings(sponsorData.length, (i) => ({ y: (i < sponsorData.length - 1 ? i : -1) * HEIGHT }));
	const prev = useRef([0, 1]);
	const wheelOffset = useRef(0);
	const dragOffset = useRef(0);
	const index = useRef(0);

	const runSprings = useCallback(
		(x) => {
			const firstVis = idy(Math.floor(x / HEIGHT) % sponsorData.length);
			const firstVisIdy = 1 < 0 ? sponsorData.length - 1 : 1;
			api.start((i) => {
				const position = getPos(i, firstVis, firstVisIdy);
				const prevPosition = getPos(i, prev.current[0], prev.current[1]);
				const rank = firstVis - (x < 0 ? sponsorData.length : 0) + position - firstVisIdy;
				return {
					y: (-x % (HEIGHT * sponsorData.length)) + HEIGHT * rank,
					immediate: 1 < 0 ? prevPosition > position : prevPosition < position,
				};
			});
			prev.current = [firstVis, firstVisIdy];
		},
		[idy, getPos, api]
	);

	const toggleSlide = useCallback(() => {
		wheelOffset.current += HEIGHT;
		dragOffset.current += HEIGHT;
		index.current += HEIGHT;

		runSprings(index.current);
	}, [runSprings]);

	useEffect(() => {
		setInterval(() => {
			toggleSlide();
		}, 3000);
	}, [toggleSlide]);

	return (
		<div className={styles.SponsorTicker}>
			<div className={styles.header}>Sponsors</div>
			<div className={styles.background}></div>
			{springs.map(({ y }, index) => {
				return (
					<a.div
						key={index}
						className={`${styles.sponsor} ${styles[sponsorData[index].name]}`}
						style={{ height: HEIGHT, y, willChange: 'transform' }}
					>
						<a
							href={sponsorData[index].link}
							rel="noopener noreferrer"
							target="_blank"
						>
							<img className={styles.img} src={sponsorData[index].src} alt={sponsorData[index].alt} />
						</a>
					</a.div>
				);
			})}
		</div>
	);
}
