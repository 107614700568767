import { useTransition, a } from '@react-spring/web';
import useWindowSize from '../../common/utilities/useWindowSize';
import OverlayBase from '../../common/components/overlay/OverlayBase';
import styles from './Modal.module.scss';
import ModalClose from './ModalClose';
import Portal from '../../common/utilities/Portal';

export default function Modal({ children, open = false, onRequestClose }) {
	const { width: winWidth, height: winHeight } = useWindowSize();

	const transitions = useTransition(open, {
		from: { opacity: 0, y: -40 },
		enter: { opacity: 1, y: 0, pointerEvents: 'auto' },
		leave: { opacity: 0, y: 60, pointerEvents: 'none' },
	});

	return transitions((transitionStyles, open) => {
		return open ? (
			<Portal>
				<a.div
					className={styles.Modal}
					style={{ width: winWidth, height: winHeight, pointerEvents: transitionStyles.pointerEvents }}
				>
					<a.div className={styles.overlay} style={{ opacity: transitionStyles.opacity }}>
						<OverlayBase fullScreen onPress={onRequestClose} />
					</a.div>
					<a.div className={styles.close} style={{ ...transitionStyles }}>
						<ModalClose onPress={onRequestClose} />
					</a.div>
					<a.div className={styles.content} style={{ ...transitionStyles }}>
						{children}
					</a.div>
				</a.div>
			</Portal>
		) : null;
	});
}
