import useWindowSize from '../../common/utilities/useWindowSize';
// import videoSrc from '../../media/Background Source Video Color.mp4';
import styles from './HomeBG.module.scss';
import imgBgSrc from '../../media/page-bgs/home_bg.jpg';
import runBgSrc from '../../media/page-bgs/home_5k_bg.jpg';
import triBgSrc from '../../media/page-bgs/home_tri_bg.jpg';
import aquaBgSrc from '../../media/page-bgs/home_aquabike_bg.jpg';
import bikeBgSrc from '../../media/page-bgs/home_bike_bg.jpg';
import { useMemo } from 'react';

export default function HomeBG({ type = 'home' }) {
	const { width, height } = useWindowSize();

	const img = useMemo(() => {
		switch (type) {
			case '5k':
				return runBgSrc;
			case 'aquabike':
				return aquaBgSrc;
			case 'biketour':
				return bikeBgSrc;
			case 'sprint':
				return triBgSrc;
			default:
				return imgBgSrc;
		}
	}, [type]);

	return (
		<div style={{ width, height }}>
			{/* <video className={styles.HomeBG} autoPlay playsInline loop muted>
				<source src={videoSrc} type="video/mp4" />
			</video> */}
			<div className={styles.image} style={{ backgroundImage: `url(${img})` }} />
			<div className={styles.grad} />
		</div>
	);
}
