import styles from './Footer.module.scss';
import treeSrc from '../../media/tree.svg';

export default function Footer() {
	return (
		<div className={styles.Footer}>
			<div className={styles.inner}>
				<div className={styles.copy}>
					Questions? Please contact our team at: <a href="mailto:info@herbalife24tri.la">info@herbalife24tri.la</a>
				</div>
				<img className={styles.tree} src={treeSrc} alt="Palm tree icon" />
			</div>
		</div>
	);
}
