import Infobox from '../Infobox/Infobox';
import { Item, List, ListTitle } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import styles from './EventsBikeTour.module.scss';
import { EVENTS_BIKE_TOUR_PATH } from '../../data/pathConstants';

import eventsBikeTourBg from '../../media/page-bgs/events_bike_tour_bg.jpg';

export const EventsBikeTourData = {
	id: 'eventsBikeTour',
	url: EVENTS_BIKE_TOUR_PATH,
	pageBg: eventsBikeTourBg,
	pageTitle: 'Herbalife24 Bike Tour',
	pageSummary: 'An urban athlete experience crafted for all levels of fitness.',
	pageIcon: '/assets/icons/map.svg',
	pageIntro:
		'Enjoy a 22 mile closed bike course through the heart of LA, starting in Venice Beach and ending at the DTLA Finish Line Festival. Travel through unique neighborhoods like Koreatown / Mid-Wilshire / Expo Park and DTLA.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA course description, Herbalife24 triathlon Los Angeles course description, Herbalife24 triathlon Los Angeles, Herbalife24 triathlon LA, venice beach, venice, marina del rey, helms bakery, midcity, koreatown, figueroa corridor, university park, L.A. Live, la live, macarthur park, downtown la, westlake',
	metaDescription:
		'Enjoy a 22 mile closed bike course through the heart of LA, starting in Venice Beach and ending at the DTLA Finish Line Festival. Travel through unique neighborhoods like Koreatown / Mid-Wilshire / Expo Park and DTLA.',
};

export default function EventsBikeTour() {
	return (
		<>
			<Title>Information</Title>
			<Paragraph>The Herbalife24 Bike Tour will be held on Sunday, May 15, 2022 from 5:30 AM - 12:00 PM.</Paragraph>

			<Subtitle>Event Day Schedule</Subtitle>
			<table className={styles.table}>
				<tbody>
					<tr>
						<td>5:30 AM</td>
						<td>On-Site Registration and Bib Pickup</td>
					</tr>
					<tr>
						<td>6:30 AM</td>
						<td>Riders Start</td>
					</tr>
					<tr>
						<td>7:30 AM</td>
						<td>Post-Rid Snacks & Beer Garden</td>
					</tr>
					<tr>
						<td>7:45 AM</td>
						<td>All Riders NOT past Figueroa / Chick Hearn must make a left into the Finish Line</td>
					</tr>
					<tr>
						<td>8:00 AM</td>
						<td>First Shuttle</td>
					</tr>
					<tr>
						<td>10:00 AM</td>
						<td>Bike Valet closes</td>
					</tr>
					<tr>
						<td>1:00 PM</td>
						<td>Finish Festival Closes</td>
					</tr>
					<tr>
						<td>1:00 PM</td>
						<td>Last Shuttle</td>
					</tr>
				</tbody>
			</table>

			<Subtitle>Registration & Bib Pick Up Procedures</Subtitle>
			<Paragraph>
				Participants must provide registration confirmation and valid photo identification to receive the bike ride bib.
				All Bike Tour participants will be assigned a bib number at packet pick-up.
			</Paragraph>
			<Paragraph>
				You may pick up your packet at the Packet Pick Up & Expo on Saturday, May 14th or on race morning at Venice High
				School beginning at 5:30 AM.
			</Paragraph>

			<Subtitle>Bag Check</Subtitle>
			<Paragraph>
				There will not be a Bag Check at Venice High School. Please leave your valuables at home or in your vehicle.
			</Paragraph>

			<Title>Route</Title>

			<Infobox>
				<b>Distance:</b> 22 miles
			</Infobox>
			<Paragraph>
				Enjoy a 22 mile bike tour through the heart of LA, starting in Venice Beach and ending at the DTLA Finish
				Festival.
			</Paragraph>

			<Subtitle>Follow Route Arrows</Subtitle>
			<Paragraph>Orange signs with with arrows</Paragraph>
			<Paragraph>*Routes subject to change</Paragraph>

			<Subtitle>Fluid Station</Subtitle>
			<Paragraph>
				There will be one fluid station offering bottle exchange of water and/or Herbalife CR7 Sports Drink.
			</Paragraph>

			<Subtitle>Bike Marshals</Subtitle>
			<Paragraph>
				Bike Marshals will be riding each course to monitor and assist participants. Marshals can be identified by their
				safety vests, with “EVENT STAFF” title across their back. They will be available to help with minor bike
				repairs, call support or medical staff if needed.
			</Paragraph>

			<Subtitle>SAG Vehicles</Subtitle>
			<Paragraph>
				Velofix will be on each course for minor bike repairs, supplies, water and transportation for riders with broken
				bikes.
			</Paragraph>

			<Subtitle>Emergency / Medical</Subtitle>
			<Paragraph>
				EMTs will be located at the fluid station for basic medical support. In case of a serious emergency, call 911
				immediately.
			</Paragraph>

			<Paragraph>
				If you need assistance with a mechanical problem, are injured or lost, flag down a Bike Marshal wearing a
				reflective safety vest to get you help.
			</Paragraph>

			<Subtitle>Cyclists Safety Tips</Subtitle>
			<Paragraph>
				THIS IS A CLOSED COURSE. Vehicles are not allowed on the course while the triathlon and bike tour are in
				process.
			</Paragraph>

			<ListTitle>Bicycle safety tips on the road:</ListTitle>
			<List>
				<Item>HELMETS ARE MANDATORY!</Item>
				<Item>Wear bright reflective clothing.</Item>
				<Item>
					Attach both a blinking headlight and tail light to your bike, and turn them on during the DAY to be visible
					from the front & back.
				</Item>
				<Item>Avoid wearing headphones.</Item>
				<Item>Do not ride on the sidewalk.</Item>
				<Item>Be predictable, and signal your turns.</Item>
				<Item>Always look behind you or in a mirror before veering/swerving left or right.</Item>
				<Item>
					When appropriate, do not hug the curb too much; leave yourself some space to veer into if necessary; be
					visible.
				</Item>
				<Item>
					While this is a closed course, there is always the possibility an errant vehicle may get onto the course, so
					always be aware of your surroundings.
				</Item>
				<Item>
					If a vehicle gets on the course, ride defensively and stop at the nearest curb until Los Angeles Police
					Officers direct the vehicle off the course. Never give a driver the benefit of the doubt when it comes to your
					life!
				</Item>
			</List>

			<Title>Directions / Parking</Title>
			<Subtitle>Signage / Navigation</Subtitle>

			<ListTitle>AVOID GETTING LOST! FOLLOW THESE STEPS.</ListTitle>
			<List>
				<Item>
					Plan to park at Venice High School. Address: 13000 Venice Blvd., Los Angeles, CA 90066. There is also ample
					free street parking around the school.{' '}
				</Item>
				<Item>
					<b>Remember there is NO PARKING on the South side of Venice Blvd.</b>
				</Item>
				<Item>
					Familiarize yourself with the route by looking at{' '}
					<a
						href="/assets/course-map/pdfs/herbalife24-tri-la-biketourmap.pdf"
						download
						target="_blank"
						rel="noreferrer"
					>
						website
					</a>
					.
				</Item>
				<Item>Remember, this is a closed course, so stay within the traffic barricades until the finish line.</Item>
				<Item>Follow the clearly marked ORANGE signs with white route arrows.</Item>
				<Item>If you are still confused, flag down a Bike Marshal or Police Officer for help.</Item>
			</List>

			<Title>FAQs</Title>
			<Subtitle>What if I have equipment trouble or need help?</Subtitle>
			<Paragraph>Flag down a Bike Marshal and he/she will either help you or call a SAG vehicle to help you.</Paragraph>

			<Subtitle>How will I stay on the route?</Subtitle>
			<Paragraph>
				Visit Herbalife24Tri.la website, familiarize yourself with the route you’re riding. The route will be clearly
				marked with arrow signage on street poles and route arrows on the ground.
			</Paragraph>

			<Subtitle>Will streets be closed to vehicle traffic?</Subtitle>
			<Paragraph>
				YES! The streets will be closed to vehicle traffic and cyclists will be riding on public streets. But be aware
				of other cyclists and your surroundings. Ride safely, and defensively.
			</Paragraph>

			<Subtitle>Will there be refreshments on the ride?</Subtitle>
			<Paragraph>
				Yes, there will be one fluid station offering bottle exchange of water and/or Herbalife24 CR7 Sports Drink. In
				addition, at the finish, all cyclists will receive post-ride snacks and entry to the beer garden.
			</Paragraph>

			<Subtitle>What if I get lost?</Subtitle>
			<Paragraph>To avoid getting lost, familiarize yourself with your route at this website.</Paragraph>

			<Subtitle>What if I get injured on the course is emergency treatment available?</Subtitle>
			<Paragraph>
				In a true lifesaving emergency, call 911, especially if a cyclist has fallen and hit their head/helmet on the
				road. EMT technicians will be available along the routes at the start, the finish and at Venice Blvd. / Vermont
				Ave.
			</Paragraph>

			<Subtitle>What should I do after completing the ride?</Subtitle>
			<Paragraph>
				Follow course signs to the complimentary Bike Valet service to store your bike while you enjoy the Finish Line
				Festival at LA Live. Participants can park their bikes and pick up their finisher’s medal, snacks and food (must
				present bib), and the beer garden for those 21 and older.
			</Paragraph>

			<Subtitle>Can I park my bike after the ride?</Subtitle>
			<Paragraph>
				Herbalife24 Bike Tour provides a complimentary Bike Valet service. If you prefer to self-park, then participants
				should bring a suitable bike restraint. Do not secure your bike to a store front gate, to a stranger’s bike or
				on the property of LA Live. Herbalife24 Triathlon and Bike Tour assumes no responsibility or liability for any
				lost and/or damaged bikes.
			</Paragraph>

			<Subtitle>Can I ride my bike in LA Live?</Subtitle>
			<Paragraph>
				No, you cannot ride, and are required to walk your bike within LA Live and Xbox Plaza. Riders will end the ride
				on Chick Hearn at LA Live, and can valet their bikes to enjoy the Finish Line Festival without the hassle of
				lugging around their two-wheeled friend.
			</Paragraph>

			<Subtitle>How do I get back to Venice High School?</Subtitle>
			<Paragraph>
				All riders are welcome to take the complimentary bus back to Venice. Both you and your bike can ride comfortably
				back to Venice Beach and then ride 1 mile back (on Venice Blvd.) to Venice High School. There is no cost for the
				shuttle. The other option is cyclists can ride back along the race route back to Venice High School If you do
				begin riding back before 9:00am, be aware that there will still be cyclists on the course. YOU MUST RIDE ON THE
				RIGHT SIDE OF THE ROAD AND AVOID ANY CYCLISTS STILL PARTICIPATING. RACE OFFICIALS AND LOS ANGELES POLICE WILL
				REMOVE ANY CYCLISTS RIDING IN AN UNSAFE FASHION.
			</Paragraph>

			<Subtitle>Can I ride an e-bike?</Subtitle>
			<Paragraph>
				Yes, you can ride an electric bicycle (e-bike) at the Herbalife24 LA Bike Tour, but some restrictions do apply.
				Only pedal assist (Class 1 and Class 3) e-bikes are allowed; throttle assist e-bikes are not allowed. You are
				responsible for making sure your battery will last long enough, and you will be staged in the back at the start
				of the ride. NOTE: E-bikes are not allowed on the return shuttles.
			</Paragraph>
		</>
	);
}
