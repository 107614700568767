import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
// import Button from '../../components/Button/Button';
import styles from './HomeCategory.module.scss';
import LandscapeActiveState from '../../states/LandscapeActive';
import LogoLandscape from '../Logo/LogoLandscape';
import LogoDate from '../Logo/LogoDate';
import LogoBox from '../Logo/LogoBox';
import HomeInfobox from './HomeInfobox';
import useWindowSize from '../../common/utilities/useWindowSize';
import useStore from '../../states/useStore';
import usatLogo from '../../media/usat-logo.svg';
import { useMobileQuery } from '../../common/utilities/MediaQuery';

export default function HomeCategory({ type = [] }) {
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const { width, height } = useWindowSize();
	const dispatchScrollY = useStore((state) => state.dispatchScrollY);
	const isMobile = useMobileQuery();

	useEffect(() => {
		setTimeout(() => dispatchScrollY(0), 100);
	}, [dispatchScrollY]);

	useEffect(() => {
		setLandscapeActive(true);
	}, [setLandscapeActive]);

	return (
		<>
			<div className={styles.Home} style={{ width, height }}>
				{!isMobile && (
					<img
						src={usatLogo}
						alt="USAT State Champsionship Logo"
						style={{ position: 'absolute', right: 30, top: 30, width: 200 }}
					/>
				)}

				<div className={styles.content}>
					<div className={styles.logo}>
						<div className={styles['logo-inner']}>
							<LogoLandscape />
							<div className={styles.sub}>
								<LogoBox type={type} />
							</div>
							<div className={styles.date}>
								<div className={styles['date-inner']}>
									<LogoDate />
								</div>
							</div>
						</div>
					</div>
					<HomeInfobox type={type[0]} />
				</div>
			</div>
		</>
	);
}
