export function debounce(func, wait) {
	let timeout
	return function (...args) {
		const context = this
		clearTimeout(timeout)
		timeout = setTimeout(() => func.apply(context, args), wait)
	}
}

export function toArray(object) {
  const arr = [];
  for(const props in object) {
    object[props].label = props;
    arr.push(object[props]);
  }
  return arr;
}

export function find(keyProperty, keyValue, array) {
  for(const element in array) {
    if (array[element][keyProperty] === keyValue) {
      return array[element];
    }
  }
}

export function isHomePage(path) {
  return ((path === '/') || (path === '/home-5k') || (path === '/home-aquabike') || (path === '/home-triathlon') || (path === '/home-biketour'));
}