import useWindowSize from '../common/utilities/useWindowSize';

export default function usePageStyles(zIndex = 1) {
	const { height: winHeight, width: winWidth } = useWindowSize();

	return {
		height: winHeight,
		width: winWidth,
		overflow: 'hidden',
		position: 'absolute',
		top: 0,
		left: 0,
		zIndex,
	};
}
