import React, { useCallback } from 'react';
import ReactGA from 'react-ga';
import styles from './Button.module.scss';
import ButtonBase from '../../common/components/button/ButtonBase';
import { useMobileQuery } from '../../common/utilities/MediaQuery';
import { noop } from '../../common/utilities/utilities';
import classNames from 'classnames';

function Button({
	href,
	gatrack,
	label,
	fbq,
	action,
	params,
	fullWidth,
	fill,
	backgroundColor,
	onPress = noop,
	children,
	className,
	...props
}) {
	const isMobile = useMobileQuery();

	const handleClick = useCallback(() => {
		onPress && onPress();
		if (href) {
			if (gatrack) {
				ReactGA.event({
					category: 'External Links',
					action: `Clicked the ${label} link`,
					value: `${label} - ${href}`,
				});
			}

			if (fbq) {
				fbq('track', fbq); // eslint-disable-line
			}

			window.open(href, '_blank');
			return;
		}

		if (action) {
			params ? action(params) : action();
		} else {
			console.log('No action added. Hint: <Button action={this.functionHere} label="Label Here" />');
		}
	}, [href, gatrack, fbq, action, params, label, onPress]);

	const buttonClassNames = classNames(styles.Button, className, {
		[styles.fullWidth]: fullWidth,
		[styles.fill]: fill,
		[styles.small]: isMobile,
	});

	return (
		<ButtonBase className={buttonClassNames} hoverClassName={styles['is-hovered']} onPress={handleClick}>
			{backgroundColor ? <div className={styles['main-background']} style={{ backgroundColor }}></div> : null}
			<span className={styles.label}>{children ?? label}</span>
			<div className={styles.background}></div>
		</ButtonBase>
	);
}

export default Button;
