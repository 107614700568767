import styles from './WeekendSchedule.module.scss';
import { WEEKEND_SCHEDULE_PATH } from '../../data/pathConstants';
import Title from '../Text/Title';

import weekendEndScheduleBg from '../../media/page-bgs/weekend_schedule_bg.jpg';

export const WeekendScheduleData = {
	id: 'weekendSchedule',
	url: WEEKEND_SCHEDULE_PATH,
	pageBg: weekendEndScheduleBg,
	pageTitle: 'Weekend Schedule',
	pageSummary: 'Check out the schedule below to plan your weekend.',
	pageIcon: '/assets/icons/icon_schedule.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, JW Marriott, JW Marriott Los Angeles, JW Marriott LA, LA Live, microsoft theater events, microsoft theater',
	metaDescription: 'Check out the schedule below to plan your weekend.',
};

export default function WeekendSchedule() {
	return (
		<>
			<Title>Saturday, May 14</Title>
			<table className={styles.table}>
				<tbody>
					<tr>
						<td>11:00 AM</td>
						<td>Expo Opens</td>
					</tr>
					<tr>
						<td>1:00 PM</td>
						<td>
							USAT Mandatory Meeting with Elite Athletes
							<br />
							Race Tips - Main Stage
						</td>
					</tr>
					<tr>
						<td>2:00 PM</td>
						<td>Meet the Elites</td>
					</tr>
					<tr>
						<td>3:00 PM</td>
						<td>Course Talk</td>
					</tr>
					<tr>
						<td>5:00 PM</td>
						<td>Expo Closes</td>
					</tr>
					<tr>
						<td></td>
						<td>
							All T2 Bags must be dropped at the T2 Bag Check Area. T2: Located on Chick Hearn Court between Crypto.com
							Arena and XBox Plaza.
						</td>
					</tr>
				</tbody>
			</table>

			<Title>Sunday, May 15</Title>
			<table className={styles.table}>
				<tbody>
					<tr>
						<td>4:30 AM</td>
						<td>Transition Area 1 (T1) Opens</td>
					</tr>
					<tr>
						<td>5:30 AM</td>
						<td>5k Registration Opens (LA Live) Course will follow the Triathlon 5k Run Course</td>
					</tr>
					<tr>
						<td>5:30 AM</td>
						<td>Pre-Race Meeting in Transition Area</td>
					</tr>
					<tr>
						<td>5:45 AM</td>
						<td>T1 Closes - All athletes must be out of Transition Area</td>
					</tr>
					<tr>
						<td>6:00 AM</td>
						<td>Elite Male Athletes Start</td>
					</tr>
					<tr>
						<td>6:03 AM</td>
						<td>Elite Female Athletes Start </td>
					</tr>
					<tr>
						<td>6:08 AM</td>
						<td>PTO Pro-AM / CAF Relay Start</td>
					</tr>
					<tr>
						<td>6:16 AM</td>
						<td>International Distance Age Groups Start</td>
					</tr>
					<tr>
						<td>6:30 AM</td>
						<td>Bike Tour Begins (Venice High School)</td>
					</tr>
					<tr>
						<td>6:30 AM</td>
						<td>5k Run Starts (LA Live)</td>
					</tr>
					<tr>
						<td>6:30 AM</td>
						<td>Spectator Shuttles Begin Running to LA Live (from Venice Beach) and will leave every 30 minutes</td>
					</tr>
					<tr>
						<td>6:35 AM</td>
						<td>Sprint Distance Age Groups Start</td>
					</tr>
					<tr>
						<td>8:00 AM</td>
						<td>First Shuttle Departs LA Live back to Venice</td>
					</tr>
					<tr>
						<td>10:00 AM</td>
						<td>Awards</td>
					</tr>
					<tr>
						<td>12:30 PM</td>
						<td>Beer Garden closes</td>
					</tr>
					<tr>
						<td>1:00 PM </td>
						<td>Transition Area 2 (T2) Closes (All bags & Bikes need to be picked up)</td>
					</tr>
					<tr>
						<td></td>
						<td>Finish Festival closes</td>
					</tr>
					<tr>
						<td>1:00 PM</td>
						<td>Last Shuttle to T1 (Venice)</td>
					</tr>
					<tr>
						<td>1:00 PM</td>
						<td>Bike Valet in Venice Closed (all bikes must be picked up)</td>
					</tr>
				</tbody>
			</table>
		</>
	);
}
