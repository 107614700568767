import { PTO_PRO_AM_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';
import Title from '../Text/Title';
import Logo from '../../media/pro-am-logo-2022.png';
import pageBg from '../../media/page-bgs/pto_bg.jpg';
import Button from '../Button/Button';
import ptoImage from '../../media/PTO02_RaceWithThePro_16x9NEW.jpg';

export const PTOProAmData = {
	id: 'ptoProAm',
	url: PTO_PRO_AM_PATH,
	pageBg: pageBg,
	pageTitle: 'PTO PRO-AM',
	pageHeader: <img src={Logo} alt="PTO Pro-Am" width={600} />,
	// pageSummary:
	// 	'The PTO Pro-Am will bring together the world’s greatest professional triathletes and big celebrity names to race together in mixed relay teams from Venice Beach to the streets of Downtown Los Angeles.',
	pageIcon: '/assets/icons/training.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, ian murray, sprint triathlon training plan',
	metaDescription:
		'The PTO Pro-Am will bring together the world’s greatest professional triathletes and big celebrity names to race together in mixed relay teams from Venice Beach to the streets of Downtown Los Angeles.',
};

export default function PTOProAm() {
	return (
		<>
			<Paragraph>
				The PTO Pro-Am will bring together the world’s greatest professional triathletes and big celebrity names to race
				together in mixed relay teams from Venice Beach to the streets of Downtown Los Angeles.
			</Paragraph>
			<Paragraph>
				The Professional Triathletes Organisation (PTO) is an athlete owned and run body of the world's greatest
				professional triathletes and it is as a result of working with the PTO that this incredible event is able to be
				part of the Herbalife24Triathlon LA weekend.
			</Paragraph>

			<Button href="https://bit.ly/36GFtwZ">Receive PTO Pro Am Updates</Button>

			<Title>What is the PTO Pro Am?</Title>
			<Paragraph>
				The PTO Pro Am will bring together the world’s greatest professional triathletes and big celebrity names to race
				together in mixed relay teams from Venice Beach to the streets of Downtown Los Angeles as part of the
				Herbalife24 Triathlon LA.
			</Paragraph>

			<Title>When and where?</Title>
			<Paragraph>
				The first ever PTO Pro Am will take place on 15 May 2022 starting at the iconic Venice Beach and finishing in
				Downtown Los Angeles.
			</Paragraph>

			<Title>Elite Professional Race </Title>
			<Paragraph>
				As well as the star studded Pro Am there will be a supporting professional race in which some of the world's
				greatest professional athletes will do battle for a share of the $100,000 prize purse.{' '}
			</Paragraph>

			<Title>Broadcast </Title>
			<Paragraph>
				The PTO Pro Am will be broadcast live globally. Sign up to be updated by the Professional Triathletes
				Organisation to be the first to hear about how to watch the gripping action live and on demand.
			</Paragraph>

			<Title>Athlete & Celebrity Lineup </Title>
			<Paragraph>
				There will be an all star line up up of both professional athletes and celebrities taking part in the PTO Pro
				Am, all of whom will be announced closer to the time. Sign up to be updated by the{' '}
				<a href="https://bit.ly/3K2JLwS" target="_blank" rel="noreferrer">
					Professional Triathletes Organisation
				</a>{' '}
				as soon as the news hits!
			</Paragraph>

			<Title>PTO Tour</Title>
			<img src={ptoImage} alt="Race Your Race: Watch the Pros" />
			<Paragraph>
				For the first time in 2022 the PTO is also bringing to life the PTO Tour, a global series of events featuring
				both professional racing with a combined prize purse of $3.5 million as well as giving age groupers the
				opportunity to race their own race on the same streets as the pros on the same weekend while not missing the
				opportunity to take in all of the gripping pro racing.
			</Paragraph>

			<Paragraph>
				In 2022 the PTO Tour includes the{' '}
				<a href="https://bit.ly/3iTLwAu" target="_blank" rel="noreferrer">
					Canadian Open
				</a>{' '}
				in Edmonton (23-24 July),{' '}
				<a href="https://bit.ly/3iS5JqF" target="_blank" rel="noreferrer">
					The Collins Cup
				</a>{' '}
				in Bratislava, Slovakia (20-21 August) and the{' '}
				<a href="https://bit.ly/3NGuHqV" target="_blank" rel="noreferrer">
					US Open
				</a>{' '}
				in Irving, Dallas (17-18 September). Sign up now to not miss out on the incredible opportunity to join the same
				professionals that will be racing on the streets of Downtown LA.
			</Paragraph>

			<Button href="https://bit.ly/3tYYXFH">Explore the PTO Tour</Button>
		</>
	);
}
