import { useTrail, a } from '@react-spring/web';
import React from 'react';
import { useMobileQuery } from '../../../common/utilities/MediaQuery';
import styles from './SubMenuContent.module.scss';
import SubMenuItem from './SubMenuItem';

function SubMenuMemo({ label, items, subMenuOpen }) {
	const isMobile = useMobileQuery();
	const length = items.length + 1;
	const trail = useTrail(length, {
		opacity: subMenuOpen ? 1 : 0,
		x: subMenuOpen ? 0 : 20.35,
		from: { opacity: 0, x: -20.35 },
		reset: true,
		config: { mass: 1, tension: 170, friction: 26, velocity: 1 },
	});

	const headerValue = trail[0];
	const itemValues = trail.slice(1);

	return (
		<div className={styles.SubMenu}>
			<div className={styles['sub-menu-inner']}>
				<div className={styles.SubMenuTitle}>
					<a.div style={{ opacity: headerValue.opacity, x: headerValue.x, height: '100%' }}>
						<h2 className={styles['sub-menu-title']}>
							<span className={styles['sub-menu-title-inner']}>{label}</span>
						</h2>
					</a.div>
				</div>

				<div className={styles['sub-menu-items']}>
					{itemValues.map(({ opacity, x }, index) => {
						if (items[index].disableOnMobile) {
							if (isMobile) return null;
						}
						return (
							<a.div key={index} style={{ opacity, x }}>
								<SubMenuItem item={items[index]} />
							</a.div>
						);
					})}
				</div>
			</div>
		</div>
	);
}

const SubMenuContent = React.memo(SubMenuMemo);

export default SubMenuContent;
