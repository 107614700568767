import React from 'react';
import { Link } from 'react-router-dom';
import styles from './BlogListItem.module.scss';
import { useInView } from 'react-hook-inview';
import { useSpring, a } from '@react-spring/web';

export default function BlogListItem({ post }) {
	const [ref, inView] = useInView();

	const props = useSpring({ opacity: inView ? 1 : 0 });

	return (
		<div className={styles.item} ref={ref}>
			<Link to={`/blog/${post.slug}`}>
				<div className={styles['image-crop']}>
					{inView && (
						<a.div
							className={styles.image}
							style={{ ...props, backgroundImage: `url(${post.titleImage})` }}
						/>
					)}
				</div>
				<div className={styles['item-info']}>
					<div className={styles.meta}>
						<span className={styles['meta-date']}>{post.date}</span>{' '}
						<span className={styles['meta-author']}>by {post.author}</span>
					</div>
					<h2>{post.title}</h2>
				</div>
			</Link>
		</div>
	);
}
