import usePageStyles from '../usePageStyles';
import Overlay from '../../components/Overlay/Overlay';

export default function OverlayLayer() {
	const pageStyles = usePageStyles(2);

	return (
		<div style={{ ...pageStyles, pointerEvents: 'none' }}>
			<Overlay />
		</div>
	);
}
