import { useCallback, useMemo, useState, useEffect } from 'react';
import MainMenu from './MainMenu/MainMenu';
import MenuButton from './MenuButton';
import MENU_LIST from '../../data/menuData';
import styles from './Menu.module.scss';
import { useRecoilState, useSetRecoilState } from 'recoil';
import MainMenuStatusState from '../../states/MainMenuStatus';
import SubMenu from './SubMenu/SubMenu';
import useWindowSize from '../../common/utilities/useWindowSize';
import { useLocation } from 'react-router';
import LandscapeActiveState from '../../states/LandscapeActive';
import IsActiveSubMenuState from '../../states/isActiveSubMenu';
import { isHomePage } from '../../utilities/GlobalFunctions';
import { useMobileQuery, useTabletLargeQuery } from '../../common/utilities/MediaQuery';
import { useSpring, a } from '@react-spring/web';
import useInteraction from '../../common/utilities/useInteraction';
import useStore from '../../states/useStore';

function Menu() {
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const [mainMenuOpen, setMainMenuOpen] = useRecoilState(MainMenuStatusState);
	const [activeSubMenuItem, setActiveSubMenuItem] = useRecoilState(IsActiveSubMenuState);
	const { height: winHeight } = useWindowSize();
	const location = useLocation();
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const isTabletLarge = useTabletLargeQuery();
	const isMobile = useMobileQuery();
	const poi = useStore((state) => state.poi);

	const [{ x }, spring] = useSpring(() => ({
		x: isTabletLarge || poi.isOpen ? -261 : 0,
	}));

	const onHover = useCallback(
		(item) => {
			if (!isMobile) {
				if (item.hasSubMenu) {
					setActiveSubMenuItem(item);
					setSubMenuOpen(true);
				} else {
					setActiveSubMenuItem(null);
					setSubMenuOpen(false);
				}
			}
		},
		[setActiveSubMenuItem, isMobile]
	);

	const closeMenu = useCallback(() => {
		setMainMenuOpen(false);
		setActiveSubMenuItem(null);
		setSubMenuOpen(false);
		!isHomePage(location.pathname) && setLandscapeActive(false);
	}, [setMainMenuOpen, setActiveSubMenuItem, setSubMenuOpen, setLandscapeActive, location.pathname]);

	useEffect(() => {
		closeMenu();
		setLandscapeActive(false);
	}, [location.pathname, setLandscapeActive, closeMenu]);

	const onMouseLeaveMenuContainer = useCallback(() => {
		if (!isTabletLarge) {
			closeMenu();
		}
	}, [closeMenu, isTabletLarge]);

	const activeSubMenuLabel = activeSubMenuItem?.label ?? 'none';

	const menuStyle = useMemo(() => {
		if (mainMenuOpen) {
			return {
				pointerEvents: 'all',
			};
		} else {
			return {
				pointerEvents: 'none',
			};
		}
	}, [mainMenuOpen]);

	//Mobile
	useEffect(() => {
		if (isTabletLarge) {
			if (mainMenuOpen) {
				spring.start({
					x: 0,
				});
			} else {
				spring.start({
					x: -261,
				});
			}
			return;
		}

		spring.start({
			x: 0,
		});
	}, [isTabletLarge, mainMenuOpen, spring]);

	useEffect(() => {
		spring.start({
			x: poi.isOpen || isTabletLarge ? -261 : 0,
		});
	}, [poi.isOpen, spring, isTabletLarge]);

	const onPressMobile = useCallback(
		(item) => {
			if (item.hasSubMenu && isTabletLarge) {
				setActiveSubMenuItem(item);
				setSubMenuOpen(true);
			} else {
				setActiveSubMenuItem(null);
				setSubMenuOpen(false);
			}
		},
		[isTabletLarge, setActiveSubMenuItem]
	);

	const onPress = useCallback(() => {
		if (isTabletLarge) {
			closeMenu();
		}
	}, [closeMenu, isTabletLarge]);

	const { bind } = useInteraction({ onPress });

	return (
		<>
			{isTabletLarge ? (
				<MenuButton
					subMenuOpen={subMenuOpen}
					setSubMenuOpen={setSubMenuOpen}
					setActiveSubMenuItem={setActiveSubMenuItem}
					mainMenuOpen={mainMenuOpen || subMenuOpen}
					setMainMenuOpen={setMainMenuOpen}
				/>
			) : null}

			<div
				className={`${styles.Menu} ${activeSubMenuItem ? styles['active-submenu'] : null}`}
				onMouseLeave={onMouseLeaveMenuContainer}
				style={{ ...menuStyle, height: winHeight, width: 261 }}
			>
				<a.div style={{ x, width: '100%' }}>
					<MainMenu
						mainMenuOpen={mainMenuOpen || subMenuOpen}
						subMenuOpen={subMenuOpen}
						activeSubMenu={activeSubMenuLabel}
						setMainMenuOpen={setMainMenuOpen}
						menuList={MENU_LIST}
						onHover={onHover}
						onPressMobile={onPressMobile}
					/>
				</a.div>
				<SubMenu activeSubMenuItem={activeSubMenuItem} />
			</div>

			{isTabletLarge && mainMenuOpen ? <div className={styles.mobileOverlay} {...bind()}></div> : null}
		</>
	);
}

export default Menu;
