import React from 'react';
import styles from './ProcessBlogPost.module.scss';

/**
 *
 * @param {String} input
 * @returns {Object} HTML sanitized input for use with dangerouslySetInnerHTML
 */
function ProcessFormattedInput(input) {
	return {
		__html: input,
	};
}

function processTextSection(section, index) {
	return (
		<React.Fragment key={index}>
			{section.title ? (
				<h3 id={section.id} className={styles['detail-body-title']}>
					{section.title}
				</h3>
			) : null}
			{section.body
				? section.body.map((bodyItem, idx) => {
						if (typeof bodyItem === 'string') {
							return (
								<p
									key={idx}
									className={styles['detail-body-text']}
									dangerouslySetInnerHTML={ProcessFormattedInput(bodyItem)}
								></p>
							);
						}

						if (typeof bodyItem === 'object') {
							return (
								<React.Fragment key={idx}>
									{bodyItem.subtitle ? (
										<h4
											className={styles['detail-body-subtitle']}
											dangerouslySetInnerHTML={ProcessFormattedInput(bodyItem.subtitle)}
										></h4>
									) : null}
									{bodyItem.infobox ? (
										<div
											className={styles['detail-body-infobox']}
											dangerouslySetInnerHTML={ProcessFormattedInput(bodyItem.infobox)}
										></div>
									) : null}
									{bodyItem.body ? (
										<p
											className={styles['detail-body-text']}
											dangerouslySetInnerHTML={ProcessFormattedInput(bodyItem.body)}
										></p>
									) : null}
								</React.Fragment>
							);
						}

						return null;
				  })
				: null}
		</React.Fragment>
	);
}

function processListSection(section, index) {
	return (
		<React.Fragment key={index}>
			{section.title ? (
				<p
					className={`${styles['detail-body-text']} ${
						section.className ? styles[section.className] : ''
					}`}
					dangerouslySetInnerHTML={ProcessFormattedInput(section.title)}
				></p>
			) : null}
			<ul
				className={`${styles['detail-body-text']} ${section.className ? styles[section.className] : ''}`}
			>
				{section.body
					? section.body.map((bodyItem, idx) => {
							if (typeof bodyItem === 'string') {
								return <li key={idx} dangerouslySetInnerHTML={ProcessFormattedInput(bodyItem)}></li>;
							}

							if (typeof bodyItem === 'object') {
								if (bodyItem.type) {
									return processSection(bodyItem, `${index}-${idx}`);
								}
							}

							return null;
					  })
					: null}
			</ul>
		</React.Fragment>
	);
}

function processSection(section, index) {
	if (section.hidden) {
		return null;
	}

	if (section.type === 'text') {
		return processTextSection(section, index);
	}

	if (section.type === 'list') {
		return processListSection(section, index);
	}

	// if (section.type === 'contents') {
	// 	return processContentsSection(section, index);
	// }

	return null;
}

function getBodySections(sections) {
	let sectionMarkup = [];

	sections.forEach((section, index) => {
		sectionMarkup.push(processSection(section, index));
	});

	return sectionMarkup;
}

/**
 *
 * @param {Object} jsonData JSON data loaded from a file or database
 * @returns {Object} Object containing JSX markup or strings for all section data
 */
export function ProcessBlogPost(jsonData) {
	if (jsonData) {
		let retVal = jsonData;

		let year = parseInt(jsonData.date.substring(0, 4), 10);
		let month = parseInt(jsonData.date.substring(5, 7), 10);
		let day = parseInt(jsonData.date.substring(8, 10), 10);

		retVal.date = new Date(year, month - 1, day);

		// retVal.date = new Date(jsonData.date);
		retVal.content = getBodySections(jsonData.content);

		return retVal;
	} else {
		return {
			title: 'Post not found.',
			author: 'Administrator',
			date: new Date(),
			content: ["We're sorry, that post does not exist."],
		};
	}
}

/**
 *
 * @param {Object} jsonData JSON data loaded from a file or database
 * @returns {Object} Object containing JSX markup or strings for all section data
 */
// export function ProcessSectionData(jsonData) {
// 	let retData = {};
// 	let pages = jsonData.pages;
// 	let pageFields = jsonData.pageFields;

// 	pages.forEach((page) => {
// 		retData[page] = {};

// 		// copy normal fields
// 		pageFields.forEach((field) => {
// 			if (jsonData[page][field]) {
// 				retData[page][field] = jsonData[page][field];
// 			}
// 		});

// 		// process section fields and return appropriate markup
// 		if (jsonData[page].sections) {
// 			retData[page].sections = getBodySections(jsonData[page].sections);
// 		}

// 		if (jsonData[page].contacts) {
// 			retData[page].contacts = getContacts(jsonData[page].contacts);
// 		}

// 		if (jsonData[page].bottomLink) {
// 			retData[page].bottomLink = getBottomLink(jsonData[page].bottomLink);
// 		} else {
// 			//Default
// 			retData[page].bottomLink = getBottomLink(defaultBottomLink);
// 		}

// 		if (jsonData[page].pageIntro) {
// 			retData[page].pageIntro = ProcessFormattedInput(jsonData[page].pageIntro);
// 		}
// 	});

// 	retData.urls = jsonData.urls;

// 	return retData;
// }
