import styles from './List.module.scss';

export function ListTitle({ children, className }) {
	return (
		<p className={`${styles['list-title']} ${className ? styles[className] : ''}`}>{children}</p>
	);
}

export function Item({children}) {
	return <li>{children}</li>;
}

export function List({ children, className }) {
	return (
		<ul
			className={`${styles['list']} ${className ? styles[className] : ''}`}
		>
			{children}
		</ul>
	);
}