import { FAQ_PATH } from '../../data/pathConstants';
import { Item, List, ListTitle } from '../List/List';
import Paragraph from '../Text/Paragraph';
import usatLogo from '../../media/usat-logo.svg';
import usatBg from '../../media/page-bgs/usat.jpg';

export const UsatData = {
	id: 'usat',
	url: FAQ_PATH,
	pageBg: usatBg,
	pageTitle: 'USAT State Championships',
	pageSummary: 'Herbalife24 Triathlon LA is proud to host the 2022 USA Triathlon California State Championships.',
	pageIcon: '/assets/icons/faq.svg',
	metaKeywords: 'Herbalife24 Triathlon LA is proud to host the 2022 USA Triathlon California State Championships.',
	metaDescription: 'Herbalife24 Triathlon LA is proud to host the 2022 USA Triathlon California State Championships.',
};

export default function Usat() {
	return (
		<>
			<img src={usatLogo} alt="USAT State Champsionship Logo" style={{ padding: 30, maxWidth: 600 }} />
			<Paragraph>
				Herbalife24 Triathlon LA is proud to host the 2022 USA Triathlon California State Championships.
			</Paragraph>
			<Paragraph>
				The Event serves as a qualifying event for the Toyota USA Triathlon Age Group National Championships for the
				Olympic and Sprint distances. The qualifying standards are posted at www.usatriathlon.org.{' '}
			</Paragraph>
			<Paragraph>
				USAT will provide unique medals to the top three overall finishers for both male and female in these divisions:
				Olympic distance, Sprint distance, and High School.
			</Paragraph>

			<ListTitle>Qualifying Details:</ListTitle>

			<Paragraph>USA Triathlon State Championship Categories Include:</Paragraph>

			<List>
				<Item>Age group races (Olympic and Sprint) </Item>
				<Item>Clydesdale and Athena </Item>
				<Item>High School</Item>
				<Item>Clubs</Item>
				<List>
					<Item>
						Athletes from any state are eligible for state championship awards. Athletes may reside in a different state
						and still win awards for this state championship.{' '}
					</Item>
					<Item>
						The High School division is only the sprint-distance triathlon. The only high school divisions are male and
						female. Athletes will not be split by age or high school class. The High School Relay division is a mixed
						relay, meaning there must be both genders on one team.
					</Item>
					<Item>
						High School athletes may compete in the high school division if they are currently enrolled in high school
						and up to a period of six (6) months post high school graduation. High School athletes do not have to be a
						member of a high school club to be eligible.{' '}
					</Item>
					<Item>An athlete’s race age is the age of the athlete on December 31, 2022</Item>
					<Item>
						Overall champions cannot be removed from their respective age group. They must remain in their respective
						5-year age group to be eligible for age group awards.{' '}
					</Item>
				</List>
			</List>
		</>
	);
}
