import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import styles from './SharpSpringForm.module.scss';
import Spinner from '../../common/icons/spinners/SquareSpinner';
import { a, useTransition } from '@react-spring/web';

export default function SharpSpringForm({ id, formID, domain, button }) {
	const ssConfigScriptTag = useRef(null);
	const ssScriptTag = useRef(null);
	const scriptsLoaded = useRef(false);

	const [visible, setVisible] = useState(button ? false : true);
	const [spinner, setSpinner] = useState(true);

	const loadScripts = useCallback(() => {
		ssScriptTag.current = document.createElement('script');
		ssScriptTag.current.src = 'https://koi-3QN859UOU8.marketingautomation.services/client/form.js?ver=2.0.1';

		ssConfigScriptTag.current = document.createElement('script');
		const ssConfigScript = `
			var ss_form = {'account': 'MzawMDE1MzW1AAA', 'formID': '${formID}'};
			ss_form.width = '100%';
			ss_form.height = '300';
			ss_form.domain = '${domain}';
			// ss_form.hidden = {'field_id': 'value'}; // Modify this for sending hidden variables, or overriding values
			ss_form.target_id = '${id}'; // Optional parameter: forms will be placed inside the element with the specified id
			ss_form.polling = true; // Optional parameter: set to true ONLY if your page loads dynamically and the id needs to be polled continually.
		`;

		ssConfigScriptTag.current.innerHTML = ssConfigScript;

		scriptsLoaded.current = true;
		setSpinner(false);
		document.body.appendChild(ssConfigScriptTag.current);
		document.body.appendChild(ssScriptTag.current);
	}, [domain, formID, id]);

	const unloadScripts = useCallback(() => {
		document.body.removeChild(ssConfigScriptTag.current);
		document.body.removeChild(ssScriptTag.current);
		scriptsLoaded.current = false;
		setSpinner(true);
	}, []);

	useEffect(() => {
		if (!button) {
			const intervalId = setInterval(() => {
				if (document.readyState === 'complete') {
					clearInterval(intervalId);
					loadScripts();
				}
			}, 100);
		}

		return () => {
			if (scriptsLoaded.current) {
				unloadScripts();
			}
		};
	}, [button, loadScripts, unloadScripts]);
	
	const onClick = useCallback(() => {
		setVisible((previousVisible) => {
			const newVisible = !previousVisible;

			if (newVisible) {
				loadScripts();
			} else {
				unloadScripts();
			}

			return !previousVisible;
		});
	}, [loadScripts, unloadScripts]);

	const form = <div id={id} className={styles['sharp-spring-form']}></div>;
	const buttonElem = (
		<Button addWidth={200} label={button} action={onClick} gatrack={true} fbq="ShowCorporateReferralForm" />
	);

	const transitions = useTransition(spinner, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	});

	return (
		<div className={styles.form}>
			{
				transitions((transitionStyles, spinner) => (
					spinner ? <a.div className={styles.spinner} styles={{...transitionStyles}}><Spinner /></a.div> : ''
				))
			}
			{visible ? form : buttonElem}
		</div>
	);
}
