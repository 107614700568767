import { useFrame } from '@react-three/fiber';
import { useRef, useState } from 'react';
import * as THREE from 'three';
import { useTabletQuery } from '../../common/utilities/MediaQuery';

export default function Rig({ children }) {
	const ref = useRef();
	const [euler] = useState(() => new THREE.Euler());
	const [quat] = useState(() => new THREE.Quaternion());
	const isTablet = useTabletQuery();

	useFrame(({ camera, mouse, size }) => {
		camera.zoom = size.width / 1700;
		camera.updateProjectionMatrix();
		if (ref.current) {
			euler.set(mouse.y / 10, 0, mouse.x / 10);
			ref.current.quaternion.slerp(quat.setFromEuler(euler), 0.1);
		}
	});

	return (
		<group ref={ref} position={[isTablet ? 0 : 2, 0, -1]}>
			{children}
		</group>
	);
}
