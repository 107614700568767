import { POINT_TO_POINT_PATH } from '../../data/pathConstants';
import { Item, List, ListTitle } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import pointToPointBg from '../../media/page-bgs/point_to_point_bg.jpg';

export const PointToPointData = {
	id: 'pointToPoint',
	url: POINT_TO_POINT_PATH,
	pageBg: pointToPointBg,
	pageTitle: 'Point to Point Explained',
	pageSummary: 'What to expect on Race Day!',
	pageIcon: '/assets/icons/icon_p2p.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, Venice Beach, LA Live, triathlon relay',
	metaDescription: 'Learn about our unique point to point experience.',
};

export default function PointToPoint() {
	return (
		<>
			<Subtitle>
				<a href="/assets/point-to-point/point-to-point-explained.pdf" target="_blank" rel="noreferrer">
					PDF Download
				</a>
			</Subtitle>

			<Paragraph>
				This urban triathlon provides a unique point to point experience for our participants. The course is
				one-of-a-kind, but requires all participants follow specific directions to ensure a seamless and enjoyable
				experience.
			</Paragraph>
			<Paragraph>
				Don’t worry. Detailed packet pick-up and race day directions are below and we’ll be sending out additional
				information as we get closer to the event. We also have a handy graphic that shows what goes in each bag and how
				they are transported so you can get all your gear after the event.{' '}
			</Paragraph>
			<Paragraph>
				Any further questions can be directed to <a href="mailto:info@herbalife24tri.la\">info@herbalife24tri.la</a>.
			</Paragraph>

			<Title>Packet Pick-Up</Title>

			<ListTitle>At Packet Pick Up participants will receive the following:</ListTitle>
			<List>
				<Item>Event T-Shirt</Item>
				<Item>Swim Cap</Item>
				<Item>Race Chip</Item>
				<Item>Transition Bag Instructions.</Item>
				<Item>(2) White plastic bags (18”x20”x4” each):</Item>
				<List>
					<Item>1 Bag for T1 Bag (to carry bike gear to event and then use for wet clothes / wetsuit after swim)</Item>
					<Item>1 Bag for T2 (running gear / fuel)</Item>
				</List>
			</List>

			<Paragraph>
				<a href="/assets/point-to-point/h24trila-bag-info.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/point-to-point/h24trila-bag-info.png" alt="" />
				</a>
			</Paragraph>

			<List>
				<Item>Helmet Sticker, Bike Sticker, Gear Bag Identification Stickers</Item>
				<Item>Race Bib and Safety Pins</Item>
				<Item>Wristband</Item>
			</List>

			<Subtitle>Important Notes:</Subtitle>

			<List>
				<Item>
					<b>All participants must have their ID to pick up their packet.</b>
				</Item>
				<Item>
					<b>You will not be allowed to pick up anyone else’s packet.</b>
				</Item>
				<Item>
					<b>Relay Team members are required to pick up their individual packets.</b>
				</Item>
				<Item>
					<b>Team members cannot pick up other team members packets.</b>
				</Item>
				<Item>
					Once your race packet is received place identification stickers on each bag. It is recommended that you also
					label your bags in black sharpie with your event number.
				</Item>
				<Item>
					<b>
						All participants MUST bring their T2 gear (all items needed for the run course) to Packet Pick Up and place
						ALL run course gear (shoes, hat, sunglasses, socks, etc) into their T2 Bag.
					</b>
				</Item>
				<Item>
					<b>
						Drop off your T2 bag at the T2 Gear Check area located on Chick Hearn Ct. between Figueroa and Georgia St
						(between LA Live and Crypto.com Arena). T2 Bags must be dropped between 11:00am - 5:00pm on the day of
						Packet Pick-up. T2 is located just one block from the packet pick up location and there will be volunteers
						to help guide you to T2.
					</b>
				</Item>
				<Item>
					<b>DO NOT TAKE YOUR T2 BAG HOME WITH YOU.</b>
				</Item>
				<Item>There is NO EVENT DAY PACKET PICKUP.</Item>
				<Item>Shuttle Information:</Item>
				<List>
					<Item>
						Relay team members (swimmers) will be provided courtesy shuttle to the Finish Line after their swim is over.
					</Item>
					<Item>
						Spectators may take shuttle from the Start Line to Finish Line with purchase of a shuttle wristband for $10
						for unlimited rides.
					</Item>
					<Item>
						Wristbands can be purchased at the Packet Pickup Merchandise Area on Saturday, or the Info Tent at the Start
						Line Sunday, or Merchandise Tent at the Finish Line Sunday.
					</Item>
				</List>
			</List>

			<Title>Race Day</Title>

			<Subtitle>Your Race Morning Schedule</Subtitle>

			<List>
				<Item>
					<u>Parking:</u>
				</Item>
				<List>
					<Item>
						Parking is located at both the Start Line (Venice Beach) and Finish Line (LA Live), but it is the
						participant’s responsibility to get to the Start Line with their Swim Gear, Bicycle and Bike Gear.{' '}
						<i>
							There will be no participant shuttles from the Finish Line to the Start Line prior to the event start.
							There is ample parking located within walking and cycling distance at the Start Line.
						</i>{' '}
						<a
							href="/assets/course-map/pdfs/herbalife24-tri-la-venice-parking-map.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Click here for a parking map.
						</a>
					</Item>
				</List>
				<Item>
					<u>Transition 1 Process</u> -{' '}
					<i>T1 is located at the Venice Beach Parking Lot located at Venice Blvd. and Ocean Front Walk.</i>
				</Item>
				<List>
					<Item>
						Set up your transition 1 area at the Venice Beach Parking Lot (T1) at your designated rack location between
						4:30am and 5:45am.
					</Item>
					<Item>
						After setting up your bike transition area and changing into wetsuit and/or swim attire, put any dry clothes
						and items into your provided T1 Bag.
					</Item>
					<List>
						<Item>
							Upon completion of the Swim, put ALL swim gear in the T1 Bag and LEAVE at your transition rack location.{' '}
							<i>
								The race team will collect all transition bags and deliver to the Finish Line.{' '}
								<b>All items must be placed in your T1 Bag.</b> No items outside of these bags will be collected. All
								items not in bags will be placed in Lost and Found and may not be able to be retrieved on event day
							</i>
							.
						</Item>
						<Item>Depart on your bike.</Item>
					</List>
				</List>
				<Item>
					<u>Transition 2 Process</u> - <i>T2 is located on Chick Hearn Ct. directly adjacent to Finish Line.</i>
				</Item>
				<List>
					<Item>Dismount your Bike.</Item>
					<Item>
						Hand your bike (no shoes or helmet) to volunteers and continue through the Transition Area to the T2 Bag
						Pick Up area.
					</Item>
					<Item>Pick Up your T2 Bag in the correct T2 Corral (where you placed your bag on Saturday).</Item>
					<Item>Head to the appropriate changing tent marked ‘Male’ or ‘Female’.</Item>
					<Item>Change clothes and gear. Stuff all cycling gear into your T2 Bag.</Item>
					<Item>
						Drop your newly stuffed T2 bag at T2 Bag Drop Off when you exit the Changing Tent.{' '}
						<i>
							This gear will be picked up after the race. T2 Bags and Bike will be picked up from inside T2; you must
							have your bib to remove your T2 Bag and Bike. Security will verify Bib Number matches your T2 Bag Number
							and Bike Sticker Number.
						</i>
					</Item>
					<Item>Go Run!</Item>
				</List>
				<Item>
					<u>After the Race</u>
				</Item>
				<List>
					<Item>Pick up your T1 Bag @ the T1 Bag claim area</Item>
					<Item>Pick up your T2 Bag @ the T2 Bag claim area (within T2)</Item>
					<Item>Pick up your Bike @ the T2 Bike claim area (within T2)</Item>
				</List>
			</List>

			<Subtitle>Transportation options to return to Start Line/Venice Beach</Subtitle>

			<List>
				<Item>Event Shuttles, located on West Rd. behind the JW Marriott.</Item>
				<List>
					<Item>
						Event shuttles are available to transport people back to T1. Athletes will take their bikes onto the shuttle
						bus with them. Please load your bike in the row in front or beside you. NO e-bikes will be permitted on the
						shuttle buses.
					</Item>
					<Item>Shuttles are complimentary for participants with their event wristband.</Item>
					<Item>
						Shuttles are available for spectators with purchase of a shuttle wristband for $10 for unlimited rides.
						Wristbands can be purchased at the Merchandise Tent within the Finish Festival area.
					</Item>
				</List>
				<Item>
					Take Metro (bikes are allowed) for $3.50 + $1.00 TAP card. Metro Trip information can be found{' '}
					<a href="https://www.metro.net/riding/trip-planner/" target="_blank" rel="noreferrer">
						HERE
					</a>
					.
				</Item>
				<Item>Pick up by family / friend or local rideshare company.</Item>
			</List>

			<Subtitle>
				<a href="/assets/point-to-point/point-to-point-explained.pdf" target="_blank" rel="noreferrer">
					PDF Download
				</a>
			</Subtitle>
		</>
	);
}
