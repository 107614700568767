import { VIRTUAL_CHALLENGE_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';
import { Item, List, ListTitle } from '../List/List';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';
import Button from '../Button/Button';
import Logo from '../../media/virtual-logo.svg';

import pageBg from '../../media/page-bgs/virtual_page.jpg';

export const VirtualChallengeData = {
	id: 'VirtualChallenge',
	url: VIRTUAL_CHALLENGE_PATH,
	pageBg: pageBg,
	pageTitle: 'Virtual Challenge',
	pageHeader: <img src={Logo} alt="Virtual Challenge" width={600} />,
	// pageSummary: 'Join us for a triathlon virtually from absolutely anywhere in the world.',
	pageIcon: '/assets/icons/training.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, ian murray, sprint triathlon training plan',
	metaDescription:
		'Whether this is your first triathlon or you’re a seasoned pro, we have a training plan perfectly suited for you!',
};

export default function VirtualChallenge() {
	return (
		<>
			<Button
				label="Sign Up"
				href="https://www.myvirtualmission.com/missions/144808/herbalife24-virtual-triathlon-la"
			/>

			<Subtitle>
				Download a visual on boarding guide{' '}
				<a href={`${process.env.PUBLIC_URL}/assets/2022-virtual-on-boarding-document.pdf`}>here</a>.
			</Subtitle>

			<Title>Introduction - Participant Information</Title>
			<Subtitle>Herbalife24 Virtual Triathlon fitness challenge platform information for participants</Subtitle>

			<Paragraph>
				We love L.A. It’s a town with Hollywood stars, rolling hillsides, neon sunsets over Venice Beach. But we know
				for some people, it’s just not possible to be in L.A. on May 15 for our race. If you can get there, amazing. We
				can’t wait for you to experience the exhilaration of race day. If you can’t make it, don’t worry. There’s still
				a way for you to join us and experience the actual international distance triathlon route… virtually from
				absolutely anywhere in the world.
			</Paragraph>

			<Paragraph>
				So, how does it work? As you bike, swim and run wherever it is you are on the globe, you’ll advance along our
				course. Let’s say, for instance, you’re in Osaka, Japan and you complete a five-kilometer run. Awesome! When
				your tracking app is connected to the virtual site, our site will automatically move your marker along the Los
				Angeles triathlon route.
			</Paragraph>

			<Paragraph>
				We will be using the My Virtual Mission platform to assist in the tracking of everyone’s performance.
			</Paragraph>

			<Paragraph>
				Distances are posted to the mission via various fitness apps and trackers that are connected to the My Virtual
				Mission app.
			</Paragraph>

			<Paragraph>
				Since this is a triathlon, only swimming, cycling, or running/walking will be tracked, however, you can decide
				whether you do all three disciplines or just those you’re most comfortable doing. For example, walking or
				running 1,500 meters (.9 mile) at the start will still move you along the swim portion of the triathlon route.
				The site also allows you to check the current weather in L.A. and follow yourself on Google Streetview as you
				run the race. Plus, as you advance along the course, you’ll have the opportunity to learn about points of
				interest in our slice of Southern California and you can even track your progress against other participants,
				which makes this a real race.
			</Paragraph>

			<ListTitle>How to join the Herbalife24 Virtual Triathlon Mission</ListTitle>
			<List>
				<Item>
					Visit the{' '}
					<a
						href="https://www.myvirtualmission.com/missions/144808/herbalife24-virtual-triathlon-la"
						target="_blank"
						rel="noreferrer"
					>
						mission link
					</a>{' '}
					on your mobile phone or desktop computer
				</Item>
				<Item>Click the ‘JOIN’ button</Item>
				<Item>
					Enter the ‘mission code’ <span style={{ fontWeight: 700 }}>GO 2022</span> and click ‘REQUEST TO JOIN’ button
				</Item>
				<Item>Download the My Virtual Mission app for Apple or Android</Item>
			</List>

			<ListTitle>How to Participate</ListTitle>
			<List>
				<Item>
					The Herbalife24 Virtual Triathlon LA will initially be in an un-launched state when you join, meaning you
					can’t yet post distances to it until April 1, 2022 when it will officially launch.
				</Item>
				<Item>
					At this point you may wish to install the My Virtual Mission app onto your mobile device. You can search for
					the My Virtual Mission app in the App Store and Play Store and log in with your email and password.
				</Item>
				<Item>
					Before the mission is launched you can connect a fitness app or device via ‘My Connections’ on the My Virtual
					Mission app. These third party services will automatically post distances towards your mission, either after
					your workout, or at various times throughout the day.
				</Item>
				<Item>
					Make sure you only choose one connection to avoid duplicate distances coming through to the mission. If
					required, distances can be deleted by clicking the small ‘x’ next to the distance on the mission newsfeed.
				</Item>
				<Item>
					Distances can be manually posted to the mission via the ‘progress’ section on the app, or just beneath the map
					on desktop/mobile web view.
				</Item>
			</List>

			<Title>Map and Streetview Information</Title>
			<Paragraph>The map shows your progress along the mission path</Paragraph>
			<Paragraph>
				Each time you add a distance, your marker will move along the mission pathway. The green flags are milestones
				that provides additional information once you reach those points. You can zoom in and view the Streetview to
				checkout your current virtual surrounds (if available at your virtual location).
			</Paragraph>

			<Title>The Leaderboard Information</Title>
			<ListTitle>The leaderboard is very dynamic. Here are some things you can do with it.</ListTitle>
			<List>
				<Item>You can view the leaderboard to show weekly, monthly and “All time” stats.</Item>
				<Item>
					If you are in a team, you can click the “+” icon below the team name to expand the view and show the
					individuals within the team.
				</Item>
				<Item>You can set the leaderboard to show individuals or teams or everyone.</Item>
				<Item>
					You can filter the leaderboard to only include specific exercise types. For example, you might want to see
					your ranking on the leaderboard based on “cycling” distances only. This eliminates other distances such as
					running or swimming from the leaderboard results.
				</Item>
			</List>

			<Title>Mission Information Recap</Title>
			<Paragraph>
				Link to Mission:{' '}
				<a
					href="https://www.myvirtualmission.com/missions/144808/herbalife24-virtual-triathlon-la"
					target="_blank"
					rel="noreferrer"
				>
					https://www.myvirtualmission.com/missions/144808/herbalife24-virtual-triathlon-la
				</a>
			</Paragraph>

			<Paragraph>Mission Code: GO 2022</Paragraph>
			<Paragraph>Start Date: April 1, 2022</Paragraph>
			<Paragraph>Completion Date: May 15, 2022</Paragraph>
			<Paragraph>Types of exercise allowed: Swimming, cycling, running/walking</Paragraph>
			<Paragraph>
				Administrator: <a href="mailto:virtual@herbalife24tri.la\">virtual@herbalife24tri.la</a>
			</Paragraph>

			<Title>Ongoing Support</Title>
			<Paragraph>
				My Virtual Mission (MVM) has a comprehensive knowledge base which should be your first port of call if you
				require support.
			</Paragraph>
			<Paragraph>
				MVM has a 24/7 multilingual support team who are available to assist with any technical queries. If you need
				technical support during your mission, you can contact MVM directly at{' '}
				<a href="mailto:mvmsupport@myvirtualmission.com\">mvmsupport@myvirtualmission.com</a>
			</Paragraph>
			<Paragraph>You can also access it via the MVM App via the Main Menu, then “Support”.</Paragraph>
			<Paragraph>
				For other questions related to the mission, please e-mail{' '}
				<a href="mailto:virtual@herbalife24tri.la\">virtual@herbalife24tri.la</a>
			</Paragraph>
		</>
	);
}
