import { BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.scss';
import { Helmet } from 'react-helmet';
import Pages from './pages/Pages';
import useScrollFixiOS from './utilities/useScrollFixiOS';
import useResetMenu from './utilities/useResetMenu';
import usePreloadLogo from './utilities/usePreloadLogo';
import Snackbar from './components/Snackbar/Snackbar';

function App() {
	useScrollFixiOS();
	useResetMenu();
	usePreloadLogo();

	return (
		<Router>
			<div className={styles.App}>
				<Helmet>
					<title>Herbalife24 Triathlon Los Angeles</title>
					<meta
						name="description"
						content="Register for the Herbalife24 Triathlon Los Angeles on October 24th, 2021. Starting in Venice Beach and ending in Downtown Los Angeles at L.A. Live, join us for a one-of-a-kind journey through the heart of L.A."
					/>
					<meta
						name="keywords"
						content="Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, Herbalife tri, Los Angeles tri, LA tri, H24 tri, H24 triathlon, Herbalife24, LA Live, down town Los Angeles"
					/>
					<meta property="og:title" content="Herbalife24 Triathlon Los Angeles" />
					<meta
						property="og:description"
						content="Register for the Herbalife24 Triathlon Los Angeles on October 24th, 2021. Starting in Venice Beach and ending in Downtown Los Angeles at L.A. Live, join us for a one-of-a-kind journey through the heart of L.A."
					/>
				</Helmet>
				<Pages />
				<Snackbar />
			</div>
			{/* This is used for the photo gallery */}
			<div id="modal-root"></div>
		</Router>
	);
}

export default App;
