import Home, { HomeData } from '../components/Pages/Home';
import About, { AboutData } from '../components/Pages/About';
import Rules, { RulesData } from '../components/Pages/Rules';
import Faq, { FaqData } from '../components/Pages/Faq';
import CorporateChallenge, { CorporateChallengeData } from '../components/Pages/CorporateChallenge';
import LaTriClub, { LaTriClubData } from '../components/Pages/LaTriClub';
import PTOProAm, { PTOProAmData } from '../components/Pages/PTOProAm';
import TrainingPlan, { TrainingPlanData } from '../components/Pages/TrainingPlan';
import VirtualChallenge, { VirtualChallengeData } from '../components/Pages/VirtualChallenge';
import PointToPoint, { PointToPointData } from '../components/Pages/PointToPoint';
import WeekendSchedule, { WeekendScheduleData } from '../components/Pages/WeekendSchedule';
import Usat, { UsatData } from '../components/Pages/Usat';
import Expo, { ExpoData } from '../components/Pages/Expo';
import RoadClosure, { RoadClosureData } from '../components/Pages/RoadClosure';
import Elites, { ElitesData } from '../components/Pages/Elites';
import Jdrf, { JdrfData } from '../components/Pages/Jdrf';
import CourseDescription, { CourseDescriptionData } from '../components/Pages/CourseDescription';
import AmbassadorProgram, { AmbassadorProgramData } from '../components/Pages/AmbassadorProgram';
import Events5kRun, { Events5kRunData } from '../components/Pages/Events5kRun';
import EventsBikeTour, { EventsBikeTourData } from '../components/Pages/EventsBikeTour';
import EventsTriathlonAquabike, { EventsTriathlonAquabikeData } from '../components/Pages/EventsTriathlonAquabike';
import Events5kVirtual, { Events5kVirtualData } from '../components/Pages/Events5kVirtual';
import { MapData } from '../components/Pages/Map';
import MapDetails, { MapDetailsData } from '../components/Pages/MapDetails';
import SportsNutrition, { SportsNutritionData } from '../components/Pages/SportsNutrition';
import ContactPage, { ContactData } from '../components/Pages/Contact';
import PhotoGallery, { PhotoGalleryData } from '../components/Pages/PhotoGallery';
import {
	ABOUT_PATH,
	AMBASSADOR_PROGRAM_PATH,
	CONTACT_PATH,
	CORPORATE_CHALLENGE_PATH,
	COURSE_DESCRIPTION_PATH,
	ELITES_PATH,
	EVENTS_5K_RUN_PATH,
	EVENTS_5K_VIRTUAL_PATH,
	EVENTS_BIKE_TOUR_PATH,
	EVENTS_TRIATHLON_AQUABIKE_PATH,
	EXPO_PATH,
	FAQ_PATH,
	HOME_PATH,
	JDRF_PATH,
	LA_TRI_CLUB_PATH,
	MAP_DETAILS_PATH,
	MAP_PATH,
	PHOTO_GALLERY_PATH,
	POINT_TO_POINT_PATH,
	ROAD_CLOSURE_PATH,
	RULES_PATH,
	SPORTS_NUTRITION_PATH,
	TRAINING_PLAN_PATH,
	WEEKEND_SCHEDULE_PATH,
	USAT_PATH,
	PTO_PRO_AM_PATH,
	VIRTUAL_CHALLENGE_PATH,
} from './pathConstants';

export const REGISTER_URL =
	'https://endurancecui.active.com/new/events/79056833/select-race?error=login_required&state=74114c1f-5727-4869-88fe-f0e0c74762ad&_p=09954940872939089';
export const REGISTER_LABEL = 'Register';

export const RESULTS_2019_URL = 'https://www.athlinks.com/event/315514/results/Event/858488/Results';
export const RESULTS_2021_URL = 'https://www.athlinks.com/event/315514/results/Event/989647/Results';
export const RESULTS_2022_URL = 'https://www.athlinks.com/event/315514/results/Event/1013355/Results';
export const PHOTOS_2022_URL = 'https://freephotos.finisherpix.com/gallery/100971/';

export const VIRTUAL_CHALLENGE_URL =
	'https://www.myvirtualmission.com/missions/125869/2021-herbalife24-virtual-triathlon-la-presented-by-filuet';

export const PAGES = {
	[HOME_PATH]: {
		content: Home,
		data: HomeData,
	},
	[ABOUT_PATH]: {
		content: About,
		data: AboutData,
	},
	[RULES_PATH]: {
		content: Rules,
		data: RulesData,
	},
	[FAQ_PATH]: {
		content: Faq,
		data: FaqData,
	},
	[CORPORATE_CHALLENGE_PATH]: {
		content: CorporateChallenge,
		data: CorporateChallengeData,
	},
	[LA_TRI_CLUB_PATH]: {
		content: LaTriClub,
		data: LaTriClubData,
	},
	[TRAINING_PLAN_PATH]: {
		content: TrainingPlan,
		data: TrainingPlanData,
	},
	[POINT_TO_POINT_PATH]: {
		content: PointToPoint,
		data: PointToPointData,
	},
	[WEEKEND_SCHEDULE_PATH]: {
		content: WeekendSchedule,
		data: WeekendScheduleData,
	},
	[USAT_PATH]: {
		content: Usat,
		data: UsatData,
	},
	[VIRTUAL_CHALLENGE_PATH]: {
		content: VirtualChallenge,
		data: VirtualChallengeData,
	},
	[EXPO_PATH]: {
		content: Expo,
		data: ExpoData,
	},
	[ROAD_CLOSURE_PATH]: {
		content: RoadClosure,
		data: RoadClosureData,
	},
	[ELITES_PATH]: {
		content: Elites,
		data: ElitesData,
	},
	[JDRF_PATH]: {
		content: Jdrf,
		data: JdrfData,
	},
	[COURSE_DESCRIPTION_PATH]: {
		content: CourseDescription,
		data: CourseDescriptionData,
	},
	[AMBASSADOR_PROGRAM_PATH]: {
		content: AmbassadorProgram,
		data: AmbassadorProgramData,
	},
	[EVENTS_5K_RUN_PATH]: {
		content: Events5kRun,
		data: Events5kRunData,
	},
	[EVENTS_BIKE_TOUR_PATH]: {
		content: EventsBikeTour,
		data: EventsBikeTourData,
	},
	[EVENTS_TRIATHLON_AQUABIKE_PATH]: {
		content: EventsTriathlonAquabike,
		data: EventsTriathlonAquabikeData,
	},
	[EVENTS_5K_VIRTUAL_PATH]: {
		content: Events5kVirtual,
		data: Events5kVirtualData,
	},
	[MAP_PATH]: {
		content: null,
		data: MapData,
	},
	[MAP_DETAILS_PATH]: {
		content: MapDetails,
		data: MapDetailsData,
	},
	[PTO_PRO_AM_PATH]: {
		content: PTOProAm,
		data: PTOProAmData,
	},
	[SPORTS_NUTRITION_PATH]: {
		content: SportsNutrition,
		data: SportsNutritionData,
	},
	[CONTACT_PATH]: {
		content: ContactPage,
		data: ContactData,
	},
	[PHOTO_GALLERY_PATH]: {
		content: PhotoGallery,
		data: PhotoGalleryData,
	},
};
