import useInteraction from '../../utilities/useInteraction';
import useWindowSize from '../../utilities/useWindowSize';
import { noop } from '../../utilities/utilities';
import styles from './OverlayBase.module.scss';
import classNames from 'classnames';
import useStyleProps from '../../utilities/useStyleProps';

export default function OverlayBase({
	onPress = noop,
	fullScreen = false,
	className = null,
	isSilent = false,
	children,
	...otherProps
}) {
	const styleProps = useStyleProps(otherProps);

	const { height: winHeight, width: winWidth } = useWindowSize();
	const { bind } = useInteraction({
		onPress: onPress,
	});

	const overlayClassNames = classNames(styles.OverlayBase, {
		[className]: className,
		[styles['is-silent']]: isSilent,
	});

	return (
		<div
			{...bind()}
			className={overlayClassNames}
			style={{
				...styleProps,
				width: fullScreen ? winWidth : '100%',
				height: fullScreen ? winHeight : '100%',
			}}
		>
			{children}
		</div>
	);
}
