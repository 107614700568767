import { TRAINING_PLAN_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';
import { Item, List, ListTitle } from '../List/List';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import trainingPlanBg from '../../media/page-bgs/training_plan_bg.jpg';

export const TrainingPlanData = {
	id: 'trainingPlan',
	url: TRAINING_PLAN_PATH,
	pageBg: trainingPlanBg,
	pageTitle: 'Training Plans',
	pageSummary:
		'Whether this is your first triathlon or you’re a seasoned pro, we have a training plan perfectly suited for you!',
	pageIcon: '/assets/icons/training.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, ian murray, sprint triathlon training plan',
	metaDescription:
		'Whether this is your first triathlon or you’re a seasoned pro, we have a training plan perfectly suited for you!',
};

export default function TrainingPlan() {
	return (
		<>
			<Title>Three Free Training Clinics</Title>

			<Paragraph>
				The Herbalife24 Triathlon race clinics will offer an opportunity to review the course, ask questions, learn
				about triathlon best practices, and get to know the triathlete community. These clinics are free but you must
				RSVP to attend. USAT membership is also required, which can be purchased when you RSVP.{' '}
			</Paragraph>

			<Paragraph>RSVP here: </Paragraph>
			<Paragraph>
				<a
					href="https://endurancecui.active.com/new/events/80932234/select-race?_p=06047303852549779"
					target="_blank"
					rel="noreferrer"
				>
					https://endurancecui.active.com/new/events/80932234/select-race?_p=06047303852549779
				</a>
			</Paragraph>

			<Subtitle>Run Clinic</Subtitle>
			<Paragraph>Date: April 9, 2022</Paragraph>
			<Paragraph>Time: 7 a.m. - 8:30 a.m.</Paragraph>
			<Paragraph>Where: L.A. Live (739 Chick Hearn Ct, Los Angeles, CA, 90015)</Paragraph>
			<Paragraph>
				About: USAT Certified Coach Deb Carabet will lead this run on the exact run course from “T2” (the bike to run
				transition area) – for both the sprint distance course (5k), and the Olympic distance course (10k). This run is
				intended to be a course inspection, so participants can get to know every turn and hill. Athletes are welcome to
				run at their own pace as long as they are aware of the route. We will be on open roads with traffic and expect
				all participants to obey traffic laws.
			</Paragraph>

			<Subtitle>Bike Clinic</Subtitle>
			<Paragraph>Date: April 23, 2022 </Paragraph>
			<Paragraph>Time: 6:30 a.m. - 10:00 a.m.</Paragraph>
			<Paragraph>Where: Venice Beach (2100 Ocean Front Walk, Los Angeles, CA, 90291)</Paragraph>
			<Paragraph>
				About: USAT Certified Coach Deb Carabet will lead this bike ride on the exact bike course from the mount line to
				the dismount line – for both the sprint distance course (15 miles), and the Olympic distance course (25 miles).
				This ride is intended to be a course inspection, so participants can get to know every turn and every rise. It
				will be ridden at an easy pace to keep all levels of riders together. We will be on open roads with traffic and
				expect all participants to obey traffic laws.
			</Paragraph>
			<Paragraph>
				Please bring a bicycle in good working order and a helmet that fits. Also bring a flat tire kit with a
				proper-sized tube for your bike, tire levers, and some sort of inflation device. Even if you don’t know how to
				change a flat, bring the bits that will allow one of our coaches the “teachable moment” in case of a puncture. A
				water bottle and some snacks might be nice too.{' '}
			</Paragraph>
			<Paragraph>
				Keep in mind, this is a point-to-point ride that will take us from Venice Beach to Downtown L.A. Please consider
				how you’ll manage your return to home or car after arriving downtown. The Pico stop of the Expo Line is just
				four blocks from L.A. Live (the finish of our ride) and for $1.75, you and your bike (if space is available,
				which is usually the case on Sunday mornings back to the Santa Monica Pier. A three-mile, flat ride from the
				tram terminus will get you back to the Venice Beach parking area where we started our ride.
			</Paragraph>

			<Subtitle>Open Water Swim Clinic</Subtitle>
			<Paragraph>Date: May 7, 2022</Paragraph>
			<Paragraph>Time: 7:00 a.m. - 9:00 a.m.</Paragraph>
			<Paragraph>Where: Venice Beach (2100 Ocean Front Walk, Los Angeles, CA, 90291)</Paragraph>
			<Paragraph>
				About: The swim clinic includes a dry-land discussion with all participants regarding the exact location of swim
				start, transition area, and bike mount line. There will also be a discussion of the swim course, buoy placement,
				and how the water conditions on race day and the lifeguards requirements will determine the exact shape of the
				course. <a href="https://www.latriclub.com/about/leadership/7-ian-murray">Ian Murray</a> will also lead
				participants through a practice of ocean swim skills needed to navigate through the surf and some ins-and-outs
				practice to hone those skills.
			</Paragraph>
			<Paragraph>
				Everyone is welcome to participate in the dry-land discussion. To participate in the in-water portion of this
				clinic one must be able to comfortably swim 300m in a pool, without stopping.
			</Paragraph>
			<Paragraph>
				Please bring a swim cap, swim goggles, towel, and a swimming wetsuit if you have one and will be using one on
				race day. If you plan on racing in a tri suit, jammers, or a brief then bring that item and let’s test it out.
			</Paragraph>

			<Title>Women’s Training Program</Title>
			<Paragraph>
				The Elevate Women 4Tri’s Herbalife24 Triathlon, Los Angeles Training program is designed to encourage
				participation for women learning the sport of triathlon and paratriathlon. The 2022 women’s Training program
				will start on March 21st with a kick-off and culminate on race day morning on May 15th.
			</Paragraph>
			<Paragraph>
				The program is open to women of all ages, who have registered for the race and helps build knowledge about
				triathlons, paratriathlon and multisport events. The training program focuses on the basics including a
				skills-building curriculum introducing not only swimming, cycling, and running, but also nutrition, bike
				maintenance, course preview and race day prep.{' '}
			</Paragraph>
			<Paragraph>
				Participants also get great discounts from sponsors to the LA Tri Club and a special discount for the
				Herbalife24 Triathlon. Participants bond at Elevate Women 4Tri exclusive social events and by racing in our own
				race and training apparel. It’s a unique chance to gain confidence while becoming part of an incredible
				triathlon community. And... there is post-race get together at a fun location to celebrate crossing the finish
				line of your first race!
			</Paragraph>
			<Paragraph>
				The training program is led by Elevate Women 4Tri Founder and USAT Certified Level ll and USAT Certified Levell
				Paratriathlon Coach Deb Carabet.
			</Paragraph>

			<ListTitle>Cost for the 8-week program:</ListTitle>
			<List>
				<Item>$375 for LA Tri Club or TriFit members</Item>
				<Item>$400 for non-members </Item>
				<Item>Can pay in two payments</Item>
			</List>

			<Paragraph>
				To sign up and find out more information reach out to Coach Deb at:{' '}
				<a href="Elevatewomen4tri@gmail.com" target="_blank" rel="noreferrer">
					Elevatewomen4tri@gmail.com
				</a>
			</Paragraph>
		</>
	);
}
