import React from 'react';
import styles from './Policy.module.scss';

function Policy() {
	return (
		<div className={styles.Policy}>
			<a
				className={styles.label}
				href={process.env.PUBLIC_URL + '/assets/privacy-policy.pdf'}
				rel="noopener noreferrer"
				target="_blank"
			>
				Policy
			</a>
		</div>
	);
}

export default Policy;
