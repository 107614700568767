import { useEffect } from 'react';
import useWindowSize from '../../common/utilities/useWindowSize';
import styles from './BlogPostBackground.module.scss';
import useStore from '../../states/useStore';
import { useSpring } from '@react-spring/web';

export default function BlogBackground() {
	const { width, height } = useWindowSize();

	const [{ yHeader }] = useSpring(() => ({
		yHeader: 0,
		config: { duration: 1 },
	}));
	const [{ yBody }] = useSpring(() => ({
		yBody: 0,
		config: { duration: 1 },
	}));

	useEffect(() => {
		return useStore.subscribe(
			(scrollY) => {
				yBody.start(-Math.min(scrollY, height));
				yHeader.start(-Math.min(scrollY, height) / 2);
			},
			(state) => state.scrollY
		);
	}, [yBody, yHeader, height]);

	return (
		<div className={styles.BlogPostBackground} style={{ width, height }}>
			{/* <a.div className={styles.header} style={{ width, height, y: yHeader }}>
				<div className={styles.img} style={{ backgroundImage: `url(${CurrentBlogPost.titleImage})` }} />
				<div className={styles.gradient} />
			</a.div>
			<a.div className={styles.body} style={{ width, height, y: yBody }}></a.div> */}
		</div>
	);
}
