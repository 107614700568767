import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import styles from './Lottie.module.scss';

export default function Lottie({ name, active, data }) {
	const containerRef = useRef();

	useEffect(() => {
		lottie.loadAnimation({
			name,
			container: containerRef.current,
			renderer: 'canvas',
			loop: true,
			autoplay: false,
			animationData: data,
		});

		return () => {
			lottie.destroy(name);
		};
	}, [name, data]);

	useEffect(() => {
		if (active) {
			lottie.play(name);
		} else {
			lottie.pause(name);
		}
	}, [name, active]);

	return <div className={styles.lottie} ref={containerRef}></div>;
}
