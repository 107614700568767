import { FAQ_PATH } from '../../data/pathConstants';
import { Item, List, ListTitle } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import faqBg from '../../media/page-bgs/faq_bg.jpg';

export const FaqData = {
	id: 'faq',
	url: FAQ_PATH,
	pageBg: faqBg,
	pageTitle: 'FAQ',
	pageSummary: 'Below you will find frequently asked questions and answers to help you along your journey.',
	pageIcon: '/assets/icons/faq.svg',
	metaKeywords:
		'Herbalife 24, FAQ, frequently asked questions, bike repair, point to point, point-to-point, cut off times, times, aid stations, flat and fast course, registration policies, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles',
	metaDescription:
		'Frequently Asked Questions for Herbalife24 Triathlon Los Angeles 2021. Questions and answers to help you along your journey.',
};

export default function Faq() {
	return (
		<>
			<Title>Registration Policies</Title>

			<ListTitle>Herbalife24 Triathlon Los Angeles Transfer Policy</ListTitle>
			<List>
				<Item>
					Transfer Policy –There is a $20 transfer fee for participants that want to complete a person-to-person
					transfer up to two weeks prior to the event.
				</Item>
				<Item>Participants can only complete a transfer if the registrant is 18 years or older.</Item>
				<Item>Registrations cannot be deferred to the following year.</Item>
				<Item>Please log into your ACTIVE.COM account and select “My Events” to make any changes.</Item>
			</List>

			<ListTitle>Herbalife24 Triathlon Los Angeles Refund Policy</ListTitle>
			<List>
				<Item>
					Herbalife24 Triathlon Los Angeles does not authorize refunds at any time (except in the case of Natural
					Disaster — see below). Insurance can be purchased via Active.com during your registration.
				</Item>
				<Item>
					Herbalife24 Triathlon Los Angeles does have a strict No Show Policy – If you are unable to attend the event
					and/or do not show up for the event, your entry spot and race items will be released. Included registration
					items such as medals, t-shirts, and hats will not be mailed to participants who do not participate in the
					event they signed up for. Please review the transfer policy above if you are interested in transferring your
					registration to a different event or person.
				</Item>
				<Item>
					Race Conduct –We will not tolerate any inappropriate behavior to any of our volunteers or staff. If you have a
					complaint please direct it to info@herbalife24tri.la. Failure to follow this guideline will result in
					disqualification from the event and possibly being banned from future events. Seriously. We’re all out here
					for some fun, so let’s have some!
				</Item>
			</List>

			<ListTitle>
				Herbalife24 Triathlon Los Angeles Natural Disaster Policy –If an Act of God/Terrorism/Community Disaster
				(including pandemic) occurs, what happens?
			</ListTitle>
			<List>
				<Item>
					<b>
						Herbalife24 Triathlon Los Angeles is proud to provide full refunds to participants when an event is
						postponed or canceled due to an Act of God/Terrorism/Community Disaster (see full policy below).
					</b>
				</Item>
				<Item>We make decisions based on safety first.</Item>
				<Item>
					Race courses and race date will be altered as seen fit by race management, the City of Los Angeles and other
					governing agencies.
				</Item>
				<Item>Participants in a postponed event will be transferred to the rescheduled date at no charge.</Item>
				<Item>
					Participants who choose not to participate in the rescheduled event date are eligible for a full refund.
					Participants must request a refund, via email to info@herbalife24tri.la, within 30 days of the posted
					announcement of a rescheduled event. Emails received after this date will not be eligible for a refund.
				</Item>
			</List>

			<ListTitle>Where do I get my packet/bib number?</ListTitle>
			<List>
				<Item>
					The pre-race Expo is on Saturday May 14, 2022 from 11:00 am to 5:00 pm. Please check back for website updates
					or follow us on social media. If you registered for the event, an email will be sent once additional Expo info
					is available
				</Item>
				<Item>
					<b>
						PRE-EVENT PACKET PICKUP IS REQUIRED FOR TRIATHLETES. THERE WILL BE NO EVENT DAY PACKET PICKUP FOR
						TRIATHLETES.
					</b>
				</Item>
				<List>
					<Item>5K runners have the option of picking up their packet on race day.</Item>
				</List>
			</List>

			<Title>Bike Repair?</Title>
			<Paragraph>
				There will be bike support/repair during race weekend at the Event Expo on Saturday and on Race Day, located at
				Transition Area 1 (T1).
			</Paragraph>

			<Title>Can I ride an e-bike?</Title>
			<Paragraph>
				Yes, you can ride an electric bicycle (e-bike) at the Herbalife24 LA Bike Tour, but some restrictions do apply.
				Only pedal assist (Class 1 and Class 3) e-bikes are allowed; throttle assist e-bikes are not allowed. You are
				responsible for making sure your battery will last long enough, and you will be staged in the back at the start
				of the ride.
			</Paragraph>

			<Title>Registration Changes</Title>
			<Paragraph>Please log into your ACTIVE.COM account and select “My Events” to make any changes.</Paragraph>

			<Title>Point to Point Course Logistics</Title>
			<Paragraph>
				At Packet Pickup, you will be given 3 white plastic bags - a Morning Bag, a Transition Area 1 (T1) Bag and a
				Transition Area 2 (T2) Bag. You will also be given 3 luggage tags to be wrapped around the handle for the
				appropriate bag. It is recommended that you also write your number on each bag in black sharpie.
			</Paragraph>

			<Subtitle>Point to Point Logistics - Morning Bag</Subtitle>
			<Paragraph>
				This year we are combining Morning Bags and T1 Bags, so there will be only (2) bags in total: (1) one T1 Bag and
				(1) one T2 Bag. Please follow the instructions below to understand what does in each bag and where to drop off
				and pick up.
			</Paragraph>

			<Subtitle>Point to Point Logistics - T1 Bag</Subtitle>
			<Paragraph>
				Your T1 Bag will be brought with you to T1 in Venice Beach (the swim start) and contain all items needed for the
				swim (Wetsuit, Goggles, Swim Cap) and bike course (Bike Shoes, Helmet, Bib, Bike Shorts, Sunglasses, etc). Place
				your T1 Bag and Bicycle at your designated rack between 4:30am and 5:45am inside T1. Once you change into your
				wetsuit and associated swim attire, place all clothes and items brought to Venice Beach into your Morning Bag
				(Shirt, Pants, Sweatshirt, etc). After the Swim, and you change into your Bike Course Gear, place all Swim Gear
				into your T1 Bag at your designated rack. Leave T1 Bag at your rack and it will be collected (from your rack) by
				Race Staff and transported to the Finish Line for pickup after your race.
			</Paragraph>
			<Paragraph>
				Please note: All items must be within this bag to be transported. Items not in your T1 Bag will not be
				transported and placed in Lost and Found.
			</Paragraph>

			<Subtitle>Point to Point Logistics - T2 Bag</Subtitle>
			<Paragraph>
				Your T2 Bag will be filled with all items needed for the Run Course (Running Shoes, Socks, Shirt, Sunscreen,
				etc). This bag will be packed on Saturday when you pickup your Race Packet. After placing all your Run Course
				items into your T2 Bag, you will take your T2 Bag to T2 (at LA Live) where it will be kept overnight and be
				given to you after you finish the Bike Course.
			</Paragraph>
			<Paragraph>Please note: Do NOT take your T2 Bag and Running attire home with you on Saturday.</Paragraph>

			<Subtitle>Point to Point Logistics - Bike Transport</Subtitle>
			<Paragraph>
				Complimentary Bike Transport will be available and will take your bike from T2 back to T1 after the event. All
				bikes must be given to Bike Transport by 1:00pm and collected from T1 by 2:30pm. All bikes not collected will be
				placed in the lost and found.
			</Paragraph>

			<Title>Pick up someone else's packet?</Title>
			<Paragraph>
				No, each participant must pick up their own packet and present a valid ID card, and USAT member card (if
				applicable). <b>NO EXCEPTIONS</b>.
			</Paragraph>

			<Title>When does transition open and close?</Title>
			<Paragraph>
				Transition Area 1 opens at 4:30am and closes at 5:45am. At this time, all gear will be transported to the Gear
				Check Area in DTLA.
			</Paragraph>
			<Paragraph>Transition Area 2 closes at 1:30pm. All gear must be removed by that time.</Paragraph>

			<Title>Are there course cut off times?</Title>
			<Paragraph>
				Yes, due the size and scope of the event (in addition to our famous LA traffic and city resources) there are
				mandatory cut off times. If you are notified by staff that you are past the cut off time on the bike or run, you
				can continue your event, BUT you must follow the rules of the road.
			</Paragraph>

			<ListTitle>
				<b>Olympic Course</b>
			</ListTitle>
			<List>
				<Item>Swim: 7:55 AM</Item>
				<Item>Bike: 10:00 AM</Item>
				<List>
					<Item>Athletes must exit Transition 1 (after swim) by 8:00 AM</Item>
					<Item>Athletes must pass I-405 (mile 3.5) by 8:15 AM</Item>
					<Item>Athletes must pass Olympic Blvd. / La Brea (mile 6) by 8:30 AM</Item>
					<Item>Athletes must pass Figueroa / Venice Blvd (mile 18) by 9:30 AM</Item>
				</List>
				<Item>Run: 12:00 PM</Item>
			</List>
			<ListTitle>
				<b>Sprint Triathlon</b>
			</ListTitle>
			<List>
				<Item>Swim: 7:55 AM</Item>
				<Item>Bike: 10:00 AM</Item>
				<List>
					<Item>Athletes must exit Transition 1 (after swim) by 8:00 AM</Item>
					<Item>Athletes must pass I-405 (mile 3.5) by 8:15 AM</Item>
					<Item>Athletes must pass Olympic Blvd. / La Brea (mile 6) by 8:30 AM</Item>
					<Item>Athletes must pass Figueroa / Venice Blvd (mile 18) by 9:30 AM</Item>
				</List>
				<Item>Run: 12:00 PM</Item>
			</List>

			<Title>How do I get timed?</Title>
			<Paragraph>
				All participants receive a timing chip to be worn on their right ankle. This timing chip records your time as
				you pass over various timing mats.
			</Paragraph>

			<Title>Do I have to wear a wetsuit?</Title>
			<Paragraph>
				Wetsuits are not required, but most people choose to race in them. Water temperatures vary between 61-68 degrees
				Fahrenheit during May.
			</Paragraph>

			<Title>Are recumbent bikes allowed?</Title>
			<Paragraph>
				Unfortunately due to USA Triathlon rules, recumbent bikes are not permitted to be used by participants.
			</Paragraph>

			<Title>Where are the aid stations?</Title>
			<Paragraph>
				There will be (1) aid station at the Swim Finish. There will be (1) aid station on the Olympic Bike Course.
				There will be aid stations located approximately every mile on the run course.
			</Paragraph>

			<Title>Is this a fast course?</Title>
			<Paragraph>Yes, this tour through the heart of LA has minimal hills and turns. This is a fast course!</Paragraph>

			<Title>Where can I find photos?</Title>
			<Paragraph>Photos will be posted on the event website and Facebook following the event.</Paragraph>

			<Title>Can I sign up the day of the event?</Title>
			<Paragraph>Only the Herbalife24 DTLA 5k Run/Walk will be open for race day registration.</Paragraph>

			<Title>If the event is sold out, is there a wait list?</Title>
			<Paragraph>No. If the event is sold out, we will not be taking any other entries.</Paragraph>

			<Title>I can bike and run, but I’m nervous about swimming in the ocean.</Title>
			<Paragraph>
				Herbalife and LA Tri Club will be hosting swim clinics prior to race day. Stay tuned for more dates.
			</Paragraph>

			<Title>Where do I park?</Title>
			<Paragraph>
				On race day there are a variety of options for getting to the race start in Venice Beach (pricing varies).
			</Paragraph>
			<List>
				<Item>
					<b>North City Parking Lot:</b> 300 Ocean Front Walk, Venice, Ca. 90291. (End of Rose Ave; Open at 4am).
				</Item>
				<Item>
					<b>Washington Blvd. Lot:</b> 3100 Washington Boulevard, Venice, Ca 90291 (Near Venice Pier and Washington
					Square).
				</Item>
				<Item>
					<b>Pacific Ave. Lot:</b> 2108 Pacific Ave, Venice, CA 90291 in the LA City 731 Lot. Parking at the 731 Lot
					opens at 5am for parking but participants can drop-off here starting at 4am.
				</Item>
				<Item>
					<b>Drop-off location:</b> For all dropoffs from friends, family or rideshare go to LA City 731 Lot at 2108
					Pacific Ave, Venice, CA 90291. (Starting at 4am)
				</Item>
				<Item>
					There will be shuttles and bike transport from the finish line back to Venice Beach. These shuttles are for
					post-event only.
				</Item>
			</List>

			<Title>Where and when do the shuttles run?</Title>
			<List>
				<Item>
					Participant and Spectator Shuttles will begin running from Venice to LA Live at 5:30am and the last shuttle
					will depart at 7:30am.
				</Item>
				<Item>
					Participant and Spectator Shuttles/Bike Transport Shuttles will begin running from LA Live back to Venice at
					9:30am and the last shuttle will depart at 1:30pm.
				</Item>
				<Item>Bikes transported from LA Live back to the Start Line need to be picked up by 2:30pm in Venice.</Item>
				<Item>
					Shuttles at the Start Line in Venice will be located adjacent to T1 at 2100 Ocean Front Walk, Venice, CA
				</Item>
				<Item>Shuttles at the Finish Line will be located on West Rd, behind the JW Marriott.</Item>
				<Item>
					All Shuttles are free for participants with event wristband (given in your event packet) and spectators who
					purchase a wristband at either the INFO Tent in Venice, Merchandise at LA Live or Merchandise at Packet Pickup
					(Saturday, May 14th from 11am-5pm) for $10 for unlimited rides.
				</Item>
			</List>

			<Title>Where can my friends watch the event?</Title>
			<Paragraph>Your friends can watch from many places:</Paragraph>
			<List>
				<Item>Swim Start / Transition 1</Item>
				<Item>Mar Vista Farmers Market (watch bikers pass)</Item>
				<Item>Koreatown</Item>
				<Item>LA Live</Item>
				<Item>Facebook Live</Item>
				<Item>Race Updates (*users will need to opt-in)</Item>
			</List>

			<Title>Relay FAQs</Title>

			<Subtitle>What is a Relay Team?</Subtitle>
			<Paragraph>
				A Relay Team is a group of individuals (2-3) whom each perform 1-2 disciplines (Swim, Bike or Run) as a team to
				complete the event.
			</Paragraph>

			<Subtitle>Who Can be on a Relay Team?</Subtitle>
			<Paragraph>Relay Teams consist of a minimum of 2 individuals, either all Male, all Female or mixed.</Paragraph>

			<Subtitle>How do I register as a Relay Team?</Subtitle>
			<Paragraph>
				During the Registration Process, a team captain will sign up and select their desired Relay Team and enter the
				information of the other 1 or 2 members.
			</Paragraph>

			<Subtitle>Can I change Relay Team members after Registration?</Subtitle>
			<Paragraph>
				Please contact <a href="mailto:info@herbalife24tri.la">info@herbalife24tri.la</a>
			</Paragraph>

			<Subtitle>When do Relays Start the Swim?</Subtitle>
			<Paragraph>Relay Team will be grouped with other Relay Teams all started at a pre-determined time.</Paragraph>

			<Subtitle>How are Relay Teams Scored?</Subtitle>
			<Paragraph>
				The same way as individuals, but only against other Relay Teams either Male, Female or Mixed.
			</Paragraph>

			<Subtitle>Do all 3 members of a Relay Team need to be present to pick up our packet on May 14th?</Subtitle>
			<Paragraph>Yes, all team members need to be on site for packet pick up.</Paragraph>

			<Subtitle>How are Relay Teams Timed?</Subtitle>
			<Paragraph>
				All relay team members share the same timing chip and transfer the chip to the next team member in the
				transition area. At T1, the Bike Relay Team member will wait for their swimmer at their designated rack while at
				T2, the Run Relay Team member will wait in the T2 Relay Holding Area. There is a free shuttle for the Run Relay
				team member to get to T2. Please be mindful of other athletes in the Transition Areas while you are waiting for
				your Teammate to arrive or after you have completed your particular leg.
			</Paragraph>

			<Title>Relay Team Rules</Title>
			<List>
				<Item>
					All rules applying to individual competitors apply to relay competitors except where such rules conflict with
					specific relay rules.
				</Item>
				<Item>
					Relay teams must be composed of two or three participants- one swimmer, one cyclist and one runner. Team
					members can be either same gender or any combination of male/female. The minimum age of participants is 16
					years old, unless agreed beforehand by the race committee.
				</Item>
				<Item>
					The relay team ‘chip transfer’ area must be within the marked Relay Team Area in the Transition Area. There
					will be a Relay Team Referee overseeing the ‘chip transfer’ of each team member. If a ‘chip transfer’ happens
					outside of the Relay Team Area, the team will not be included in the official results.
				</Item>
				<Item>
					Any relay team interfering with an individual competitor or in any way placing an individual competitor at a
					competitive disadvantage will be disqualified.
				</Item>
			</List>

			<Subtitle>Swim</Subtitle>
			<List>
				<Item>Relay swimmers will start in their designated wave.</Item>
				<Item>
					All relay swimmers must wear the official race swim cap throughout the swim portion of the race. The swim cap
					must not be removed from the swimmer’s head until after the timing chip is passed to the team cyclist.
				</Item>
			</List>

			<Subtitle>Bike</Subtitle>
			<List>
				<Item>
					All relay bikes must be placed in the designated bike racks in the Transition Area. Team bikes must not be
					removed from the designated bike rack until AFTER the timing chip has been passed onto the team cyclist.
				</Item>
				<Item>
					After receiving the timing chip, the cyclist must WALK/RUN their bike through the Transition Area of the MOUNT
					LINE. At this time cyclists may mount their bike and depart on the bike leg.
				</Item>
				<Item>
					Upon arrival at the Transition Area, cyclists must dismount at the DISMOUNT LINE and WALK/RUN their bike back
					to the designated bike rack. Once the cyclist has entered the marked Team Relay Area, they may hand off the
					timing chip to the runner. Once the runner has attached the timing chip, he/she may depart on the run course.
				</Item>
			</List>
		</>
	);
}
