import React, { useState } from 'react';
import styles from './Snackbar.module.scss';

function Snackbar() {
	const [open, setOpen] = useState(true);

	return (
		<React.Fragment>
			{open ? (
				<div className={`${styles.Snackbar} ${styles.normal}`}>
					<div className={styles['snackbar-content']}>
						<div className={styles['snackbar-title']}>2023 Herbalife24 Triathlon LA Postponed.</div>
						<div className={styles['snackbar-copy']}>
						We've decided to postpone the 2023 Herbalife24 Triathlon and 5K Los Angeles. While we’re disappointed to not be holding this award-winning event this year, we hope to be back bigger and better than ever in the future! Meanwhile, keep an eye out for updates at <a href='https://www.herbalife24.tri.la' target='_blank' rel="noreferrer">www.herbalife24.tri.la</a>.
						</div>
					</div>
					<div className={styles['snackbar-dismiss-container']}>
						<button
							className={styles['snackbar-dismiss']}
							onClick={() => {
								setOpen(false);
							}}
						>
							Dismiss
						</button>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
}

export default Snackbar;
