import styles from './MapSideSheet.module.scss';
import { MAP_DATA } from '../../data/mapData';
import useStore from '../../states/useStore';
import { useTransition, a } from '@react-spring/web';
import Close from '../../common/icons/Close';
import { useCallback } from 'react';

export default function MapSideSheet() {
	const active = useStore((state) => state.poi.active);
	const isOpen = useStore((state) => state.poi.isOpen);
	const dispatchPoi = useStore((state) => state.dispatchPoi);

	const transitions = useTransition(isOpen, {
		from: { opacity: 0, y: 40 },
		enter: { opacity: 1, y: 0, pointerEvents: 'auto' },
		leave: { opacity: 0, y: 40, pointerEvents: 'none' },
		config: {
			mass: 2,
			tension: isOpen ? 180 : 210,
			friction: isOpen ? 12 : 26,
		},
	});

	const handleClose = useCallback(() => {
		dispatchPoi({ isOpen: false });
	}, [dispatchPoi]);

	return transitions((tranStyles, isOpen) => {
		if (isOpen) {
			return (
				<a.div className={styles.MapSideSheet} style={tranStyles}>
					<div className={styles.back} onClick={handleClose}>
						<Close size="L" />
					</div>
					<div className={styles.header}>
						<div className={styles.img} style={{ backgroundImage: `url('${MAP_DATA[active].img}')` }} />
						<div className={styles.title}>{MAP_DATA[active].title}</div>
						<div className={styles.gradient} />
					</div>
					<div className={styles.copy}>{MAP_DATA[active].copy}</div>
				</a.div>
			);
		} else {
			return null;
		}
	});
}
