import { LA_TRI_CLUB_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';

import laTriClubBg from '../../media/page-bgs/la_tri_club_bg.jpg';

export const LaTriClubData = {
	id: 'laTriClub',
	url: LA_TRI_CLUB_PATH,
	pageBg: laTriClubBg,
	pageTitle: 'LA Tri Club',
	pageSummary:
		'LA Tri Club is proud to be the host Triathlon Club for the Herbalife24 Triathlon Los Angeles. LA Tri Club and its members are very excited to have the Herbalife24 Triathlon Los Angeles right in its own backyard.',
	pageIcon: '/assets/icons/latri.svg',
	pageIconSize: 'full',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles',
	metaDescription:
		'Swim. Bike. Run. Play! LA Tri Club is proud to be the host Triathlon Club for the Herbalife24 Triathlon Los Angeles.',
};

export default function LaTriClub() {
	return (
		<>
			<Paragraph>
				The LA Tri Club’s mission is to provide a network of information, support services, training & racing
				activities, friendship and fun for all ages and abilities. LA Tri Club’s community of supportive and committed
				members help create extraordinary memories and lifelong friendships through competition, education,
				volunteering, and awesome social activities! LA Tri Club knows that our members have lives and responsibilities
				outside of triathlon. Being a member of LA Tri Club makes entry and continued longevity into the triathlon
				community easy, and staying the course fun!
			</Paragraph>
			<Paragraph>
				One of LA Tri Club’s biggest goals is building a strong community of triathletes and other endurance athletes
				linked together by a sense of camaraderie.
			</Paragraph>
			<Paragraph>
				<img src="/assets/latriclub-1.jpg" alt="" />
			</Paragraph>
			<Paragraph>
				Membership with the LA Tri Club gives you first-hand access to sport legends, USAT certified coaches, coached
				women specific training programs, group training, clinics/seminars, race & sponsor discounts, socials and LATC’s
				own rewards programs. From transition tips to shaving secrets, our members learn how to achieve success and
				truly enjoy leading a triathlete land endurance sports lifestyle.
			</Paragraph>

			<Paragraph>
				LA Tri Club continues to expand its membership base, affiliations with coaching leaders in the community, and
				local and national race leaders. LA Tri Club provides great support to members with quality resources, robust
				training events, and a strong race presence at many races.
			</Paragraph>

			<Paragraph>
				Today, LA Tri Club continues to promote the enjoyment of the Triathlon and Endurance Sport Lifestyle to a wider
				community and supports members of all skill-levels and abilities. LA Tri Club is proud of the value offered in
				membership and the over 10,000 athletes we have supported over the years.
			</Paragraph>
			<Paragraph>
				<img src="/assets/latriclub-2.jpg" alt="" />
			</Paragraph>
			<Paragraph>
				The spirit and life of the LA Tri Club resides in its members and their support for one another. LA Tri Club
				gives thanks to our members for over 20 amazing years of swimming, biking, running and playing! Come join LA Tri
				Club and make your Herbalife24 Triathlon, Los Angeles experience that much more.
			</Paragraph>

			<Paragraph>
				For all Herbalife24 Triathlon Los Angeles participants, LA Tri Club is offering a special membership discount
				for your first year. Use the discount code HERBTRI24 for a $25 off a year membership. Go to{' '}
				<a href="https://www.latriclub.com" target="_blank" rel="noreferrer">
					www.latriclub.com
				</a>{' '}
				for more information.
			</Paragraph>
		</>
	);
}
