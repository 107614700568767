import React from 'react';
import useIconColor from './useIconColor';
import useIconSize from './useIconSize';

export default function Close({ color = 'default', size = 'S' }) {
	const [width, height] = useIconSize(size);
	const fill = useIconColor(color);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 22 22"
		>
			<path
				d="M16.032,15.171l-.863.861a.732.732,0,0,1-1.036,0l0,0-3.365-3.367L7.4,16.032a.732.732,0,0,1-1.036,0l0,0-.86-.864a.732.732,0,0,1,0-1.036l0,0,3.366-3.364L5.5,7.4a.732.732,0,0,1,0-1.036l0,0L6.365,5.5A.732.732,0,0,1,7.4,5.5l0,0,3.364,3.368L14.133,5.5a.732.732,0,0,1,1.036,0l0,0,.863.863a.732.732,0,0,1,0,1.036l0,0-3.369,3.365,3.367,3.367a.732.732,0,0,1,0,1.036Z"
				transform="translate(0.221 0.221)"
				fill={fill}
			/>
		</svg>
	);
}
