import { EVENTS_5K_RUN_PATH } from '../../data/pathConstants';
import Infobox from '../Infobox/Infobox';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';
import Title from '../Text/Title';

import events5kRunBg from '../../media/page-bgs/events_5k_run_bg.jpg';

export const Events5kRunData = {
	id: 'events5kRun',
	url: EVENTS_5K_RUN_PATH,
	pageBg: events5kRunBg,
	pageTitle: 'Herbalife24 DTLA 5k Run/Walk',
	pageSummary: 'Run and Walk through Downtown LA.',
	pageIcon: '/assets/icons/map.svg',
	pageIntro:
		'Enjoy Downtown Los Angeles without traffic. Run and Walk along a closed course through DTLA and Koreatown.',
	metaKeywords:
		'Herbalife 24, Herbalife24 triathlon LA course description, Herbalife24 triathlon Los Angeles course description, Herbalife24 triathlon Los Angeles, Herbalife24 triathlon LA, venice beach, venice, marina del rey, helms bakery, midcity, koreatown, figueroa corridor, university park, L.A. Live, la live, macarthur park, downtown la, westlake',
	metaDescription:
		'Enjoy Downtown Los Angeles without traffic. Run and Walk along a closed course through DTLA and Koreatown.',
};

export default function Events5kRun() {
	return (
		<>
			<Title>Information</Title>
			<Infobox>
				<b>Distance:</b> 5k (3.1 miles)
			</Infobox>
			<Paragraph>
				The Herbalife24 DTLA 5k Run/Walk travels part of the triathlon run course - taking runners through Downtown LA
				and Koreatown before a spectacular finish at LA Live.
			</Paragraph>
			<Subtitle>Course Maps</Subtitle>
			<Paragraph>
				<a href="https://herbalife24tri.la/map-details">https://herbalife24tri.la/map-details</a>
			</Paragraph>
			<Title>Packet Pickup &amp; Registration</Title>
			<Subtitle>May 14</Subtitle>
			<Paragraph>
				Packet pick-up and Expo at the Los Angeles Convention Center Petree Hall C/D (11 am - 5 pm). 5K runners have the
				option of picking up their packet on race day, but we encourage you to do this ahead of time and avoid the rush
				on race day.
			</Paragraph>
			<Subtitle>May 15</Subtitle>
			<Paragraph>Registration opens at Xbox Plaza L.A. Live - 5:30am 5K race start - 6:30am</Paragraph>
		</>
	);
}
