export default function DateIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
			<g id="Date_icon" data-name="Date icon" transform="translate(0.497)">
				<rect
					id="Rectangle_190"
					data-name="Rectangle 190"
					width="22"
					height="22"
					rx="2"
					transform="translate(-0.497)"
					fill="#b1b1b1"
				/>
				<g id="Group_548" data-name="Group 548" transform="translate(4.5 3.75)">
					<g id="Group_547" data-name="Group 547">
						<path
							id="Path_554"
							data-name="Path 554"
							d="M9.375,2.5A6.875,6.875,0,1,0,16.25,9.375,6.881,6.881,0,0,0,9.375,2.5Zm0,12.375a5.5,5.5,0,1,1,5.5-5.5A5.507,5.507,0,0,1,9.375,14.875Z"
							transform="translate(-2.5 -2.5)"
							fill="#fff"
						/>
						<path
							id="Path_555"
							data-name="Path 555"
							d="M48.5,25.375l-2.012-.883v-3.1a.7.7,0,0,0-.695-.695.686.686,0,0,0-.695.695v3.546a.7.7,0,0,0,.405.637l2.417,1.057a.627.627,0,0,0,.275.058.719.719,0,0,0,.637-.405A.663.663,0,0,0,48.5,25.375Z"
							transform="translate(-38.934 -18.066)"
							fill="#fff"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
}
