import React from 'react';
import styles from '../HomeInfobox.module.scss';
export default function HomeBiketour() {
	return (
		<>
			<div className={styles['home-bullet']}>22 mile flat and fast course</div>
			<div className={styles['home-bullet']}>Free custom training and nutrition plans</div>
			<div className={styles['home-bullet']}>Experience Los Angeles with no traffic</div>
		</>
	);
}
