import { RULES_PATH } from '../../data/pathConstants';
import { Item, List } from '../List/List';
import Title from '../Text/Title';

import rulesBg from '../../media/page-bgs/rules_bg.jpg';

export const RulesData = {
	id: 'rules',
	url: RULES_PATH,
	pageTitle: 'Rules',
	pageSummary: 'The race rules can be found below. Please reach out via our contact page if any questions arise.',
	pageBg: rulesBg,
	// "pageIcon": "/assets/icons/rules.svg",
	metaKeywords: 'Herbalife 24, race rules, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles',
	metaDescription: 'Race rules for Herbalife24 Triathlon Los Angeles 2021.',
};

export default function Rules() {
	return (
		<>
			<Title>Herbalife24 Triathlon After Event Sweepstakes</Title>
			<List>
				<Item>Please click <a href="/assets/after-event-sweepstakes-rules.pdf" target="_blank">here</a> for the official rules: <a href="/assets/after-event-sweepstakes-rules.pdf" target="_blank">PDF Download</a></Item>
			</List>

			<Title>Race Rules</Title>
			<List>
				<Item>
				This event will be officiated with USAT rules. For a full list of rules, please visit:{' '}
					<a
						href="https://www.teamusa.org/USA-Triathlon/About/Multisport/Competitive-Rules"
						target="_blank"
						rel="noreferrer"
					>
						https://www.teamusa.org/USA-Triathlon/About/Multisport/Competitive-Rules
					</a>
				</Item>
			</List>

			<Title>Most Common Rules Violations</Title>
			<List>
				<Item>
					<b>Helmets:</b> Only helmets approved by the US Consumer Product Safety Commission (CPSC) may be used in USAT sanctioned events. Helmets must be worn at all times while on your bike. This means before, during, and after the event.<br />
					<b>Penalty:</b> Disqualification
				</Item>
				<Item>
					<b>Chin Straps:</b> Chin straps must be buckled at all times when on a bicycle. DO NOT unbuckle your chin strap unless you are off your bicycle.<br />
					<b>Penalty:</b> Disqualification on the course; Variable time penalty in transition area only.
				</Item>
				<Item>
					<b>Outside Assistance:</b> No assistance other than that offered by race and medical officials may be used. Triathlons and duathlons are individual tests of fitness.<br />
					<b>Penalty:</b> Variable time penalty
				</Item>
				<Item>
					<b>Transition Area:</b> All equipment must be placed in the properly designated and individually assigned bike corral. The wheel of the bicycle must be down on the side of the assigned space. All participants must return their bicycles to an upright position in their designated bicycle corral. No person shall interfere with another participant’s equipment or impede the progress of another participant. All bar ends must be solidly plugged. No participant shall bring ANY glass containers into the transition area. <br />
					<b>Penalty:</b> Variable time penalty
				</Item>
				<Item>
					<b>Drafting:</b> Drafting--keep at least three bike lengths of clear space between you and the cyclist in front. If you move into the zone, you must pass within 15 seconds. Position--keep to the right hand side of the lane of travel unless passing. Blocking--riding on the left side of the lane without passing anyone and interfering with other cyclists attempting to pass. Overtaken--once passed, you must immediately exit the draft zone from the rear, before attempting to pass again. <br />
					<b>Penalty:</b> Variable time penalty
				</Item>
				<Item>
					<b>Course:</b> All competitors are required to follow the prescribed course and to stay within all coned lanes. Cutting the course is an obvious violation and going outside the course is a safety issue. Cyclists shall not cross a solid yellow center line for ANY reason. Cyclists must obey all applicable traffic laws at all times. <br />
					<b>Penalty:</b> Referee's discretion
				</Item>
				<Item>
					<b>Unsportsmanlike-Like Conduct:</b> Foul, harsh, argumentative or abusive language or other unsportsmanlike conduct directed at race officials, USA Triathlon officials, volunteers, spectators or fellow athletes is forbidden. <br />
					<b>Penalty:</b> Disqualification
				</Item>
				<Item>
					<b>Headphones:</b> Headphones, headsets, walkmans, ipods, mp3 players, or personal audio devices, etc. are not to be carried or worn at any time during the race. <br />
					<b>Penalty:</b> Variable time penalty
				</Item>
				<Item>
					<b>Race numbers:</b> All athletes are required to wear race numbers at all times during the run. Numbers must face the front and be clearly visible at all times. Numbers may not be cut or folded or altered in any way. DO NOT transfer your number to any other athlete or take a number from an athlete that is not competing.<br />
					<b>Penalty:</b> Variable time penalty for missing or altered number, disqualification and one year suspension from membership in USAT for transferring a number without race director permission.
				</Item>
				<Item>
					<b>Wetsuits:</b> Each age group participant shall be permitted to wear a wetsuit without penalty in any event sanctioned by USA Triathlon up to and including a water temperature of 78 degrees Fahrenheit. When the water temperature is greater than 78 degrees but less than 84 degrees Fahrenheit, age group participants may wear a wetsuit at their own discretion, provided, however that participants who wears a wetsuit within such temperature range shall not be eligible for prizes or awards. Above 84 degrees, wetsuits are prohibited.
				</Item>
				<Item>
					<b>Abandonment:</b> All personal equipment and belongings taken out onto the course must stay on the athlete the entire time. No garbage, clothing, etc. shall be thrown on the course.<br />
					<b>Penalty:</b> Variable time penalty
				</Item>
			</List>
		</>
	);
}