import { useEffect, useState } from 'react';
import Axios from 'axios';

let cache = {};

export default function useBlogList() {
	const [blogList, setBlogList] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (cache['blog-list']) {
			setBlogList(cache['blog-list']);
			setLoading(false);
		} else {
			Axios.get(`${process.env.PUBLIC_URL}/blog.json?${Date.now()}`)
				.then((response) => {
					cache['blog-list'] = response.data.posts;
					setBlogList(response.data.posts);
					setLoading(false);
				})
				.catch((error) => {
					setBlogList([]);
					setLoading(false);
				});
			}
	}, []);

	return [blogList, loading];
}
