import { useRecoilValue } from 'recoil';
import LogoBlobState from '../../states/LogoBlobState';

export default function LogoLandscape() {
	const logo = useRecoilValue(LogoBlobState);
	return (
		<img
			src={logo.url}
			style={{ aspectRatio: '600/191', opacity: logo.isReady ? 1 : 0, transition: 'opacity .5s ease' }}
			alt="Herbalife Triathlon Los Angeles 2021 - October 24th"
		/>
	);
}
