import { useTransition, a } from '@react-spring/web';
import { useEffect, useState } from 'react';
import styles from './LogoBox.module.scss';

const LANDING_PAGE = ['5k', 'sprint', 'international', 'aquabike', 'biketour', 'proam'];

export default function LogoBox({ type = LANDING_PAGE }) {
	const [index, setIndex] = useState(LANDING_PAGE.indexOf(type[0]));
	const [count, setCount] = useState(0);
	const transition = useTransition(index, {
		initial: { opacity: 1 },
		from: { opacity: 0, blade1: -650, blade2: -650, blade3: -650, nameY: -100 },
		enter: {
			opacity: 1,
			blade1: 105,
			blade2: 35,
			blade3: -55,
			delay: (key) => {
				if (key === 'blade1') return 500;
				if (key === 'blade2') return 550;
				if (key === 'blade3') return 600;
				return 500;
			},
			nameY: 0,
		},
		leave: {
			opacity: 0,
			blade1: 600,
			blade2: 600,
			blade3: 600,
			nameY: 100,
			delay: (key) => {
				if (key === 'blade1') return 120;
				if (key === 'blade2') return 60;
				if (key === 'blade3') return 0;
				return 0;
			},
		},
	});

	useEffect(() => {
		if (!type || LANDING_PAGE.indexOf(type) === -1) {
			const interval = setInterval(() => setCount((count) => (count === (type.length - 1) ? 0 : ++count)), 4000);
			return () => clearInterval(interval);
		}
	}, [type]);

	useEffect(() => {
		setIndex(LANDING_PAGE.indexOf(type[count]));
	}, [type, setIndex, count]);

	const fragment = transition((tStyles, index) => {
		// 5K Run
		if (index === 0) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '55%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 254.92 36.11">
								<g style={{ isolation: 'isolate' }} fill="#fff">
									<path
										d="M0 37.88l6.52-7.57c3 2.42 5.85 3.83 8.73 3.83s4.45-1.46 4.45-3.38v-.1c0-2.07-1.67-3.38-4.14-3.38a8.32 8.32 0 00-5.41 2.22L3 26 4 7.58h25.55v9.34H12.83l-.25 4.6a12.13 12.13 0 016.71-1.92c5.86 0 11.82 3.74 11.82 11.16v.1c0 8.18-6.72 12.83-15.71 12.83A21.52 21.52 0 010 37.88zM42.12 7.58h11.82v13.23L64 7.58h13.73L65.41 22.93l12.59 20H63.89L57 31.72l-3 3.69v7.52H42.12zM128.9 7.58h17.27c6.26 0 10.25 1.56 12.73 4a11.11 11.11 0 013.23 8.42v.1c0 5.15-2.67 8.74-7 10.81l8.23 12h-13.45l-6.67-10.1h-2.52v10.1H128.9zm16.92 16.82c2.83 0 4.54-1.27 4.54-3.44v-.1c0-2.27-1.76-3.38-4.49-3.38h-5.15v6.92zM172.79 27.07V7.58h12v19.34c0 4.4 2.27 6.26 5.46 6.26s5.45-1.71 5.45-6V7.58h12v19.24c0 12.07-7 16.82-17.57 16.82s-17.34-4.85-17.34-16.57zM219.56 7.58h11.06l12.58 15.6V7.58h11.72v35.35h-10.51l-13.13-16.31v16.31h-11.72z"
										transform="translate(0 -7.58)"
									></path>
								</g>
							</svg>
						</a.div>
					</a.div>
					<a.div className={styles.blade1} style={{ x: tStyles.blade1, skewX: -55, backgroundColor: '#ace57a' }} />
					<a.div className={styles.blade2} style={{ x: tStyles.blade2, skewX: -55, backgroundColor: '#8acc52' }} />
					<a.div className={styles.blade3} style={{ x: tStyles.blade3, skewX: -55, backgroundColor: '#75b943' }} />
				</div>
			);
		}

		// Sprint
		if (index === 1) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '44%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.64 26.85">
								<g style={{ isolation: 'isolate' }} fill="#fff">
									<path d="M0 22.49 4.62 17a14.87 14.87 0 0 0 9.07 3c1.55 0 2.21-.41 2.21-1.11v-.07c0-.74-.81-1.15-3.58-1.71C6.51 15.94 1.41 14.28 1.41 8.8v-.07C1.41 3.81 5.25 0 12.39 0c5 0 8.69 1.18 11.69 3.55l-4.22 5.84a13.61 13.61 0 0 0-7.77-2.55c-1.29 0-1.88.45-1.88 1.08V8c0 .7.7 1.15 3.44 1.66 6.62 1.21 11.06 3.14 11.06 8.34v.08c0 5.43-4.48 8.76-11.43 8.76C8 26.85 3.29 25.37 0 22.49ZM31.89.48h11.65c6.88 0 11.57 3.07 11.57 9.21v.07c0 6.26-4.77 9.58-11.76 9.58h-2.81v7h-8.65Zm11 12.69c2.22 0 3.7-1 3.7-2.85v-.07c0-1.89-1.37-2.85-3.66-2.85h-2.39v5.77ZM62.14.48h12.65c4.59 0 7.51 1.15 9.32 3a8.11 8.11 0 0 1 2.37 6.14v.07a8.38 8.38 0 0 1-5.1 7.92l6 8.8h-9.85L72.65 19H70.8v7.4h-8.66ZM74.53 12.8c2.08 0 3.33-.93 3.33-2.52v-.07c0-1.67-1.29-2.48-3.29-2.48H70.8v5.07ZM93.84.48h8.66v25.89h-8.66ZM110.71.48h8.1L128 11.91V.48h8.58v25.89h-7.69l-9.62-12v12h-8.58ZM151.36 7.8h-7.62V.48h23.9V7.8H160v18.57h-8.66Z" />
								</g>
							</svg>
						</a.div>
					</a.div>
					<a.div className={styles.blade1} style={{ x: tStyles.blade1, skewX: -55, backgroundColor: '#80ddda' }} />
					<a.div className={styles.blade2} style={{ x: tStyles.blade2, skewX: -55, backgroundColor: '#6accca' }} />
					<a.div className={styles.blade3} style={{ x: tStyles.blade3, skewX: -55, backgroundColor: '#59bebd' }} />
				</div>
			);
		}

		// International
		if (index === 2) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '67%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303.27 21.58">
								<g style={{ isolation: 'isolate' }} fill="#fff">
									<path d="M0 .44h6.92v20.69H0ZM13.47.44H20l7.36 9.14V.44h6.85v20.69H28l-7.69-9.54v9.54h-6.84ZM46 6.3h-6.14V.44H59V6.3h-6.13v14.83H46ZM64.66.44H82.3v5.68H71.45v2.1h10.11v5H71.45v2.25h11v5.67H64.66ZM88.44.44h10.11c3.66 0 6 .92 7.45 2.37a6.5 6.5 0 0 1 1.89 4.9v.06a6.7 6.7 0 0 1-4.08 6.33l4.82 7h-7.89l-3.9-5.91h-1.48v5.91h-6.92Zm9.9 9.85c1.66 0 2.66-.74 2.66-2v-.07c0-1.33-1-2-2.63-2h-3v4ZM113.71.44h6.47l7.36 9.14V.44h6.86v20.69h-6.15l-7.69-9.54v9.54h-6.85ZM148.1.3h6.9l8.72 20.83h-7.51l-1.09-2.78h-7.24l-1.07 2.78h-7.39Zm5.27 13.15-1.9-5-1.89 5ZM171.3 6.3h-6.09V.44h19.1V6.3h-6.09v14.83h-6.92ZM190.06.44H197v20.69h-6.92ZM203.06 10.85v-.06c0-6 4.94-10.79 11.32-10.79s11.27 4.73 11.27 10.73v.06c0 6-4.94 10.79-11.32 10.79s-11.27-4.73-11.27-10.73Zm15.61 0v-.06a4.42 4.42 0 0 0-4.34-4.67 4.37 4.37 0 0 0-4.33 4.61v.06a4.42 4.42 0 0 0 4.34 4.67 4.36 4.36 0 0 0 4.33-4.61ZM231.67.44h6.47l7.36 9.14V.44h6.86v20.69h-6.15l-7.68-9.54v9.54h-6.86ZM266.07.3h6.85l8.72 20.83h-7.51L273 18.35h-7.2l-1.06 2.78h-7.39Zm5.26 13.15-1.89-5-1.9 5ZM286.63.44h6.92v14.78h9.72v5.91h-16.64Z" />
								</g>
							</svg>
						</a.div>
					</a.div>
					<a.div className={styles.blade1} style={{ x: tStyles.blade1, skewX: -55, backgroundColor: '#e54c8a' }} />
					<a.div className={styles.blade2} style={{ x: tStyles.blade2, skewX: -55, backgroundColor: '#d63677' }} />
					<a.div className={styles.blade3} style={{ x: tStyles.blade3, skewX: -55, backgroundColor: '#cc2162' }} />
				</div>
			);
		}

		// Aquabike
		if (index === 3) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '58%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272.41 30.92">
								<g style={{ isolation: 'isolate' }} fill="#fff">
									<path
										d="M12.39 6.08h9.75L34.53 35.7H23.86l-1.55-4H12l-1.51 4H0zm7.48 18.7l-2.69-7.14-2.69 7.14zM67.46 36.58l-3.24-2.9a16.72 16.72 0 01-9.16 2.65c-9.07 0-16-6.72-16-15.25V21c0-8.53 7-15.34 16.09-15.34s16 6.72 16 15.25V21a14.85 14.85 0 01-2.6 8.44l3 2.56zm-10.3-9l-3.31-2.85L58 20.11 61.11 23a8.21 8.21 0 00.21-1.76v-.09c0-3.48-2.43-6.76-6.26-6.76S49 17.64 49 21.08v.09c0 3.48 2.36 6.76 6.18 6.76a6.32 6.32 0 001.98-.34zM79.46 22.51V6.29h10v16.09c0 3.66 1.89 5.21 4.54 5.21s4.53-1.43 4.53-5V6.29h10v16c0 10-5.84 14-14.62 14s-14.45-4.04-14.45-13.78zM126.5 6.08h9.75l12.39 29.62H138l-1.55-4h-10.32l-1.52 4h-10.5zm7.5 18.7l-2.69-7.14-2.68 7.14zM155.73 6.29h16.47c4.08 0 6.64.93 8.36 2.65a6.28 6.28 0 011.81 4.58v.08c0 3.49-2.1 5.63-5.25 6.72 3.78 1.1 6.21 3.11 6.21 7.15v.08c0 4.7-3.86 8.15-11.3 8.15h-16.3zm17 9.46c0-1.31-1.05-2-3.15-2h-4.25v4.12h4.29c2.1 0 3.11-.72 3.11-2zm-2.44 8.15h-5v4.36h4.92c2.14 0 3.23-.88 3.23-2.14V26c.02-1.22-1.03-2.1-3.17-2.1zM191.43 6.29h9.83V35.7h-9.83zM210.59 6.29h9.83v11l8.36-11h11.42L230 19.06l10.41 16.64h-11.72l-5.75-9.33-2.52 3.07v6.26h-9.83zM247.13 6.29h25.07v8.07h-15.41v3h14.36v7.1h-14.36v3.19h15.62v8.05h-25.28z"
										transform="translate(0 -5.66)"
									></path>
								</g>
							</svg>
						</a.div>
					</a.div>
					<a.div className={styles.blade1} style={{ x: tStyles.blade1, skewX: -55, backgroundColor: '#ea596e' }} />
					<a.div className={styles.blade2} style={{ x: tStyles.blade2, skewX: -55, backgroundColor: '#e2445b' }} />
					<a.div className={styles.blade3} style={{ x: tStyles.blade3, skewX: -55, backgroundColor: '#e03445' }} />
				</div>
			);
		}

		// Bike Tour
		if (index === 4) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '56%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 277.8 32.51">
								<g style={{ isolation: 'isolate' }} fill="#fff">
									<path
										d="M2.77 6.7h16c3.93 0 6.7 1 8.53 2.77a7 7 0 012 5.09v.09c0 3.62-2.05 5.76-4.82 7 3.8 1.25 6.21 3.39 6.21 7.63v.09c0 5.36-4.38 8.62-11.84 8.62H2.77zm18 9.65c0-1.74-1.38-2.68-3.93-2.68h-5.63v5.49h5.45c2.63 0 4.15-.89 4.15-2.72zm-3 8.89h-6.56V31h6.7c2.68 0 4.15-1 4.15-2.86v-.09c0-1.7-1.34-2.81-4.24-2.81zM38.5 6.7h8.67V38H38.5zM56.46 6.7h8.66v12.68L75.93 6.7h10.23L74.32 20.1 86.56 38H76.2l-7.91-11.65-3.17 3.48V38h-8.66zM92.63 6.7h25.15V14h-16.57v4.8h15v6.84h-15v4.95H118V38H92.63zM149.62 14.29h-9.33V6.7h27.38v7.59h-9.38V38h-8.67zM172.67 22.42v-.09c0-9 7.23-16.25 16.88-16.25s16.79 7.19 16.79 16.16v.09c0 9-7.23 16.26-16.88 16.26s-16.79-7.19-16.79-16.17zm24.79 0v-.09c0-4.55-3.22-8.44-8-8.44s-8 3.84-8 8.35v.09c0 4.51 3.26 8.44 8 8.44s8-3.84 8-8.35zM214 24.25V6.7h8.75v17.37c0 4.51 2.28 6.66 5.76 6.66s5.81-2.06 5.81-6.43V6.7h8.75V24c0 10-5.76 14.51-14.65 14.51S214 34 214 24.25zM251.81 6.7h14.79c4.78 0 8.08 1.25 10.18 3.35a9.77 9.77 0 012.77 7.28v.09a9.93 9.93 0 01-6.43 9.65L280.57 38h-9.95l-6.3-9.46h-3.84V38h-8.67zm14.43 15c2.9 0 4.64-1.43 4.64-3.71v-.09c0-2.5-1.83-3.75-4.69-3.75h-5.71v7.55z"
										transform="translate(-2.77 -6.08)"
									></path>
								</g>
							</svg>
						</a.div>
					</a.div>
					<a.div className={styles.blade1} style={{ x: tStyles.blade1, skewX: -55, backgroundColor: '#ed926e' }} />
					<a.div className={styles.blade2} style={{ x: tStyles.blade2, skewX: -55, backgroundColor: '#ef8156' }} />
					<a.div className={styles.blade3} style={{ x: tStyles.blade3, skewX: -55, backgroundColor: '#ff7f41' }} />
				</div>
			);
		}

		// PTO
		if (index === 5) {
			return (
				<div style={{ position: 'absolute', width: '100%', height: '100%' }}>
					<a.div className={styles.name} style={{ opacity: tStyles.opacity, width: '90%' }}>
						<a.div style={{ y: tStyles.nameY }}>
							<div style={{ transform: 'translateY(-3px)' }}>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 419.72 73.94">
									<g fill="#fff" data-name="Pro Am">
										<path d="M170.12 26.63a39.05 39.05 0 00-14.53-20 38.55 38.55 0 00-24.4-6.47 37.61 37.61 0 00-23.27 10.05c-.52-.5-1-1-1.58-1.5A35 35 0 0091.51.91a35.93 35.93 0 00-17.09.25 38.32 38.32 0 00-15.21 8.2A33.91 33.91 0 0040.37.41a34.84 34.84 0 00-22.85 4.83A35.92 35.92 0 002.29 23.52 38.25 38.25 0 000 35.45a40.49 40.49 0 001.78 12.19A37.17 37.17 0 0016.5 66.93a37.38 37.38 0 0023.87 5.84 38.58 38.58 0 0021.36-9.44 1.14 1.14 0 01.11.11.76.76 0 00.09.09 35.85 35.85 0 0013.49 8.32 38.88 38.88 0 0018.6 1 37 37 0 0016.63-8.37 37.71 37.71 0 0017.95 8.87 36.46 36.46 0 0023.87-3.9 36 36 0 0016.35-18.16 38.21 38.21 0 001.3-24.66zm-4.74 22.62a34.29 34.29 0 01-14.54 17.39A34.68 34.68 0 01108 60.41a35.37 35.37 0 01-9.34-21.54c.24 7.31 3.34 15 7.8 21a30.72 30.72 0 01-13.71 8.43 33.59 33.59 0 01-16.59.5c-1-.24-2-.54-3-.87a30 30 0 01-11.86-7.17 43.81 43.81 0 01-3-3.35c-.46-.57-.89-1.15-1.31-1.74A33.78 33.78 0 0153 48c-.25-.68-.49-1.37-.7-2.08a35.08 35.08 0 005.35 12.37 33.73 33.73 0 01-18 8.6A31.06 31.06 0 0119 62.62 33.13 33.13 0 015.59 46.11a29.47 29.47 0 01-2-10.66 31.78 31.78 0 011.74-10.92A36 36 0 0118.53 7a33.74 33.74 0 0141.9 4.57 35.8 35.8 0 019.9 20.31 30.73 30.73 0 00-.48-3.56h12.66L71.6 52h9.64L91.4 30.63l-3.55-2.54h15l3.56-7.87H67.18A35.27 35.27 0 0063.37 14a29.82 29.82 0 0112.3-8.28 30.87 30.87 0 0115.09-1.54A33.63 33.63 0 01105.33 10a31.83 31.83 0 019.49 10.27h-5.39l6 4.25-7 16.82c-2.54 5.84.25 10.66 6.09 10.66h22.85c10.41 0 11.17-3 14.22-10.66l4.57-10.66c2.54-5.84 0-10.35-5.89-10.35l-35.2-.05a34.71 34.71 0 00-3.84-6.36l.78.83a32.81 32.81 0 0119.46-9.35 32.21 32.21 0 0121.28 4.93 33.41 33.41 0 0113.5 17.39 34.24 34.24 0 01-.87 21.53zM118.06 41l4.82-11.43-3-2.28h24.63c1.78 0 2.54 1.27 1.78 3L141.67 41a5 5 0 01-4.32 3h-17.52c-1.77.08-2.53-1.19-1.77-3z"></path>
										<path d="M50.78 20.22H16l6.85 5L10.66 52h9.65l3.56-7.87h17.51C51 44.08 54.33 40 58.14 31.9c4.32-9.65 1.78-11.68-7.36-11.68zM48.24 32.4c-1.52 3.56-3.56 4.07-6.86 4.07h-14L30 30.88l-3.6-2.79h18.79c3.3 0 4.81.51 3.05 4.31zM214.54 20.18c8.24 0 11.49 4.12 8.86 11.37-2.8 7.69-9.17 12.13-17.41 12.13h-4.39l-3 8.23h-10.5l11.55-31.73zm-10 15.53h3.85a5.26 5.26 0 005-3.85c.86-2.35 0-3.71-2.22-3.71h-3.85zM236.59 43.68h-3.94l-3 8.23h-10.5l11.55-31.73h15.35c8.6 0 12 4.12 9.4 11.37a17.64 17.64 0 01-8.68 10l2.49 10.32H237.4zm3.81-8a5.26 5.26 0 005-3.85c.86-2.35 0-3.71-2.22-3.71h-4.88l-2.75 7.56zM291 36a25.67 25.67 0 01-23.38 16.33c-10.09 0-14.92-6.87-11.48-16.33a25.41 25.41 0 0123.31-16.16c10.13.03 14.99 6.82 11.55 16.16zm-24.1 0c-1.58 4.35.42 7.56 4 7.56s7.69-3.21 9.27-7.56-.18-7.47-3.8-7.47-7.86 3.2-9.44 7.54zM309 47.43l-3.26 4.48h-10.8l24.94-31.73h10.78L332 51.91h-11.17l.05-4.48zm12.18-16.79l-6.73 9.28H321zM360.71 51.91L367 34.53l-10.6 14.31h-6.88l-.23-14.31L343 51.91h-9.15l11.55-31.73h11.09l.9 16.57 12.95-16.57h11.06l-11.54 31.73z"></path>
										<path d="M419.72 19.91l-18.29 41.91c-2.88 6.55-11 11.88-18.16 11.88h-5.2l-202.45-1.38 202.45-1.39c6 0 13.2-4.68 15.61-10.21l17.81-40.81z"></path>
									</g>
								</svg>
							</div>
						</a.div>
					</a.div>
				</div>
			);
		}
		return null;
	});

	return <div className={styles.LogoBox}>{fragment}</div>;
}
