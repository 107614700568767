import useStyleProps from '../../common/utilities/useStyleProps';

export default function LogoDate(props) {
	const styles = useStyleProps(props);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221.13 28.01" style={styles}>
			<g style={{ isolation: 'isolate' }} fill="#fff" data-name="May 15 2022">
				<path
					d="M4.66 13.16V28.9H.85V5.1h6.87l1.36 14.42L10.54 5.1h6.9v23.8h-4.69V13.33L11 28.9H6.46zM35 28.9l-.38-3.47h-3l-.34 3.47H27l3.09-23.8h6.49l3.13 23.8zm-2.93-7.62h2.11l-1.06-10.36zM55.93 28.9h-4.76v-8.13l-4-15.67h5l1.6 8.09 1.7-8.09h4.52l-4 15.33zM88.5 28.9h-4.66V10.54H81.6v-3.3h.31a3.47 3.47 0 002.71-1.67L85 5h3.5zM110.09 24.68c0 3-1.46 4.42-4.42 4.42h-2.58c-3 0-4.42-1.46-4.42-4.42v-4.31h4.59v3.8a.74.74 0 00.85.85h.44a.74.74 0 00.85-.85v-5.74c0-.61-.24-1-.85-1s-.92.34-1.43 1H98.8V5.1h11v4.25h-6.53l-.07 5.1a4.37 4.37 0 013.27-1.33h.07c2.21 0 3.6 1.47 3.6 3.85zM124.27 26.69c0 2.41-.24 3-1.77 5l-.88 1.19-1.81-.51.48-1a14.42 14.42 0 001-2.52h-1.49v-5.19h4.42zM152.83 14.72a2.9 2.9 0 00.81-2V9.83a.74.74 0 00-.85-.85h-.3a.74.74 0 00-.85.85v3.94h-4.49V9.32c0-3 1.46-4.42 4.42-4.42h2.35c3 0 4.41 1.46 4.41 4.42v3.57a7 7 0 01-1.9 4.79l-3.84 4.83a2.92 2.92 0 00-.82 2v.17h6.56v4.22h-11.25v-4.56a6.84 6.84 0 011.92-4.79zM175.54 29.1h-2.93c-3 0-4.42-1.46-4.42-4.42V9.32c0-3 1.47-4.42 4.42-4.42h2.93c3 0 4.42 1.46 4.42 4.42v15.36c.04 2.96-1.46 4.42-4.42 4.42zM174.38 9h-.61a.74.74 0 00-.85.85v14.32a.74.74 0 00.85.85h.61a.74.74 0 00.85-.85V9.83a.74.74 0 00-.85-.83zM195.56 14.72a2.92 2.92 0 00.82-2V9.83a.74.74 0 00-.85-.85h-.31a.74.74 0 00-.85.85v3.94h-4.48V9.32c0-3 1.46-4.42 4.42-4.42h2.34c3 0 4.42 1.46 4.42 4.42v3.57a7 7 0 01-1.9 4.79l-3.84 4.83a2.92 2.92 0 00-.82 2v.17h6.56v4.22h-11.25v-4.56a6.87 6.87 0 011.9-4.79zM216.47 14.72a2.92 2.92 0 00.82-2V9.83a.74.74 0 00-.85-.85h-.31a.74.74 0 00-.85.85v3.94h-4.48V9.32c0-3 1.46-4.42 4.42-4.42h2.34c3 0 4.42 1.46 4.42 4.42v3.57a7 7 0 01-1.9 4.79l-3.84 4.83a2.92 2.92 0 00-.82 2v.17H222v4.22h-11.27v-4.56a6.87 6.87 0 011.9-4.79z"
					transform="translate(-.85 -4.9)"
				></path>
			</g>
		</svg>
	);
}
