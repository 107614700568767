import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useBlobify from '../common/utilities/useBlobify';
import logo from '../media/logo-landscape.svg';
import LogoBlobState from '../states/LogoBlobState';

export default function usePreloadLogo() {
	const [logoUrl, logoIsReady] = useBlobify(logo);
	const setLogoBlobState = useSetRecoilState(LogoBlobState);

	useEffect(() => {
		setLogoBlobState({ url: logoUrl, isReady: logoIsReady });
	}, [logoUrl, logoIsReady, setLogoBlobState]);
}
