import { useSpring, a } from '@react-spring/web';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useWindowSize from '../../../common/utilities/useWindowSize';
import { rangeMap } from '../../../common/utilities/utilities';
import MainMenuStatusState from '../../../states/MainMenuStatus';
import styles from './MainMenuBg.module.scss';
import useStore from '../../../states/useStore';
import { isHomePage } from '../../../utilities/GlobalFunctions';
import { useLocation } from 'react-router';
import { useMobileQuery, useTabletLargeQuery } from '../../../common/utilities/MediaQuery';

export default function MainMenuBg() {
	const lastMenuX = useRef(0);
	const { width: winWidth, height: winHeight } = useWindowSize();
	const mainMenuOpen = useRecoilValue(MainMenuStatusState);
	const location = useLocation();
	const [defaultOpacity, setDefaultOpacity] = useState(isHomePage(location.pathname) ? 0.9 : 0.9);
	const lastGrayOpacity = useRef(0);
	const prevLocation = useRef();
	const isMobile = useMobileQuery();
	const isTabletLarge = useTabletLargeQuery();
	const isMobileMenuActive = isMobile || isTabletLarge;

	const [{ x, grayOpacity, opacity }, spring] = useSpring(() => ({
		x: isMobileMenuActive ? -600 : 0,
		blueOpacity: 1,
		grayOpacity: 0,
		opacity: defaultOpacity,
	}));

	useEffect(() => {
		prevLocation.current = location.pathname;

		if (isHomePage(location.pathname)) {
			setDefaultOpacity(0.9);
		} else {
			setDefaultOpacity(0.9);
		}
	}, [location.pathname]);

	useEffect(() => {
		opacity.start(defaultOpacity);
	}, [defaultOpacity, opacity]);

	useEffect(() => {
		return useStore.subscribe(
			(scrollY) => {
				const norm = Math.min(scrollY / winHeight, 1);
				grayOpacity.start(norm);
			},
			(state) => state.scrollY
		);
	}, [winHeight, grayOpacity]);

	useEffect(() => {
		if (mainMenuOpen) {
			spring.start({
				x: 55,
				config: {
					tension: 180,
					friction: 12,
				},
			});
		} else {
			spring.start({
				x: lastMenuX.current,
			});
		}
	}, [mainMenuOpen, spring]);

	useEffect(() => {
		if (mainMenuOpen) {
			lastGrayOpacity.current = grayOpacity.get();

			spring.start({
				grayOpacity: 0,
				opacity: 0.9,
			});
		} else {
			const locationChanged = prevLocation.current !== location.pathname;
			spring.start({
				grayOpacity: locationChanged ? 0 : lastGrayOpacity.current,
				opacity: defaultOpacity,
			});
		}
	}, [mainMenuOpen, spring, grayOpacity, defaultOpacity, location.pathname]);

	const onMouseMove = useCallback(
		({ pageX }) => {
			if (!mainMenuOpen && !isMobileMenuActive) {
				const x = rangeMap([0, winWidth / 2], [20, 0], pageX);

				lastMenuX.current = x;

				spring.start({
					x,
					config: {
						tension: 170,
						friction: 24,
					},
				});
			}
		},
		[winWidth, spring, mainMenuOpen, isMobileMenuActive]
	);

	useEffect(() => {
		window.addEventListener('mousemove', onMouseMove);

		return () => {
			window.removeEventListener('mousemove', onMouseMove);
		};
	}, [onMouseMove]);

	//isMobile
	useEffect(() => {
		if (isMobileMenuActive) {
			spring.start({
				x: mainMenuOpen ? 0 : -600,
			});
		} else {
			spring.start({
				x: 0,
			});
		}
	}, [isMobileMenuActive, mainMenuOpen, spring]);

	return (
		<a.div
			className={styles.MainMenuBg}
			style={{ x, skewX: -7, height: winHeight, opacity: isMobileMenuActive ? 1 : opacity }}
		>
			<a.div
				style={{
					position: 'absolute',
					height: '100%',
					width: '100%',
					backgroundColor: '#06314d',
				}}
			/>
			<a.div
				style={{
					position: 'absolute',
					height: '100%',
					width: '100%',
					backgroundColor: 'rgb(62,62,62)',
					opacity: isMobileMenuActive ? 1 : grayOpacity,
				}}
			/>
		</a.div>
	);
}
