// Any way to make this import not required?
import colors from '../../common/sass/base.module.scss';

const colorMap = {
	default: colors.iconColorDefault ?? 'rgb(204,204,204)',
	active: colors.iconColorActive ?? '#2CFFF6',
};

export default function useIconColor(color) {
	return colorMap[color] ?? colorMap.default;
}
