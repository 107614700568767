import { ABOUT_PATH } from '../../data/pathConstants';
import { Item, List } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Title from '../Text/Title';

import aboutBg from '../../media/page-bgs/aboutBg.jpg';

export const AboutData = {
	id: 'about',
	url: ABOUT_PATH,
	pageTitle: 'About',
	pageSummary: 'More info about the race can be found below.',
	pageBg: aboutBg,
	// pageIcon: '/assets/icons/highlights.svg',
	metaKeywords:
		'Herbalife 24, race highlights, register, fast course, DTLA, down town Los Angeles, finish line festival, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, triathlon relay',
	metaDescription:
		'Event highlights for Herbalife24 Triathlon Los Angeles 2021. An Urban triathlon crafted for professionals and newcomers. Check out triathlon relay opportunities!',
};

export default function About() {
	return (
		<>
			<Title>Something for Everyone</Title>
			<Paragraph>
				There are a multitude of different race categories to fit everyone’s comfort level and desired goals. This year we are also excited to announce the addition of a bike-only category (Bike Tour). Below are the various categories available this year:
			</Paragraph>

			<List>
				<Item>Triathlon Sprint & International Distance</Item>
				<Item>Aquabike International Distance</Item>
				<Item>Triathlon Relay Teams</Item>
				<Item>22 Mile Bike Tour </Item>
				<Item>5k DTLA Run/Walk</Item>
			</List>

			<Title>Flat and Fast Course From Venice Beach to Downtown Los Angeles</Title>
			<Paragraph>
				After rave reviews of the course design, the race once again begins at iconic Venice Beach in the Pacific Ocean. After participants exit the water and pass through T-1, the race continues on bike down Venice Blvd and through the heart of Los Angeles. Proceeding from T-2 in Downtown, the race culminates on foot alongside iconic LA landmarks and finishes at the famed L.A. Live outdoor venue.
			</Paragraph>
			<Paragraph>
				The point-to-point course will take racers through a variety of LA neighborhoods and points of interest, each showcasing the diversity and stories that have shaped the second most populous city in the United States.
			</Paragraph>

			<Title>A Dedicated Support Team From Start to Finish</Title>
			<Paragraph>
				The Herbalife24 Triathlon Los Angeles will welcome participants from around the world to the city of dreams in the spring of 2022. But we also encourage new participants to join! Whether an Elite or first timer, our team is available for any questions that may arise along the way and are eager to assist all participants with planning an unforgettable experience.
			</Paragraph>

			<Title>An Exceptional Experience</Title>
			<Paragraph>
				From the Pre-Event Expo to the Finish Line Festival - The Herbalife24 Triathlon Los Angeles promises to be unforgettable with giveaways and other exciting moments expected from the entertainment capital of the world. The festival will be open to the public so we encourage spectators and supporters to join in for a fun and memorable afternoon.
			</Paragraph>

			<Title>Create a Relay Team</Title>
			<Paragraph>
				A triathlon is not just an individual sport. Don’t like to swim in the ocean? No problem! The opportunity to participate as part of a relay team is available and is a fun way to share the experience with friends, family or colleagues.
			</Paragraph>

			<Title>Race Category Options</Title>
			<Paragraph>
				The Herbalife24 Triathlon Los Angeles provides a one of a kind tour through the heart of Los Angeles. The swim starts at iconic Venice beach, followed by a relatively flat and fast ride through various LA neighborhoods enroute to Downtown Los Angeles. The run takes runners through more of Downtown LA and Koreatown. <a href="https://herbalife24tri.la/course-description">Click here for more information!</a>
			</Paragraph>
		</>
	);
}