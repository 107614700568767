import React from 'react';
import ReactDOM from 'react-dom';
import iNoBounce from 'inobounce'; // eslint-disable-line
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

ReactDOM.render(
	<RecoilRoot>
		<BrowserRouter basename="/">
			<App />
		</BrowserRouter>
	</RecoilRoot>,
	document.getElementById('root')
);
