import React from 'react';
import styles from '../HomeInfobox.module.scss';

export default function HomeTriathlon() {
	return (
		<>
			<div className={styles['home-bullet']}>Flat &amp; fast course: Venice Beach to Downtown L.A.</div>
			<div className={styles['home-bullet']}>Free custom training and nutrition plans</div>
			<div className={styles['home-bullet']}>Relay team registration available</div>
		</>
	);
}
