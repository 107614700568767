import React, { useCallback, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import useInteraction from '../../../common/utilities/useInteraction';
import styles from './SubMenuItem.module.scss';
import { Link } from 'react-router-dom';

function SubMenuDashLine({ comingSoon }) {
	if (!comingSoon) {
		return (
			<svg
				className={styles['sub-menu-dash-line']}
				width="22"
				height="20"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
			>
				<line x1="0" y1="10" x2="22" y2="10"></line>
			</svg>
		);
	} else {
		return null;
	}
}

function SubMenuItem({ item }) {
	const location = useLocation();
	const history = useHistory();

	const onPress = useCallback(
		(e) => {
			if (!e) return;

			if (item.external) {
				return;
			}

			if (item) {
				e.preventDefault();
				history.push(`${item.link}`);
			}
		},
		[item, history]
	);

	const { bind, isHovered, isPressed } = useInteraction({ onPress });

	const activePage = useCallback(
		(path) => {
			return location.pathname === `/${path}`;
		},
		[location]
	);

	useEffect(() => {
		if (isPressed) {
			if (item.external) {
				return;
			}

			if (item) {
				history.push(`${item.link}`);
			}
		}
	}, [isPressed, item, history]);

	return (
		<div
			{...bind()}
			className={`${styles['sub-menu-item']} ${
				activePage(item.link) || item.inActive || item.comingSoon ? styles['sub-menu-item--disabled'] : ''
			} ${isHovered ? styles['sub-menu-item--hovered'] : ''}`}
		>
			<SubMenuDashLine comingSoon={item.comingSoon} />
			<Link
				to={{ pathname: item.link }}
				target={`${item.external ? '_blank' : '_self'}`}
				className={`${styles['sub-menu-item-label']} ${item.comingSoon ? styles['sub-menu-label-coming-soon'] : ''}`}
			>
				{item.label}
			</Link>
		</div>
	);
}

export default SubMenuItem;
