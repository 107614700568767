import usePageStyles from '../usePageStyles';
import { Route, Switch } from 'react-router-dom';
import {
	MAP_PATH,
	BLOG_PATH,
	HOME_PATH,
	HOME_TRIATHLON_PATH,
	HOME_AQUABIKE_PATH,
	HOME_5K_PATH,
	HOME_BIKETOUR_PATH,
	VIRTUAL_CHALLENGE_PATH,
} from '../../data/pathConstants';
import { PAGES } from '../../data/pageData';
import ContentPageContent from '../../components/ContentPage/ContentPageContent';
import MainMenuBg from '../../components/Menu/MainMenu/MainMenuBg';
import Home from '../../components/Home/Home';
import { useSpring, a, useTransition } from '@react-spring/web';
import { useRecoilValue } from 'recoil';
import MainMenuStatusState from '../../states/MainMenuStatus';
import BlogContent from '../../components/Blog/BlogContent';
import BlogPostContent from '../../components/Blog/BlogPostContent';
import { useLocation, useRouteMatch, Redirect } from 'react-router-dom';
import usePrevious from '../../common/utilities/usePrevious';
import HomeCategory from '../../components/Home/HomeCategory';

export default function ContentLayer() {
	const pageStyles = usePageStyles(3);
	const mainMenuOpen = useRecoilValue(MainMenuStatusState);
	const location = useLocation();
	const prevLocation = usePrevious(location);
	const isMap = location.pathname === MAP_PATH;
	const isBlogPost = useRouteMatch('/blog/:slug');
	const prevIsBlogPost = usePrevious(isBlogPost);

	const tranStyles = useSpring({
		opacity: mainMenuOpen ? 0 : 1,
		x: mainMenuOpen ? 20 : 0,
		willChange: 'opacity, transform',
	});
	const menuTranStyles = useSpring({ opacity: isMap ? (mainMenuOpen ? 1 : 0) : 1 });

	const blogTransitions = useTransition(location, {
		from: (location) => {
			if (isBlogPost && prevLocation?.pathname === BLOG_PATH) {
				return { opacity: 0, y: -40, position: 'absolute' };
			}
			if (location?.pathname === BLOG_PATH && prevIsBlogPost) {
				return { opacity: 0, y: -40, position: 'absolute' };
			}
			return { opacity: 0, y: 0, position: 'absolute' };
		},
		enter: { opacity: 1, y: 0, position: 'absolute', pointerEvents: 'auto' },
		leave: { opacity: 0, y: 60, position: 'absolute', pointerEvents: 'none' },
	});

	return (
		<div style={{ ...pageStyles, pointerEvents: isMap ? 'none' : 'auto' }}>
			<a.div style={menuTranStyles}>
				<MainMenuBg />
			</a.div>
			<a.div style={tranStyles}>
				<Switch>
					<Route path={HOME_PATH} exact>
						<Home />
					</Route>
					<Route path={HOME_TRIATHLON_PATH} exact>
						<HomeCategory type={['sprint', 'international']} />
					</Route>
					<Route path={HOME_AQUABIKE_PATH} exact>
						<HomeCategory type={['aquabike']} />
					</Route>
					<Route path={HOME_5K_PATH} exact>
						<HomeCategory type={['5k']} />
					</Route>
					<Route path={HOME_BIKETOUR_PATH} exact>
						<HomeCategory type={['biketour']} />
					</Route>
					<Route
						path={Object.keys(PAGES).filter(function (i) {
							return i !== HOME_PATH && i !== MAP_PATH;
						})}
					>
						<ContentPageContent />
					</Route>
					<Route path="/virtual">
						<Redirect to={VIRTUAL_CHALLENGE_PATH} />
					</Route>
					{blogTransitions((tStyles, item) => (
						<a.div style={tStyles}>
							<Switch location={item}>
								<Route path="/blog/:slug">
									<BlogPostContent />
								</Route>
								<Route path={BLOG_PATH} exact>
									<BlogContent />
								</Route>
							</Switch>
						</a.div>
					))}
				</Switch>
			</a.div>
		</div>
	);
}
