import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import styles from './Home.module.scss';
import LandscapeActiveState from '../../states/LandscapeActive';
import LogoLandscape from '../Logo/LogoLandscape';
import LogoDate from '../Logo/LogoDate';
import LogoBox from '../Logo/LogoBox';
import useWindowSize from '../../common/utilities/useWindowSize';
import useStore from '../../states/useStore';
import usatLogo from '../../media/usat-logo.svg';
import { useMobileQuery } from '../../common/utilities/MediaQuery';
import SponsorTickerMobile from '../SponsorTickerMobile/SponsorTickerMobile';
import SponsorTicker from '../SponsorTicker/SponsorTicker';
import Button from '../Button/Button';
import { PHOTOS_2022_URL, RESULTS_2022_URL } from '../../data/pageData';

const LANDING_PAGE = ['5k', 'sprint', 'aquabike', 'biketour'];

export default function Home({ type = [] }) {
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const { width, height } = useWindowSize();
	const dispatchScrollY = useStore((state) => state.dispatchScrollY);
	const isMobile = useMobileQuery();

	useEffect(() => {
		setTimeout(() => dispatchScrollY(0), 100);
	}, [dispatchScrollY]);

	useEffect(() => {
		setLandscapeActive(true);
	}, [setLandscapeActive]);

	return (
		<>
			<div className={styles.Home} style={{ width, height }}>
				{!isMobile && (
					<img
						src={usatLogo}
						alt="USAT State Champsionship Logo"
						style={{ position: 'absolute', right: 200, top: 30, width: 200 }}
					/>
				)}
				<div className={styles.logo}>
					<div className={styles['logo-inner']}>
						<LogoLandscape />
						<div className={styles.sub}>
							<LogoBox type={type[0] ? type : LANDING_PAGE} />
						</div>
						<div className={styles.date}>
							<div className={styles['date-inner']}>
								<LogoDate />
							</div>
						</div>
						<div className={styles.buttons}>
							<div className={styles.button}>
								<Button href={RESULTS_2022_URL}>Results 2022</Button>
							</div>
							<div className={styles.button}>
								<Button href={PHOTOS_2022_URL}>Photos 2022</Button>
							</div>
						</div>
					</div>
				</div>
				{isMobile ? <SponsorTickerMobile /> : <SponsorTicker />}
			</div>
		</>
	);
}
