import { MAP_DETAILS_PATH } from '../../data/pathConstants';
import Paragraph from '../Text/Paragraph';
import Subtitle from '../Text/Subtitle';

import mapDetailsBg from '../../media/page-bgs/map_details_bg.jpg';

export const MapDetailsData = {
	id: 'mapDetails',
	url: MAP_DETAILS_PATH,
	pageBg: mapDetailsBg,
	pageTitle: 'Map Details',
	pageSummary: 'View and download maps of the course!',
	pageIcon: '/assets/icons/highlights.svg',
	metaKeywords:
		'Herbalife 24, race highlights, register, fast course, DTLA, down town Los Angeles, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles, triathlon relay, map, swim, bike, run',
	metaDescription: 'View and download maps of the course!',
};

export default function MapDetails() {
	return (
		<>
			<Subtitle>All Maps</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-all-maps.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>

			<Subtitle>Venice Parking Map</Subtitle>
			<Paragraph>
				<a
					href="/assets/course-map/pdfs/herbalife24-tri-la-venice-parking-map.pdf"
					download
					target="_blank"
					rel="noreferrer"
				>
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-venice-parking-map.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-venice-parking-map.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>International Bike Course</Subtitle>
			<Paragraph>
				<a
					href="/assets/course-map/pdfs/herbalife24-tri-la-bike-map-olympic.pdf"
					download
					target="_blank"
					rel="noreferrer"
				>
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-bike-map-olympic.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-bike-map-olympic.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>Sprint Bike Course</Subtitle>
			<Paragraph>
				<a
					href="/assets/course-map/pdfs/herbalife24-tri-la-bike-map-sprint.pdf"
					download
					target="_blank"
					rel="noreferrer"
				>
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-bike-map-sprint.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-bike-map-sprint.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>Bike Tour</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-biketourmap.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-biketourmap.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-biketourmap.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>International, Sprint and DTLA 5k Run</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-run-map.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-run-map.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-run-map.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>Swim Courses</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-swim-map.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-swim-map.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-swim-map.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>T1 Map</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-t1-map.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-t1-map.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-t1-map.jpg" alt="" />
				</a>
			</Paragraph>

			<Subtitle>T2 Map - Finish Line</Subtitle>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-t2-map.pdf" download target="_blank" rel="noreferrer">
					PDF DOWNLOAD
				</a>
			</Paragraph>
			<Paragraph>
				<a href="/assets/course-map/pdfs/herbalife24-tri-la-t2-map.pdf" target="_blank" rel="noreferrer">
					<img src="/assets/course-map/jpgs/herbalife24-tri-la-t2-map.jpg" alt="" />
				</a>
			</Paragraph>

			<div style={{ fontWeight: 'bold', fontSize: '28px', fontStyle: 'oblique' }}>Course routes subject to change.</div>
		</>
	);
}
