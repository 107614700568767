import useLayerStyles from '../useLayerStyles';
import MobileRegister from '../../components/MobileRegister/MobileRegister';
import { VIRTUAL_CHALLENGE_PATH } from '../../data/pathConstants';
import { useLocation } from 'react-router-dom';

const HIDE = [VIRTUAL_CHALLENGE_PATH];

export default function BannerLayer() {
	const location = useLocation();
	const layerStyles = useLayerStyles(6);

	return <div style={layerStyles}>{HIDE.includes(location.pathname) ? '' : <MobileRegister />}</div>;
}
