import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../common/utilities/useWindowSize';
import usePageData from '../../pages/usePageData';
import usePageContent from '../../pages/usePageContent';
import { useSetRecoilState } from 'recoil';
import LandscapeActiveState from '../../states/LandscapeActive';
import { useEffect } from 'react';
import styles from './ContentPageContent.module.scss';
import useStore from '../../states/useStore';
import { useGesture } from '@use-gesture/react';
import Footer from '../Footer/Footer';
import LogoLandscapeDate from '../Logo/LogoLandscapeDate';
import { useMobileQuery } from '../../common/utilities/MediaQuery';
import { PTO_PRO_AM_PATH, VIRTUAL_CHALLENGE_PATH } from '../../data/pathConstants';

export default function ContentPageContent() {
	const location = useLocation();
	const data = usePageData(location.pathname);
	const { width, height } = useWindowSize();
	const ref = useRef();
	const isMobile = useMobileQuery();
	const hideLogo = location.pathname === VIRTUAL_CHALLENGE_PATH || location.pathname === PTO_PRO_AM_PATH;

	const PageContent = usePageContent(location.pathname);
	const setLandscapeActive = useSetRecoilState(LandscapeActiveState);
	const dispatchScrollY = useStore((state) => state.dispatchScrollY);

	const bind = useGesture({
		onScroll: ({ offset }) => {
			dispatchScrollY(offset[1]);
		},
	});

	useEffect(() => {
		setLandscapeActive(false);
	}, [setLandscapeActive]);

	useEffect(() => {
		ref.current.scrollTo(0, 0);
	}, [location]);

	return (
		<>
			<Helmet>
				<title>{`Herbalife24 Tri - ${data.pageTitle}`}</title>
				<meta name="description" content={`${data.metaDescription ? data.metaDescription : ''}`} />
				<meta name="keywords" content={`${data.metaKeywords ? data.metaKeywords : ''}`} />
				<meta property="og:title" content={`${data.pageTitle}`} />
				<meta property="og:description" content={`${data.metaDescription ? data.metaDescription : ''}`} />
			</Helmet>
			<div {...bind()} style={{ height, width }} className={styles.ContentPageContent} ref={ref}>
				<div className={styles.header} style={{ height: isMobile ? height * 0.85 : height }}>
					<div className={styles['header-content']}>
						{!hideLogo ? (
							<div className={styles.logo}>
								<LogoLandscapeDate size="small" />
							</div>
						) : null}
						<div>
							<h2 className={styles.title}>{data.pageHeader ? data.pageHeader : data.pageTitle}</h2>
							{data.pageSummary && <div className={styles.description}>{data.pageSummary}</div>}
						</div>
					</div>
				</div>
				<div className={styles['detail-body-inner']}>
					<div className={styles['content']}>
						<div className={styles['detail-body-wrap']}>
							<div className={styles['inner-wrap']}>
								<PageContent />
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
}
