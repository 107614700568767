import React from 'react';
import useIconColor from './useIconColor';
import useIconSize from './useIconSize';

export default function ShoppingBag({ color = 'default', size = 'S' }) {
	const [width, height] = useIconSize(size);
	const fill = useIconColor(color);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 56" width={width} height={height}>
			<path
				fill={fill}
				d="M24,0c-5,0-9,3.6-9,8v6H6c-1,0-1.9,0.8-2,1.8l-4,38c-0.1,1.1,0.7,2.1,1.8,2.2c0.1,0,0.1,0,0.2,0h44
                c1.1,0,2-0.9,2-2c0-0.1,0-0.1,0-0.2l-4-38c-0.1-1-1-1.8-2-1.8h-9V8C33,3.6,29,0,24,0z M19,8c0-2.2,2.2-4,5-4s5,1.8,5,4v6H19V8z
                M40.2,18l3.6,34H4.2l3.6-34H15v4c0,1.1,0.9,2,2,2s2-0.9,2-2v-4h10v4c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2v-4H40.2z"
			/>
		</svg>
	);
}
