import ButtonBase from '../../common/components/button/ButtonBase';
import Close from '../../common/icons/Close';
import styles from './ModalClose.module.scss';

export default function ModalClose({ onPress }) {
	return (
		<ButtonBase className={styles.back} onPress={onPress}>
			<Close size="L" />
		</ButtonBase>
	);
}
