import { Item, List } from '../List/List';
import Paragraph from '../Text/Paragraph';
import Title from '../Text/Title';
import Subtitle from '../Text/Subtitle';
import SharpSpringForm from '../SharpSpringForm/SharpSpringForm';
import { CORPORATE_CHALLENGE_PATH } from '../../data/pathConstants';

import corporateChallengeBg from '../../media/page-bgs/corporate_challenge_bg.jpg';

export const CorporateChallengeData = {
	id: 'corporateChallenge',
	url: CORPORATE_CHALLENGE_PATH,
	pageBg: corporateChallengeBg,
	pageTitle: 'Corporate Challenge',
	pageSummary: 'Who are the fittest companies in Los Angeles?',
	pageIcon: '/assets/icons/icon_corporate.svg',
	metaKeywords:
		'Herbalife 24, LA tri club, Los Angeles tri club, LA triathlon club, Herbalife24 triathlon LA, Herbalife24 triathlon Los Angeles',
	metaDescription:
		'Who are the fittest companies in Los Angeles? The Corporate Challenge is an opportunity for leading Los Angeles companies to engage in “friendly” competition...',
};

export default function CorporateChallenge() {
	return (
		<>
			<Title>Register your company's team and get fit while making a difference together!	</Title>

			<Paragraph>Herbalife Nutrition and <a href="https://www.jdrf.org/impact/" target="_blank" rel="noreferrer">JDRF</a> are partnering to change the future of millions of people living with type 1 diabetes by working together toward a world without T1D.  The Corporate Challenge is the opportunity for companies to have a “friendly” competition between leading Los Angeles companies, build an internal culture of fitness and health with employees, and have a significant social impact with <a href="https://www.jdrf.org/impact/" target="_blank" rel="noreferrer">JDRF</a>.</Paragraph>
			<Paragraph>
				<img src="/assets/corporate/image2.jpg" alt="" />
			</Paragraph>

			<Title>Corporate Challenge Categories</Title>
			<Subtitle>Companies can compete in the following categories:</Subtitle>

			<List>
				<Item>Fittest and Fastest Triathlon Team</Item>
				<List>
					<Item>International Distance Event</Item>
					<Item>Sprint Distance Event</Item>
				</List>
				<Item>Largest Triathlon Team</Item>
				<Item>Largest 5k Team</Item>
				<Item>Most money raised for JDRF</Item>
				<List>
					<Item>Large Companies (100+ employees)</Item>
					<Item>Small Companies ({'<'}100 employees)</Item>
				</List>
			</List>

			<Subtitle>Members of your company team can compete in the following event categories:</Subtitle>

			<List>
				<Item>International or Sprint Distance Relay</Item>
				<Item>International or Sprint Distance Triathlon</Item>
				<Item>Aquabike</Item>
				<Item>Bike Tour</Item>
				<Item>5k Fun Run</Item>
			</List>

			<Paragraph>
				The relay category is a great option for company teams as the team consists of 3 people and each person competes in either the running, biking, or swimming portion of the event -- perfect for team bonding and getting fit, without needing to be an experienced athlete.
			</Paragraph>

			<Subtitle>
				Sign up to get more information about the event, prizes, fundraising awards, and other additional services we have to offer!
			</Subtitle>

			<SharpSpringForm
				id="corporate-challenge-form" 
				formID="s0iyNDI0MjfQTUlJs9Q1SbQ01E1KTTXStTQxNU1LSjS3MDM2AQA" 
				domain="app-3QN859UOU8.marketingautomation.services"
			/>

			<Title>Corporate Challenge Prizes</Title>

			<Paragraph>
				Winners of each Corporate Challenge category will receive a team plaque. Individuals on a corporate team can also compete for the Individual Fundraising Premiums below.
			</Paragraph>
			<Paragraph>
				<img src="/assets/corporate/image1.jpg" alt="" />
			</Paragraph>

			<Title>Individual Fundraising Premiums:</Title>
			<Paragraph>TBD</Paragraph>

			<Title>How to Register</Title>

			<List>
				<Item>Register <a href="https://endurancecui.active.com/new/events/63653354/select-race?e4p=0d090d99-939f-4301-99fb-3d910b9d3ccc&amp;e4ts=1579219960&amp;e4q=14285276-b039-49ca-9077-6444a7a42053&amp;e4c=active&amp;e4e=snawe00000000&amp;e4h=befdd844c5c198b51fd43d590f28f0a6&amp;e4rt=Safetynet&amp;_p=16631228150658184" target="_blank" rel="noreferrer">here</a>. This can be done by the team captain or HR representative.</Item>
				<Item>Create a clear team name when prompted during online registration.</Item>
				<Item>If your company is covering the registration fee of employees, select the option to pre-purchase entries. You can also request to pay one invoice in exchange for comp codes that can be distributed to your employees and team members. Please email <a href="mailto:alida@genericevents.com">alida@genericevents.com</a> if you would like to pay an invoice online and receive comp codes.</Item>
				<Item>If the company is not covering team registration fees, simply create a team during online registration HERE and invite others to join. They can join by registering and selecting to join the team name you created when prompted.</Item>
				<Item>Send invites and manage your team at myevents.active.com.</Item>
				<Item>Begin fundraising for JDRF. Instructions will be emailed to you.</Item>
				<Item>All event day details will be communicated to participants prior to event day.</Item>
			</List>

			<Title>Corporate Challenge Requirements & Details:</Title>

			<List>
				<Item><b>Relay teams</b> competing in the <b>Corporate Challenge</b> are responsible for their race entry fee plus a minimum of $150 per athlete in pledges or donations. Race entry fees can be paid by Company or by each athlete.</Item>
				<Item>Athletes must choose the team they are competing on PRIOR to event day (this is done online via Active.com).</Item>
				<Item>All donations to JDRF must be received by May 1, 2022 to be counted in the <b>Corporate Challenge</b> (Corporate recognition is on event day, May 15, 2022).</Item>
				<Item>All athletes that compete in the <b>Corporate Challenge</b> are eligible to win Age Group and division awards, unless they have chosen to participate in the Recreational Wave.</Item>
				<Item>All individuals on a Corporate Team are available for individual fundraising premiums. Please see Fundraising Premiums on the <a href="https://www2.jdrf.org/site/TR/Challenge-Blueprint/SouthernCaliforniaChapter4041?pg=entry&amp;fr_id=8375" target="_blank" rel="noreferrer">JDRF fundraising page</a>.</Item>
			</List>

			<Paragraph>
				<img src="/assets/corporate/image3.jpg" alt="" />
			</Paragraph>

			<Title>Questions?</Title>
			<Paragraph>
				For more information, please contact <a href="mailto:info@herbalife24tri.la\">info@herbalife24tri.la</a>.
			</Paragraph>

			<div style={{ fontStyle: 'italic', fontSize: '14px', marginTop: '40px' }}>
				JDRF is an accredited 501(c)(3) nonprofit agency. Donations may be tax deductible under U.S and state law. If you have any questions, please contact the JDRF Southern California Chapter at 1391 Warner Avenue, Suite D, Tustin, California 92780 or southernca@jdrf.org.
			</div>
		</>
	);
}