import useLayerStyles from '../useLayerStyles';
import Landscape from '../../components/Landscape/Landscape';

export default function LandscapeLayer() {
	const layerStyles = useLayerStyles(3);

	return (
		<div style={layerStyles}>
			<Landscape />
		</div>
	);
}
