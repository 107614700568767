import BannerLayer from './layers/BannerLayer';
import ContentLayer from './layers/ContentLayer';
import ForegroundLayer from './layers/ForegroundLayer';
import LandscapeLayer from './layers/LandscapeLayer';
import MenuLayer from './layers/MenuLayer';
import OverlayLayer from './layers/OverlayLayer';
import BackgroundLayer from './layers/BackgroundLayer';
import useTracking from '../utilities/useTracking';

export default function Pages() {
	useTracking();
	
	return (
		<>
			<BannerLayer />
			<MenuLayer />
			<ForegroundLayer />
			<LandscapeLayer />
			<ContentLayer />
			<OverlayLayer />
			<BackgroundLayer />
		</>
	);
}
